import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
// UI's
import { Typography, Card, Space, Button, Row, Col, message } from "antd";
import { CaretRightOutlined, EyeFilled, CopyFilled } from "@ant-design/icons";
import { ReactComponent as Aeroplane } from "./Assets/aeroplane.svg";
import { generateCode } from "../../services/integrations";
import Venues from "./comps/Venues";
import Packages from "./comps/Packages";
import Payments from "./comps/Payments";
import Contracts from "./comps/Contracts";
import ShowCode from "./comps/ShowCode";
import usePopup from "../../common/MessageModal/usePopup";

// CSS
import "./integrationStyles.scss";

const Integrations = () => {
  const popup = usePopup();
  const { Title } = Typography;
  const [venue, setVenue] = useState("");
  const [packages, setPackages] = useState([]);
  const [payments, setPayments] = useState(null);
  const [terms, setTerms] = useState({ title: "", terms_html: "" });
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [generatedCode, setGeneratedCode] = useState(null);

  const validateFields = () => {
    const errors = {};
    if (!venue?.id) {
      errors.venue = "Please select a venue.";
    }
    if (!packages?.length) {
      errors.packages = "Please select at least one package.";
    }
    if (!terms?.title) {
      errors.terms = { ...errors.terms, title: "This field is required" };
    }
    if (!terms?.terms_html) {
      errors.terms = { ...errors.terms, terms_html: "This field is required" };
    }
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const generateIframeCode = async (type) => {
    if (validateFields()) return;

    setLoading(true);
    const generated_url =
      window.location.origin + "/booking/" + venue?.name + "-" + venue?.id;
    try {
      const payload = {
        reference_name: "PlanSpace website integration",
        packages: packages,
        terms: terms,
        location: venue?.id,
        generated_url: generated_url?.toLowerCase().replace(/ /g, "-"),
        payment_terms: payments,
      };
      const res = await generateCode(payload);
      setGeneratedCode(res?.data?.data);

      if (type === "preview") {
        window.open(res?.data?.data?.generated_url, "_blank");
      } else {
        setShowModal(type);
      }

      // popup.success(res?.data?.message);

    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPackages([]);
  }, [venue]);

  useEffect(() => {
    setGeneratedCode(null);
  }, [venue, packages, payments, terms]);

  return (
    <div
      className="scroll-view-two scrollbar-secondary-two"
      style={{ marginBottom: 36 }}
    >
      <Space>
        <Title level={3}>Integrations</Title>
      </Space>
      <div className="events-details">
        <p className="event-head" style={{ color: "#0B98D2" }}>
          Integrations
        </p>
        <p
          className="event-head"
          style={{ color: "#667085", pointerEvents: "none" }}
        >
          <CaretRightOutlined /> Website
        </p>
      </div>

      <Row gutter={[16, 16]}>
        <Col span={19}>
          <Venues
            error={errors?.venue}
            setErrors={setErrors}
            venue={venue}
            setVenue={setVenue}
          />
          <Packages
            error={errors?.packages}
            setErrors={setErrors}
            venue={venue}
            selected={packages}
            setSelected={setPackages}
          />
          <Payments createdPayments={setPayments} />
          <Contracts
            error={errors?.terms}
            setErrors={setErrors}
            terms={terms}
            setTerms={setTerms}
          />
        </Col>
        <Col span={5}>
          <Card style={{ position: "fixed" }}>
            <Button
              className="button-terms"
              size="large"
              block
              icon={<EyeFilled />}
              style={{ marginTop: 0 }}
              loading={loading}
              onClick={() => {
                if (generatedCode) {
                  window.open(generatedCode?.generated_url, "_blank");
                } else {
                  generateIframeCode("preview");
                }
              }}
            >
              Preview
            </Button>

            <Button
              icon={<Aeroplane width={14} />}
              size="large"
              type="primary"
              block
              style={{ marginTop: "10px" }}
              onClick={() => {
                if (generatedCode) {
                  setShowModal("Send by Email");
                } else {
                  generateIframeCode("Send by Email");
                }
              }}
              loading={loading}
            >
              Send by email
            </Button>

            <Button
              className="button-terms"
              size="large"
              block
              style={{ marginTop: "10px" }}
              icon={<CopyFilled />}
              onClick={() => {
                if (generatedCode) {
                  setShowModal("Integrations Code");
                } else {
                  generateIframeCode("Integrations Code");
                }
              }}
              loading={loading}
            >
              View & Copy
            </Button>
          </Card>
        </Col>
      </Row>
      <ShowCode
        open={showModal}
        setOpen={setShowModal}
        handelClose={() => setShowModal(false)}
        generatedCode={generatedCode}
      />
    </div>
  );
};

export default Integrations;
