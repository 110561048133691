import React from "react";
import InputMask from "react-input-mask";

export default function ZipCodeMask(props) {
  return (
    <InputMask
      mask="99999"
      onChange={props.onChange}
      maskChar=""
      alwaysShowMask={false}
      style={{
        width: "100%",
      }}
      placeholder="Enter Zip Code*"
      className="ant-input"
      value={props.value}
    />
  );
}
