import axios from "axios";

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
});
// Axios interceptor to update the 'Authorization' header when the token changes
client.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("access_token");
        if (token) {
            config.headers.Authorization = `JWT ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default client;
