import React, { useState } from 'react';
import usePopup from '../../../../../common/MessageModal/usePopup';

// UI's
import { Button, Modal } from 'antd';
import { Form, Input, Result } from 'antd';
import { shareOnEmail } from '../../../../../services/events';
import styles from '../../../events.module.scss';

const ShareModal = ({ open, handelClose, eventId }) => {
    const popup = usePopup();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const renderdButtons = () => {
        return [
            <Button size={'large'} key="cancel" onClick={() => {
                handelClose();
                setSuccessMessage('');
            }}>
                Cancel
            </Button>,
            <Button loading={loading} onClick={() => form.submit()} size={'large'} type="primary">
                Share
            </Button>
        ]
    }

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            const res = await shareOnEmail({
                ...values,
                event: eventId,
            });
            setSuccessMessage(res?.data?.message);
            form.resetFields();
        } catch (error) {
            popup.error('Oops!', error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    }

    const CustomEmailValidator = (rule, value, callback) => {
        if (!value) {
            callback('Email is required');
        } else {
            const emails = value.split(',').map(email => email.trim());
            const isInvalid = emails.some(email => !/^\S+@\S+\.\S+$/.test(email));

            if (isInvalid) {
                callback('Invalid email format');
            } else {
                callback(); // All emails are valid
            }
        }
    };

    return (
        <Modal
            className={styles.createModal}
            centered
            title="Share Contract"
            open={open}
            onCancel={() => {
                handelClose();
                setSuccessMessage('');
            }}
            footer={!successMessage && renderdButtons()}
        >
            {successMessage ? (
                <Result
                    status="success"
                    title={successMessage}
                    extra={[
                        <Button size={'large'} onClick={() => {
                            handelClose();
                            setSuccessMessage('');
                        }}>
                            Close
                        </Button>,
                    ]}
                />
            ) : (
                <Form
                    className={styles.eventForm}
                    form={form}
                    name="validateOnly"
                    layout="vertical"
                    autoComplete="off"
                    onFinish={handleSubmit}
                >
                    <Form.Item
                        name="emails"
                        label="Email*"
                        rules={[
                            {
                                required: true,
                                validator: CustomEmailValidator,
                            }
                        ]}
                    >
                        <Input size="large" placeholder="Enter your email" />
                    </Form.Item>
                </Form>
            )}
        </Modal>
    )
}

export default ShareModal
