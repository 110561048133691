import React from 'react'
import ErrorIcon from '@mui/icons-material/Error';
import { Tooltip } from "@mui/material";
// import { List, ListItem, ListItemText, ListSubheader, Popover } from '@mui/material';

const StripeErrors = ({ data }) => {

    return data?.currently_due?.length ? (<>
        {/* <span className="status error" aria-describedby={id} onClick={handleClick}> */}
        <span className="status error">
            <Tooltip title={data?.status} placement="top">
                <ErrorIcon />
            </Tooltip>
        </span>
    </>) : null
}

export default StripeErrors
