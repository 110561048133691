import React, { useState } from "react";
import "./ServiceCard.scss";
import { ReactComponent as RoomIconBlack } from "../../../assets/RoomIconBlack.svg";
import { ReactComponent as EditIcon } from "../../../assets/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/deleteIcon.svg";
import { ReactComponent as LockIcon } from "../../../assets/LockIcon.svg";
import EditServiceForm from "../Modals/EditServiceForm";
import ConfirmationModal from "../Modals/ConfirmationModal";
import axios from "axios";
import axiosInstance from "../../../common/axiosInstance";
import cardImgN from "../../../assets/Image_not_available.png";
import { Switch } from "antd";

const ServiceCard = ({ serviceData, getServices, activeTab }) => {
  const [editServiceModal, setEditServiceModal] = useState(false);
  const [deleteServiceModal, setDeleteServiceModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onChange = (checked) => {
    if (checked) {
      updateService(true);
    } else {
      updateService(false);
    }
  };

  const deleteService = async () => {
    try {
      setIsLoading(true);
      await axiosInstance.delete(
        `${process.env.REACT_APP_BASE_API_URL}/api/company/addon/${serviceData?.id}/`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("access_token")}`,
          },
        }
      )
      getServices();
      setDeleteServiceModal(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const updateService = async (status) => {
    try {
      setIsLoading(true);
      await axiosInstance.put(
          `${process.env.REACT_APP_BASE_API_URL}/api/company/addon/${serviceData?.id}/`,
          {
            is_active: status,
          },
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem("access_token")}`,
            },
          }
        )
      if (activeTab === "All") {
        getServices();
      } else if (activeTab == "Active") {
        getServices("True");
      } else if (activeTab === "Inactive") {
        getServices("False");
      } else {
        getServices();
      }
      setEditServiceModal(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="service-card-wrapper">
        <div className="service-header">
          <div className="service-name">{serviceData?.name}</div>
          <div className="service-price">${serviceData?.total_price}</div>
        </div>
        <div className="service-img">
          <div className="btns">
            <span
              className="btn"
              onClick={() => {
                setEditServiceModal(true);
              }}
            >
              <EditIcon />
            </span>
            <span
              className="btn"
              onClick={() => {
                setDeleteServiceModal(true);
              }}
            >
              <DeleteIcon />
            </span>
          </div>
          <img
            src={serviceData?.images?.length ? serviceData?.images[0]?.image : cardImgN}
            alt="card-pic"
          />
        </div>

        <div className="service-description">
          <p
            className="line-clamp"
            dangerouslySetInnerHTML={{
              __html: serviceData?.description,
            }}
          ></p>
        </div>
        <div className="service-details">
          <div className="info-wrapper">
            <div className="icon-text">
              <span className="icon">
                <RoomIconBlack />
              </span>
              &nbsp;
              <span>{serviceData?.room?.name}</span>
            </div>
            <div className="value">
              <LockIcon />
            </div>
          </div>
        </div>
        <div className="service-footer">
          <div>
            <Switch
              defaultChecked={serviceData?.is_active}
              onChange={onChange}
              className="service-switch"
              size="small"
            />{" "}
            <span>{serviceData?.is_active ? "Active" : "Inactive"}</span>
          </div>
        </div>
      </div>
      <EditServiceForm
        serviceData={serviceData}
        open={editServiceModal}
        getServices={getServices}
        setOpen={setEditServiceModal}
      />
      <ConfirmationModal
        open={deleteServiceModal}
        setOpen={setDeleteServiceModal}
        isLoading={isLoading}
        onConfirm={deleteService}
        content={`Are you sure you want to delete ${serviceData?.name}?`}
      />
    </>
  );
};

export default ServiceCard;
