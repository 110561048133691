import React, { useState, useEffect } from 'react';
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import usePopup from '../../../../../common/MessageModal/usePopup';
import { madePayment } from '../../../../../services/events';

// UI's
import { Button, Modal } from 'antd';
import { Form, Input } from 'antd';


import { ReactComponent as CardIcon } from '../../../../../assets/icons/credit-card.svg';
import { ReactComponent as CheckIcon } from '../../../../../assets/icons/check.svg';
import { ReactComponent as CashIcon } from '../../../../../assets/icons/cash.svg';

import ImageUploader from '../../../../../components/ImageUploader/';
import myApi from '../../../../Integrations/PaymentIntegration/UserData';


const PaymentMode = ({ open, handelClose, payment, setPayButtonLoading, fetchAllPayments, event }) => {
    const popup = usePopup();
    const [form] = Form.useForm();
    const [paymentType, setPaymentType] = useState('credit');
    const [chequeImage, setChequeImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const stripe = useStripe()
    const elements = useElements()

    const handleCancel = () => {
        handelClose();
        form.resetFields();
        setChequeImage(null);
        setPaymentType('credit');
    }

    const madePaymentOnServer = async (id) => {
        let formData = new FormData();
        // if (paymentType === 'cheque') {
        //     formData.append("file", chequeImage[0]?.originFileObj);
        // }
        if (paymentType === 'cheque' && chequeImage.length > 0) {
            for (let i = 0; i < chequeImage.length; i++) {
                formData.append("files", chequeImage[i].originFileObj);
            }
        }
        formData.append("payment_id", payment?.id);
        formData.append("type", paymentType);
        formData.append("paid_by_email", event?.contacts[0]?.email);
        setLoading(true);
        try {
            const res = await madePayment(formData);
            popup.success('Success!', res.data.message);
            handelClose();
            form.resetFields();
            fetchAllPayments();
        } catch (error) {
            popup.error('Opss!', error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async (values) => {
        if (paymentType === 'cash' || paymentType === 'cheque') {
            madePaymentOnServer(payment?.id);
            return;
        }

        setLoading(true);
        // get payment intent client secret from backend
        let clientSecret;
        await myApi.post(`${process.env.REACT_APP_BASE_API_URL}/api/company/payment/intent/`, { payment_id: payment?.id }).then(async (result) => {
            clientSecret = result.data.data
            const card = elements.getElement(CardElement);
            await stripe.confirmCardPayment(clientSecret?.client_secret ? clientSecret?.client_secret : clientSecret, {
                payment_method: {
                    card: card,
                },
            }).then((result) => {
                if (result.error) {
                    popup.error('Opss!', result.error.message);
                    setLoading(false);
                } else {
                    setPayButtonLoading(payment?.id);
                    setLoading(true);
                    myApi.post(`${process.env.REACT_APP_BASE_API_URL}/api/company/payment/confirm/`, { payment_id: payment?.id, payment_intent_id: result.paymentIntent.id }).then((result) => {
                        popup.success('Success!', result.data.message);
                        handelClose();
                        form.resetFields();
                        fetchAllPayments();
                    }).catch((error) => {
                        popup.error('Opss!', error?.response?.data?.message);
                    }).finally(() => {
                        setLoading(true);
                        setPayButtonLoading(false);
                    })
                }
            }).finally(() => {
                setLoading(false);
            });
        }).catch((error) => {
            let message = error?.response?.data?.message;
            let errorMessage = ""
            if (Array.isArray(message)) {
                for (let i = 0; i < message.length; i++) {
                    let msg = message[i];
                    // if msg is an object
                    if (typeof msg === 'object') {
                        for (let key in msg) {
                            errorMessage += `${msg[key][0]}`;
                        }
                    } else if (typeof msg === 'string') {
                        errorMessage += `${msg}`
                    }
                }
            }
            popup.error('Opss!', errorMessage);
        })
            .finally(() => {
                setLoading(false);
            });
    }

    const renderdButtons = () => {
        let buttons = [
            <Button size={'large'} key="cancel" onClick={handleCancel}>
                Cancel
            </Button>,
            <Button style={{ marginLeft: 'auto' }} size={'large'} key="next" type="primary"
                onClick={() => form.submit()}
                loading={loading}
            >
                Pay
            </Button>
        ];
        return buttons
    }

    const payment_types = [
        {
            label: 'Credit',
            value: 'credit',
            icon: <CardIcon />
        },
        {
            label: 'Check',
            value: 'cheque',
            icon: <CheckIcon />
        },
        {
            label: 'Cash',
            value: 'cash',
            icon: <CashIcon />
        }
    ]

    return open ? (
        <Modal
            centered
            title={"Payment"}
            open={open}
            onCancel={handleCancel}
            footer={renderdButtons()}
        >
            <Form
                style={{ marginTop: 24 }}
                form={form}
                name="validateOnly"
                layout="vertical"
                autoComplete="off"
                className='modal-form payments-modal'
                onFinish={handleSubmit}
            >
                <div className="options">
                    {payment_types.map((item, i) => (
                        <div
                            key={i}
                            className={`item ${item.value === paymentType && 'active'}`}
                            onClick={() => setPaymentType(item.value)}
                        >
                            {item.icon}
                            {item.label}
                        </div>
                    ))}
                </div>
                {paymentType === 'credit' ? (
                    <>
                        <Form.Item
                            name="name"
                            label="Name on card*"
                            rules={[
                                {
                                    required: true,
                                    message: 'Name is required!'
                                },
                            ]}
                        >
                            <Input size="large" placeholder="Type here..." />
                        </Form.Item>
                        <Form.Item
                            label="Card Info"
                        >
                            <CardElement
                                id="card-element"
                            />
                        </Form.Item>
                    </>
                ) : null}
                {paymentType === 'cheque' ? (
                    <Form.Item
                        name="cheque"
                        label="Check front & back Images*"
                        rules={[
                            {
                                required: true,
                                message: 'Check front and back images are required!'
                            },
                        ]}
                    >
                        <ImageUploader
                            max_length={2}
                            input={chequeImage}
                            output={(val) => {
                                if (val?.length > 1) {
                                    form.setFieldValue('cheque', true);
                                } else {
                                    form.setFieldValue('cheque', '');
                                }
                                setChequeImage(val);
                            }}
                        />
                    </Form.Item>
                ) : null}
                {paymentType === 'cash' ? (
                    <>
                        <Form.Item
                            label="Host"
                        >
                            <Input
                                value={event?.contacts[0]?.first_name}
                                size="large"
                                readOnly
                                placeholder="Host name missing"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Amount"
                        >
                            <Input
                                value={payment?.payment_expected}
                                size="large"
                                readOnly
                                placeholder="Amount missing"
                            />
                        </Form.Item>
                    </>
                ) : null}
            </Form>
        </Modal>
    ) : null
}

export default PaymentMode
