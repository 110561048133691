import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import {
    Card,
    Empty,
    Button,
    Spin,
    Typography
} from "antd";
import {
    ClockCircleFilled,
    CaretDownOutlined,
    CaretRightOutlined,
    CaretLeftOutlined
} from "@ant-design/icons";
import { getEvents } from "../../services/events";
import dayjs from 'dayjs';
import { Calendar, Select } from 'antd';
import './styles.scss';

const CalendarEvents = () => {
    const { Text, Title } = Typography;
    const location = useSelector((state) => state.venues.selectedVenue);
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));

    const fetchEvents = async () => {
        setLoading(true);
        try {
            const res = await getEvents({ location: location?.id, page_size: 100 });
            setEvents(res?.data?.results);
        } catch (error) {
            toast.error(error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (location?.id) {
            fetchEvents();
        }
    }, [location]);


    const customHeader = ({ value, onChange }) => {
        const start = 0;
        const end = 12;
        const monthOptions = [];
        let current = value.clone();
        const localeData = value.localeData();
        const months = [];
        for (let i = 0; i < 12; i++) {
            current = current.month(i);
            months.push(localeData.monthsShort(current));
        }
        for (let i = start; i < end; i++) {
            monthOptions.push(
                <Select.Option key={i} value={i} className="month-item">
                    {months[i]}
                </Select.Option>,
            );
        }
        const year = value.year();
        const month = value.month();
        const options = [];
        for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
                <Select.Option key={i} value={i} className="year-item">
                    {i}
                </Select.Option>,
            );
        }

        return (
            <div
                style={{
                    paddingBlock: 8,
                    paddingInline: 16,
                }}
            >
                <div className="calendar-header">
                    <Button
                        type="text"
                        className="month-switch"
                        icon={<CaretLeftOutlined />}
                        size={'large'}
                        onClick={() => {
                            const now = value.clone().month(month - 1);
                            onChange(now);
                        }}
                        style={{
                            marginRight: 'auto'
                        }}
                    />
                    <Select
                        size="large"
                        bordered={false}
                        popupMatchSelectWidth={false}
                        value={month}
                        suffixIcon={<CaretDownOutlined />}
                        style={{ minWidth: 80 }}
                        onChange={(newMonth) => {
                            const now = value.clone().month(newMonth);
                            onChange(now);
                        }}
                    >
                        {monthOptions}
                    </Select>
                    <Select
                        size="large"
                        style={{ minWidth: 90 }}
                        bordered={false}
                        popupMatchSelectWidth={false}
                        className="my-year-select"
                        suffixIcon={<CaretDownOutlined />}
                        value={year}
                        onChange={(newYear) => {
                            const now = value.clone().year(newYear);
                            onChange(now);
                        }}
                    >
                        {options}
                    </Select>
                    <Button
                        type="text"
                        className="month-switch"
                        icon={<CaretRightOutlined />}
                        size={'large'}
                        onClick={() => {
                            const now = value.clone().month(month + 1);
                            onChange(now);
                        }}
                        style={{
                            marginLeft: 'auto'
                        }}
                    />
                </div>
            </div>
        );
    }


    const customCellRender = (date) => {
        const dateValue = date.date();
        const formatedDate = dayjs(date).format('YYYY-MM-DD');
        const eventDates = events.length ? events?.map((item) => (item.event_date)) : [];
        const checkEvent = eventDates?.length ? eventDates.includes(formatedDate) : false;
        return checkEvent ? (
            <div className="ant-picker-cell-inner ant-picker-calendar-date">
                <div className="ant-picker-calendar-date-value have-events">
                    {dateValue}
                </div>
            </div>
        ) : (
            <div className="ant-picker-cell-inner ant-picker-calendar-date">
                <div className="ant-picker-calendar-date-value">
                    {dateValue}
                </div>
            </div>
        );
    };

    return (
        <Spin spinning={loading} size="medium">
            <Card className="Dashboard-cards calendar-card-dash">
                <Title level={3} >
                    Calendar
                </Title>
                <div className="calendar-content-wrapper">
                    <div className="calendar-wrapper">
                        <Calendar
                            fullscreen={false}
                            headerRender={customHeader}
                            onChange={(value) => setSelectedDate(value.format('YYYY-MM-DD'))}
                            dateFullCellRender={customCellRender}
                        />
                    </div>
                    <div className="events-wrapper">
                        <Title level={4}>
                            {dayjs(selectedDate).format('MMMM DD, YYYY')}
                        </Title>
                        {selectedDate && events?.filter(item => item.event_date === selectedDate)?.length ? events.filter(item => item.event_date === selectedDate).map((event, i) => (
                            <Card className="event-card" key={i}>
                                <Title level={5}>{event?.title}</Title>
                                <Text type="secondary"><ClockCircleFilled style={{ marginRight: 6 }} />
                                    {event?.start_time ? dayjs(event?.start_time, 'HH:mm:ss').format('hh:mm A') : 'NaN'}{' '}-{' '}
                                    {event?.end_time ? dayjs(event?.end_time, 'HH:mm:ss').format('hh:mm A') : 'NaN'}
                                </Text>
                            </Card>
                        )) : (
                            <Empty
                                className="empty-msg"
                                description="No Events on this date"
                            />
                        )}
                    </div>
                </div>
            </Card>
        </Spin>
    )
}

export default CalendarEvents
