export const theme = {
    token: {
        colorPrimary: '#0499CB',
    },
    components: {
        Input: {
            borderColor: '#E0E2E7',
            borderRadius: '8px',
        },
    },
}