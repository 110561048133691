import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Select, Dropdown, Menu, Badge, Button, Empty } from "antd";
import {
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import InactivityRedirect from "../../InactivityRedirect";
import {
  setVenues,
  setVenue,
  setVenuesLoading,
} from "../../../store/VenuesSlice";
import { setLoading, setCompany } from "../../../store/CompanySlice";
import { getVenues } from "../../../services/venues";
import { getCompanyDetails } from "../../../services/dashboard";
import CompanyForm from "../../../pages/CompanySettings/comps/forms/CompanyForm";
import { formatDistanceToNow } from "date-fns";

import bell from "../../../assets/Header/bell.png";
import Search from "./Search";
import "./Header.scss";
import { useNavigate } from "react-router";

const Notification = (props) => {
  const navigate = useNavigate();
  let userInfo = localStorage.getItem("planspace_user_obj");
  userInfo = JSON.parse(userInfo);

  const [notifications, setNotifications] = useState([]);
  //expand notifcation
  const [expanded, setExpanded] = useState(false);
  const [socket, setSocket] = useState(null);
  const [accessToken, setAccessToken] = useState(userInfo?.access);

  const handleLogoutUsingWebSocket = (user_id) => {
    if (userInfo?.id === user_id) {
      localStorage.removeItem("planspace_user_obj");
      localStorage.removeItem("selectedVanue");
      setAccessToken(null);
      navigate("/login");
    }
  };

  useEffect(() => {
    if (accessToken) {
      let socket = new WebSocket(
        `${process.env.REACT_APP_BASE_SOCKET_URL}/ws/company/notification?token=${accessToken}`
      );
      setSocket(socket);
    }
  }, []);

  useEffect(() => {
    if (accessToken && socket) {
      socket.onopen = (event) => {
        console.log("Connection established");
      };

      socket.onmessage = function (event) {
        let data = JSON.parse(event.data);
        if (data?.type == "notification") {
          setNotifications(data?.data);
        } else if (data?.type == "logout") {
          handleLogoutUsingWebSocket(data?.user_id);
        }
      };


      // creating new dev for planspace
      socket.onerror = function (error) {
        console.error("Error:", error);
      };

      socket.onclose = function (event) {
        if (event.wasClean) {
          console.log(
            `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
          );
        } else {
          console.log("[close] Connection died");
        }
      };
    }
  }, [accessToken, socket]);

  const clearNotifications = () => {
    const message = {
      notification_id: null,
    };
    socket.send(JSON.stringify(message));
  };
  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);

  const handleNotification = (action, notificationId = null) => {
    const data = {
      notification_id: notificationId,
      action: action,
    };
    socket.send(JSON.stringify(data));
    // setNotificationMenuOpen(true);
  };
  const formatRelativeTime = (timestamp) => {
    const relativeTime = formatDistanceToNow(new Date(timestamp), {
      addSuffix: true,
    });

    return relativeTime.replace("about ", "");
  };

  const getRandomCategoryColor = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return `#${randomColor}`;
  };

  //colors of accounts on catogory
  function getCategoryColor(description_html, category) {
    if (
      description_html &&
      description_html.toLowerCase().includes("stripe account") &&
      description_html.toLowerCase().includes("created")
    ) {
      return "#008000"; // green color for "Stripe Account Created"
    }
    if (
      description_html &&
      description_html.toLowerCase().includes("stripe account") &&
      description_html.toLowerCase().includes("relinked")
    ) {
      return "#3B71CA"; // Blue color for "Stripe Account Created"
    }
    if (
      description_html &&
      description_html.toLowerCase().includes("stripe account") &&
      description_html.toLowerCase().includes("delinked")
    ) {
      return "#DC4C64"; // red color for "Stripe Account Created"
    }
    if (
      description_html &&
      description_html.toLowerCase().includes("stripe account") &&
      description_html.toLowerCase().includes("linked")
    ) {
      return "#3B71CA"; // Blue color for "Stripe Account Created"
    }
    if (
      description_html &&
      description_html.toLowerCase().includes("stripe account") &&
      description_html.toLowerCase().includes("deleted")
    ) {
      return "#DC4C64"; // red color for "Stripe Account Created"
    }
    if (category == "Open & Lead") {
      return "#008000";
    }
    if (category == "Booked & Lead") {
      return "#E4A11B";
    }
  }

  // hover effects on notifications by unique id
  const handleMouseEnter = (itemId) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((item) =>
        item.id === itemId ? { ...item, isHovered: true } : item
      )
    );
  };
  const handleMouseLeave = (itemId) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((item) =>
        item.id === itemId ? { ...item, isHovered: false } : item
      )
    );
  };

  const notificationMenu = (
    <Menu className="notifcation-container" style={{ marginTop: "0.3rem" }}>
      <div className="menu-title">
        <h4>Notifications</h4>
        <Button
          type=""
          icon={<CloseOutlined />}
          style={{ borderRadius: "50px" }}
          onClick={() => setNotificationMenuOpen(false)}
        />
      </div>
      <div
        className="custom-scrollbar"
        style={{
          overflowY: "auto",
          maxWidth: "400px",
          maxHeight: expanded ? "600px" : "400px",
        }}
      >
        {notifications.length > 0 ? (
          notifications.map((item) => (
            <Menu.Item
              key={item.id}
              style={{
                backgroundColor: item.is_dismissed ? "transparent" : "#e6f7ff", // #e6f7ff Light blue for dismissed items
                maxWidth: "400px",
                marginTop: "5px",
              }}
            >
              <div
                className="notify-time"
                style={{ display: "flex", alignItems: "center" }}
              >
                <ClockCircleOutlined style={{ fontSize: "20px" }} />
                <p style={{ marginLeft: "8px", fontSize: "15px" }}>
                  {formatRelativeTime(item?.created_date)}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3 className="menu-heading">{item?.notification?.title}</h3>
                <Button
                  type=""
                  icon={<CloseCircleOutlined />}
                  style={{ borderRadius: "50px" }}
                  onClick={() => handleNotification("delete", item?.id)}
                />
              </div>
              <p className="menu-description">
                {item?.notification?.description_html && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item?.notification?.description_html,
                    }}
                  />
                )}
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    color: getCategoryColor(
                      item?.notification?.description_html,
                      item?.notification?.category
                    ),
                  }}
                >
                  {item?.notification?.category}
                </p>

                <Button
                  type="text"
                  className={
                    item.is_dismissed ? "read-button" : "unread-button"
                  }
                  style={{ color: "#0499CB", fontWeight: "bold" }}
                  icon={<CheckOutlined twoToneColor="#52c41a" />}
                  onClick={() => handleNotification("read", item?.id)}
                >
                  Mark as Read
                </Button>
              </div>
              <div className="notification-separator"></div>
            </Menu.Item>
          ))
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Empty description="No notifications right now!" />
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#ffff",
          marginTop: "5px",
        }}
      >
        {notifications.length > 0 && (
          <Button
            type="primary"
            key="clear"
            onClick={() => handleNotification("delete_all")}
          >
            Clear Notifications
          </Button>
        )}
        {notifications.length > 0 && (
          <Button
            className="see_more_btn"
            key="see-more"
            type="primary"
            onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
          >
            {expanded ? "See Less" : "See More"}{" "}
            {expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
          </Button>
        )}
      </div>
    </Menu>
  );

  return (
    <div style={{ cursor: "pointer", marginRight: "30px" }}>
      <Dropdown
        overlay={notificationMenu}
        placement="bottomRight"
        trigger={["click"]}
        onVisibleChange={(visible) => setNotificationMenuOpen(visible)}
        visible={notificationMenuOpen}
      >
        <Badge
          count={
            notifications.filter((notification) => !notification.is_dismissed)
              .length
          }
        >
          <Button
            icon={<img src={bell} />}
            style={{ marginLeft: "20px", background: "none", border: "none" }}
          />
        </Badge>
      </Dropdown>
    </div>
  );
};

const Header = (props) => {
  const { vanues, selectedVenue, loading, dispatch, company } = props;
  const access_token = localStorage.getItem("access_token");
  const [updateCompany, setUpdateCompany] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);

  const fetchVenues = async () => {
    dispatch(setVenuesLoading(true));
    try {
      const res = await getVenues({ page_size: 100 });
      dispatch(setVenues(res?.data?.results));
    } catch (error) {
      console.error("Venues Error: ", error);
    } finally {
      dispatch(setVenuesLoading(false));
    }
  };

  const fetchCompany = async () => {
    dispatch(setLoading(true));
    try {
      const res = await getCompanyDetails();
      dispatch(setCompany(res?.data?.results[0]));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    fetchVenues();
    fetchCompany();
  }, []);

  useEffect(() => {
    if (!selectedVenue) {
      const oldVenue = localStorage.getItem("selectedVanue");
      if (oldVenue) {
        dispatch(setVenue(JSON.parse(oldVenue)));
      }
      if (!oldVenue && vanues?.length) {
        dispatch(setVenue(vanues[0]));
      }
    }
  }, [access_token, vanues, selectedVenue]);

  useEffect(() => {
    if (selectedVenue) {
      localStorage.setItem("selectedVanue", JSON.stringify(selectedVenue));
    }
  }, [selectedVenue]);

  useEffect(() => {
    if (company && !company?.name) {
      setUpdateCompany(true);
    }
  }, [company]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        minWidth: "100%",
        position: "fixed",
        zIndex: 999,
        backgroundColor: "rgb(249, 253, 255)",
        height: 75,
      }}
    >
      <InactivityRedirect timeout={3600000} redirectUrl="/login" />
      <div className="searchbar-wrapper">
        <Search />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "auto",
          position: "fixed",
          right: 10,
        }}
      >
        <Select
          value={
            vanues?.length && selectedVenue
              ? { value: selectedVenue?.id, label: selectedVenue?.name }
              : ""
          }
          style={{
            width: 220,
          }}
          onChange={(val, obj) => dispatch(setVenue(obj))}
          onDropdownVisibleChange={(open) => (open ? fetchVenues() : null)}
          size="large"
          loading={loading}
          options={
            vanues.length
              ? vanues.map((item) => ({
                value: item.id,
                label: item.name,
                id: item.id,
              }))
              : []
          }
          className="select-venue"
        />

        <CompanyForm
          open={updateCompany}
          handelClose={() => setUpdateCompany(false)}
          company={company}
        />
        {openNotification && <Notification />}
        <Notification />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  vanues: state.venues.venues,
  company: state.company.company,
  selectedVenue: state.venues.selectedVenue,
  loading: state.venues.venuesLoading,
});

export default connect(mapStateToProps)(Header);
