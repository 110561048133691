import React from "react";
import dayjs from "dayjs";
import usePopup from "../../../common/MessageModal/usePopup";
import { Typography, Avatar, Button, Dropdown, Space, Col } from "antd";
import { UserOutlined, MoreOutlined } from "@ant-design/icons";
import { deleteEvent } from "../../../services/events";
import StagesDropdown from "./StagesDropdown";
import StatusDropdown from "./StatusDropdown";
import styles from "./comps.module.scss";
import { Link } from "react-router-dom";

const ListCard = ({ event, fetchEvents, setCreateNewEvent }) => {
  const popup = usePopup();
  const { Text, Title } = Typography;
  const { title, event_date, start_time, end_time, contacts, status } = event;

  const eventDelete = async () => {
    try {
      const res = await deleteEvent(event.id);
      popup.success('Success!', res?.data?.message);
      fetchEvents();
    }catch (error) {
      const errorCode = error?.response?.status || 500; 
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = '/server-error';
        console.log("Redirecting to server error page...");
      } else {
        popup.error("Oops...", error?.response?.data?.message);     
      }
  }
  }

  const items = [
    {
      label: (
        <a onClick={() => setCreateNewEvent({ ...event, type: "edit" })}>
          Edit
        </a>
      ),
      key: 0,
    },
    {
      label: <a onClick={() => popup.confirm("Delete " + event?.title, "Are you sure? This action is permanent and cannot be undone.", eventDelete)}>Delete</a>,
      key: 1,
    },
  ];

  return (
    <Col span={24}>
      <div className={`${styles.ListCard} ${styles[status]}`}>
        <Title level={5} style={{ marginBottom: 0, width: "15%" }}>
          <Link to="/event/details" style={{ color: "black" }} state={event}>
            {title}
          </Link>
        </Title>
        <div className={styles.labelWrapper}>
          <Text type="secondary" style={{ fontSize: 14 }}>
            Event Date
          </Text>
          <Text style={{ fontWeight: 500, fontSize: 16 }}>
            {dayjs(event_date).format("DD MMM YYYY")}
          </Text>
        </div>
        <div className={styles.labelWrapper}>
          <Text type="secondary" style={{ fontSize: 14 }}>
            Time
          </Text>
          <Text style={{ fontWeight: 500, fontSize: 16 }}>
            {start_time ? dayjs(start_time, "hh:mm:ss").format("h:mm A") : '-'}{" "}{start_time && end_time ? 'To' : null}{" "}
            {end_time ? dayjs(end_time, "hh:mm:ss").format("h:mm A") : '-'}
          </Text>
        </div>
        <div className={styles.labelWrapper}>
          <Text type="secondary" style={{ fontSize: 14 }}>
            Organizer
          </Text>
          <div className={styles.organizer}>
            <Avatar
              size="small"
              icon={<UserOutlined />}
            />
            <Text style={{ fontWeight: 500, fontSize: 14, color: "#4A4C56" }}>
              {contacts[0]?.full_name || '-'}
            </Text>
          </div>
        </div>
        <div className={styles.labelWrapper}>
          <Text type="secondary" style={{ fontSize: 14 }}>
            Email
          </Text>
          <Text style={{ fontWeight: 500, fontSize: 16 }}>
            {contacts[0]?.email}
          </Text>
        </div>
        {/* <div className={styles.status}>
          {event?.status ? event?.status : "Open"}
        </div> */}
        <StatusDropdown
          style={{ width: 208 }}
          eventId={event?.id}
          value={event?.status}
          fetchEvents={fetchEvents}
        />
        <StagesDropdown
          style={{ width: 208 }}
          event={event}
          value={event?.stage}
          fetchEvents={fetchEvents}
        />
        <Space>
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
            placement="bottomRight"
          >
            <Button
              type="text"
              shape="circle"
              icon={<MoreOutlined />}
              size={24}
            />
          </Dropdown>
        </Space>
      </div>
    </Col>
  );
};

export default ListCard;
