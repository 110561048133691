import React, { useState, useEffect, useRef } from 'react'
import axios from './MyApi'
import { Card, Button, Row, Col, Tooltip, Spin } from 'antd';
import './paymentIntegration.scss'
import { accountMessages } from './StripeMessages'
import { useNavigate } from 'react-router';
import StripeErrors from './StripeErrors';
import { CheckCircleTwoTone } from '@ant-design/icons';
import ChangeInfo from './Modals/ChangeInfo';
import PersonalInformation from './PersonalInformatiom';
import CompanyInformation from './CompanyInformation';
import toast from 'react-hot-toast';
import myApi from '../../../network/axios';

const StripeAccountInfo = () => {
  const reloadIntervalRef = useRef(null);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState("")

  useEffect(() => {
    getStripeAccountInfo();
  }, []);

  const getStripeAccountInfo = async () => {
    try {
      const response = await myApi.get(`${process.env.REACT_APP_BASE_API_URL}/api/company/integration/stripe/`);
      setAccount(response.data.data);
    } catch (error) {
      navigate("/Integration/LinkStripe")
    } finally {
      // setLoading(false);
    }
  };

  const removeAccount = async () => {
    setLoading(true);
    await myApi
      .post(`${process.env.REACT_APP_BASE_API_URL}/api/company/integration/stripe/delink/`)
      .then((result) => {
        setLoading(false);
        toast.success("Account Removed Successfully!", {
          position: "top-right",
        });
        getStripeAccountInfo();
      })
      .catch((error) => {
        setLoading(false);
        toast.success("Error while removing account!", {
          position: "top-right",
        });
      });
  }

  const returnError = (error) => {
    const subString = error.substring(error.indexOf("."));
    const obj = accountMessages.find(el => el.name === subString);
    return obj ? obj.message : stripeErrorsReturn(error);
  }

  const stripeErrorsReturn = (error) => {
    const obj = account.errors.find(el => el.requirement === error);
    return obj ? obj.reason : error
  }

  useEffect(() => {
    const reloadPage = () => {
      window.location.reload();
    };

    if (account?.disabled_reason === 'requirements.pending_verification') {
      reloadIntervalRef.current = setInterval(reloadPage, 120000);
    }

    return () => {
      clearInterval(reloadIntervalRef.current);
    };
  }, [account]);

  return (
    <div>
      {account ? (
        <Card className='account-info-wrapper'>
          <div className='account-header'>
            <div>
              <h1>Welcome! {account?.individual?.first_name} {account?.individual?.last_name}
                {account?.status === 'active' ? (
                  <span className="status" style={{ marginLeft: 10, marginTop: 5 }}>
                    <Tooltip title={account?.status} placement="top">
                      <CheckCircleTwoTone twoToneColor="#52c41a" />
                    </Tooltip>
                  </span>
                ) : (
                  <StripeErrors data={account} />
                )} </h1>
              {account?.status === 'active' ? (
                <p>Your Stripe account is all set to receive payments.</p>
              ) : account?.disabled_reason === 'requirements.pending_verification' ? (
                <p style={{ color: '#d32f2f' }}>Stripe is currently verifying information that you submitted.</p>
              ) : (
                <p style={{ color: '#d32f2f' }}>
                  Your Stripe account status is {account?.status}, Please correct/Update the below information:
                  <ul>
                    {account?.currently_due?.map((msg, i) => (<>
                      <li key={i}>{returnError(msg)}</li>
                    </>))}
                  </ul>
                </p>
              )}
            </div>
            <Button danger loading={loading} onClick={() => removeAccount()}>Remove Account</Button>
          </div>

          <PersonalInformation
            person={account?.individual}
            accountID={account?.account_id}
            callback={getStripeAccountInfo}
          />
          {account?.business_type === "company" ?
            <CompanyInformation
              company={account?.company}
              accountID={account?.account_id}
              callback={getStripeAccountInfo}
            />
            : null}

          <Card className="inner-card" style={{ marginTop: "20px" }}>
            <div>
              <h2>Bank Information</h2>
              <Row style={{ marginTop: "15px" }} gutter={[15, 15]}>
                <Col span={24} md={12}>
                  <p className='info-heading-stripe'>
                    Account Holder
                  </p>
                  <p style={{ marginTop: "0px" }}>{account?.external_account?.account_holder_name}</p>
                </Col>
                <Col span={24} md={12}>
                  <p className='info-heading-stripe'>
                    Account Number
                  </p>
                  <p style={{ marginTop: "0px" }}>******{account?.external_account?.last4}</p>
                </Col>
                <Col span={24} md={12}>
                  <p className='info-heading-stripe'>
                    Routing Number
                  </p>
                  <p style={{ marginTop: "0px" }}>{account?.external_account?.routing_number}</p>
                </Col>
              </Row>
            </div>
          </Card>
        </Card>
      ) : (
        <Spin tip="Fetching Account Details" size="large">
          <div style={{
            height: "400px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50px",
            marginBottom: "50px",
            marginRight: "auto",
            marginLeft: "auto",
          }} />
        </Spin>
      )}
    </div>
  )
}

export default StripeAccountInfo