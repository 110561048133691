import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Row, Col, Result, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { getServices } from '../../services/services';

import Loader from '../../common/Loader';
import Layout from './Layout';
import ServiceCard from './comps/ServiceCard';
import ServicesForm from './comps/forms/ServicesForm';
import usePopup from '../../common/MessageModal/usePopup';

const Services = () => {
    const popup = usePopup();
    const current_venue = useSelector((state) => state?.venues?.selectedVenue?.id);
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState([]);
    const [servicesModal, setServicesModal] = useState(false);
    const [statusFilter, setStatusFilter] = useState('all');

    const fetchServices = async () => {
        setLoading(true);
        try {
            const res = await getServices({ location: current_venue, is_active: (statusFilter && statusFilter !== 'all') ? statusFilter : '', });
            setServices(res?.data?.results);
        } catch (error) {
            const errorCode = error?.response?.status || 500; 
            if (errorCode === 500 || errorCode === 503) {
              window.location.href = '/server-error';
              console.log("Redirecting to server error page...");
            } else {
              popup.error("Oops...", error?.response?.data?.message);     
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (current_venue) {
            fetchServices();
        }
    }, [current_venue]);

    useEffect(() => {
        if (current_venue) {
            fetchServices();
        }
    }, [statusFilter]);

    const SERVICES_STATUSES = [
        { value: "all", label: "All Services" },
        { value: 'true', label: "Active" },
        { value: 'false', label: "Inactive" },
    ];

    return (<>
        <Layout
            title={'Services'}
            addButton={
                <Button type='primary' size='large' icon={<PlusOutlined />} onClick={() => setServicesModal(true)}>
                    Add Service
                </Button>
            }
            items={[{ title: 'Company Settings' }, { title: 'Services' }]}
        >
            <div className={'filter-switch'}>
                {SERVICES_STATUSES?.map((item, i) => (<>
                    <Button
                        key={i}
                        className={`button ${statusFilter === item?.value && 'active'}`}
                        type="text"
                        onClick={() => setStatusFilter(item?.value)}
                    >
                        {item?.label}
                    </Button>
                </>))}
            </div>
            {loading && !services?.length ? (
                <Loader minHeight={400} />
            ) : (
                <Spin spinning={loading}>
                    <Row gutter={[24, 24]}>
                        {services?.length ? services.map((item, i) => (
                            <Col key={i} span={24} md={12} xl={8} xxl={6}>
                                <ServiceCard
                                    data={item}
                                    fetchServices={fetchServices}
                                    status_switch={true}
                                />
                            </Col>
                        )) : (
                            <Col span={24}>
                                <Result
                                    status="404"
                                    title="Oops! No services are found!"
                                    subTitle="Sorry, you don't have any services yet."
                                />
                            </Col>
                        )}
                    </Row>
                </Spin>
            )}
        </Layout>
        <ServicesForm
            open={servicesModal}
            handelClose={() => setServicesModal(false)}
            fetchServices={fetchServices}
        />
    </>)
}

export default Services;