import React, { useState, useEffect } from 'react';
import moment from 'moment';
import usePopup from '../../../../../common/MessageModal/usePopup';
// UI's
import { Card, Checkbox, Empty, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Loader from '../../../../../common/Loader';

import AddNewAppointment from '../Appointments/AddNewAppointment';

import { getAppointments, updateAppointment } from '../../../../../services/events';

const Appointments = ({ eventId }) => {
    const popup = usePopup();
    const [loading, setLoading] = useState(false);
    const [appointments, setAppointments] = useState([]);
    const [addNewAppointment, setAddNewAppointment] = useState(false);

    // Appointments
    const fetchAppointments = async (noLoading) => {
        setLoading(!noLoading);
        try {
            const res = await getAppointments(eventId);
            setAppointments(res?.data?.results);
        } catch (error) {
            popup.error('Oops!', error?.response?.data?.message, error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (eventId) {
            fetchAppointments();
        }
    }, [eventId]);

    if (loading) {
        return (
            <Card style={{ marginTop: '24px' }}>
                <Loader size={'default'} />
            </Card>
        )
    }

    return (
        <>
            <Card
                title="Appointments"
                style={{ marginTop: '24px' }}
                extra={<a onClick={() => setAddNewAppointment(true)} style={{ color: "#0B98D2" }}> <PlusOutlined /> Add Appointment</a>}

            >
                {appointments?.length ? appointments.map((item, i) => (
                    <div key={i} className='appointment-info'>
                        <h4 className='date'>{moment(item?.appt_start_date).format('DD MMM')}</h4>
                        <div className='details'>
                            <h4>{item?.title}</h4>
                            <p style={{ color: "#667085", fontSize: '12px' }}>{item?.description}</p>
                        </div>
                        <p className='time'>{moment(item?.appt_start_time, 'HH:mm:ss').format('LT')}</p>
                    </div>
                )) : (
                    <Empty description={'No Appointments'} />
                )}
            </Card>
            <AddNewAppointment
                open={addNewAppointment}
                eventId={eventId}
                handelClose={() => setAddNewAppointment(false)}
                refetch={() => fetchAppointments()}
            />
        </>
    )
}

export default Appointments