import React, { useState } from "react";
import { Row, Col, DatePicker, Modal, Input, Typography } from "antd";
import "./paymentIntegration.scss";
import dayjs from "dayjs";
import ReactInputMask from "react-input-mask";
import { toast } from "react-hot-toast";
import myApi from "../../../network/axios";
import StateAutoComplete from "../../../common/StateAutoComplete";

const UpdatePersonalInformation = ({
  open,
  setEditModal,
  accountID,
  personData,
  setPersonData,
  callback,
}) => {
  const { Title, Text } = Typography
  const [loading, setLoading] = useState(false);

  const handleFieldChange = (e, field, subfield) => {
    e.preventDefault();
    // If it's a nested field (e.g., address.city), update it accordingly
    if (subfield) {
      setPersonData({
        ...personData,
        [field]: {
          ...personData[field],
          [subfield]: e.target.value,
        },
      });
    } else {
      // If it's a top-level field, update it directly
      setPersonData({
        ...personData,
        [field]: e.target.value,
      });
    }
  };

  const handleDateChange = (date, dateString) => {
    let parts = dateString.split('-');
    let year = parseInt(parts[0], 10);
    let month = parseInt(parts[1], 10);
    let day = parseInt(parts[2], 10);
    let dob = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    setPersonData({
      ...personData,
      ["dob"]: dob,
    });
  };

  const handleUpdate = async () => {
    if (
      !personData.first_name ||
      !personData.last_name ||
      !personData.phone ||
      !personData.dob ||
      !personData.address.line1 ||
      !personData.address.city ||
      !personData.address.state ||
      !personData.address.postal_code
    ) {
      toast.error("Please fill all fields", {
        position: "top-right",
      });
    } else {
      setLoading(true);
      let payload = {
        account_id: accountID,
        id: personData?.id,
        type: "individual",
        individual: {
          first_name: personData?.first_name,
          last_name: personData?.last_name,
          phone: personData?.phone.replace(/[^0-9]/g, ""),
          email: personData?.email,
          address: {
            line1: personData?.address?.line1,
            line2: personData?.address?.line2 || "",
            city: personData?.address?.city,
            state: personData?.address?.state,
            postal_code: personData?.address?.postal_code,
          },
        },
      };
      if (personData?.dob && typeof personData?.dob === "string") {
        let dob = {
          day: Number(personData?.dob?.split("-")[2]),
          month: Number(personData?.dob?.split("-")[1]),
          year: Number(personData?.dob?.split("-")[0]),
        };
        payload.individual.dob = dob;
      }
      await myApi
        .post(
          `${process.env.REACT_APP_BASE_API_URL}/api/company/integration/stripe/update/`,
          payload
        )
        .then((res) => {
          toast.success(res?.data?.message);
          setEditModal(false);
          setLoading(false);
          callback();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onOk={handleUpdate}
        onCancel={() => setEditModal(false)}
        okText="Update"
        confirmLoading={loading}
        cancelButtonProps={{
          disabled: loading,
        }}
      >
        <Title level={3} style={{ marginBottom: 0 }}>Personal Information</Title>
        <Text color="secondary">Update your personal information.</Text>
        <Row
          gutter={[16, 16]}
          style={{ marginBottom: "24px", marginTop: '16px' }}
        >
          <Col span={12}>
            <p>First Name</p>
            <Input
              type="text"
              placeholder="First Name"
              size="large"
              value={personData?.first_name}
              onChange={(e) => handleFieldChange(e, "first_name")}
            />
          </Col>
          <Col span={12}>
            <p>Last Name</p>
            <Input
              type="text"
              placeholder="Last Name"
              size="large"
              value={personData?.last_name}
              onChange={(e) => handleFieldChange(e, "last_name")}
            />
          </Col>
          <Col span={12}>
            <p>Phone Number</p>
            <ReactInputMask
              mask="(999) 999-9999"
              maskChar=""
              value={personData?.phone}
              onChange={(e) => handleFieldChange(e, "phone")}
            >
              {() => <Input placeholder="Phone Number" size="large" />}
            </ReactInputMask>
          </Col>
          <Col span={12}>
            <p>Date of Birth</p>
            <DatePicker
              style={{
                borderColor: "#ced4da",
              }}
              size="large"
              defaultValue={dayjs(
                personData?.dob?.year +
                "-" +
                personData?.dob?.month +
                "-" +
                personData?.dob?.day
              )}
              onChange={(date, dateString) =>
                handleDateChange(date, dateString)
              }
            />
          </Col>
          <Col span={24}>
            <p>Street Address</p>
            <Input
              placeholder="Street Address"
              size="large"
              value={personData?.address?.line1}
              onChange={(e) => handleFieldChange(e, "address", "line1")}
            />
          </Col>
          <Col span={9}>
            <p>City</p>
            <Input
              placeholder="City Name"
              size="large"
              value={personData?.address?.city}
              onChange={(e) => handleFieldChange(e, "address", "city")}
            />
          </Col>
          <Col span={8}>
            <p>State</p>
            <StateAutoComplete
              value={personData?.address?.state}
              onChange={(val) => {
                setPersonData({
                  ...personData,
                  address: {
                    ...personData.address,
                    state: val,
                  },
                });
              }}
            />
          </Col>
          <Col span={7}>
            <p>Postal Code</p>
            <Input
              placeholder="Postal Code"
              size="large"
              value={personData?.address?.postal_code}
              onChange={(e) => handleFieldChange(e, "address", "postal_code")}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default UpdatePersonalInformation;
