import React, { useState, useEffect } from 'react';
import InputMask from "react-input-mask";
import { useDispatch } from 'react-redux';
import SingleImageUploader from '../../../../components/ImageUploader/SingleImageUploader';
import usePopup from '../../../../common/MessageModal/usePopup';
import { setCompany } from '../../../../store/CompanySlice';

// UI's
import { Button, Modal } from 'antd';
import { Form, Input } from 'antd';
import { updateCompanyDetails } from '../../../../services/dashboard';
import StateAutoComplete, { validateStateInput } from '../../../../common/StateAutoComplete';
import CityAutoComplete from '../../../../common/CityAutoComplete';

const CompanyForm = ({ open, handelClose, company }) => {
    const dispatch = useDispatch();
    const popup = usePopup();
    const [form] = Form.useForm();
    const [logo, setLogo] = useState(null);
    const [loading, setLoading] = useState(false);
    const user_info = localStorage.getItem('planspace_user_obj');

    function formatPhoneNumber(phoneNumber) {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber;
    }

    useEffect(() => {
        if (company) {
            const { address_line1, phone, ...rest } = company
            form.setFieldsValue({ ...rest, phone: formatPhoneNumber(phone), address_line1: address_line1 ? address_line1 : '' });
        }
        if (company?.logo) {
            setLogo([company?.logo]);
        }
    }, [company, open]);

    const handleCancel = () => {
        if (company?.name) {
            handelClose();
            setLogo(null);
        }
    }

    const renderdButtons = () => {
        let buttons = [
            <Button style={{ marginLeft: 'auto' }} size={'large'} key="next" type="primary" loading={loading} onClick={() => form.submit()}>
                Update
            </Button>
        ];
        if (company?.name) {
            buttons.unshift(
                <Button size={'large'} key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>
            );
        }
        return buttons
    }

    const handleSubmit = async (values) => {
        // if city, state or zip_code is not provided then delete it from values
        console.log(values);
        if (!values?.city && values?.city !== '') delete values.city;
        if (!values?.state && values?.state !== '') delete values.state;
        if (!values?.zip_code && values?.zip_code !== '') delete values.zip_code;
        const formData = new FormData();
        Object.entries(values)?.map(([key, value]) => {
            formData.append(key, key === 'state' ? value?.toUpperCase() : value);
        });
        if (logo) {
            if (logo[0]?.originFileObj) {
                formData.append('logo', logo[0]?.originFileObj);
            } else if (!typeof logo[0] === 'string') {
                formData.append('logo', '');
            }
        } else {
            formData.append('logo', '');
        }
        setLoading(true);
        try {
            const res = await updateCompanyDetails(company?.id, formData);
            popup.success("Success!", res?.data?.message);
            dispatch(setCompany(res?.data?.data));
            handleCancel();
        } catch (error) {
            const errorCode = error?.response?.status || 500;

            if (errorCode === 500 || errorCode === 503) {
                window.location.href = '/server-error';
                console.log("Redirecting to server error page...");
            } else {
                if (typeof error?.response?.data?.message === 'object' || 'array') {
                    const errors = error?.response?.data?.message[0];
                    Object.entries(errors).map(([field, message]) => {
                        form.setFields([
                            {
                                name: field,
                                errors: message,
                            },
                        ]);
                    });
                } else {
                    popup.error("Oops...", error?.response?.data?.message);
                }
            }
        }
        finally {
            setLoading(false);
        }
    }

    return open ? (
        <Modal
            centered
            title={"Update Company"}
            open={open}
            onCancel={handleCancel}
            footer={renderdButtons()}
            closable={Boolean(company?.name)}
        >
            <Form
                style={{ marginTop: 24 }}
                form={form}
                name="validateOnly"
                layout="vertical"
                autoComplete="off"
                className='modal-form'
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="name"
                    label="Company Name*"
                    rules={[
                        {
                            required: true,
                            message: 'Company name is required!'
                        },
                    ]}
                >
                    <Input maxLength={50} size="large" placeholder="Enter company name" />
                </Form.Item>
                <Form.Item
                    name={'email'}
                    label="Email*"
                    rules={[
                        {
                            required: true,
                            message: 'Email is required!',
                        },
                        {
                            type: 'email',
                            message: 'The email you entered doesn’t seem valid!',
                        },
                    ]}
                >
                    <Input size="large" disabled={user_info?.is_google_login} type="email" placeholder="Enter company email" />
                </Form.Item>
                <Form.Item
                    name={'phone'}
                    label="Phone*"
                    rules={[
                        {
                            required: true,
                            message: 'Phone is required!'
                        },
                        {
                            pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
                            message: 'Please enter a valid phone!'
                        }
                    ]}
                >
                    <InputMask
                        mask="(999) 999-9999"
                        maskChar=""
                    >
                        {() => <Input size="large" type="text" placeholder="Enter company phone" />}
                    </InputMask>
                </Form.Item>
                <Form.Item
                    name="address_line1"
                    label="Address*"
                    rules={[
                        {
                            required: true,
                            message: 'Address is required!'
                        },
                    ]}
                >
                    <Input maxLength={100} size="large" placeholder="Enter company address" />
                </Form.Item>
                <Form.Item
                    name="city"
                    label="City"
                >
                    <CityAutoComplete />
                </Form.Item>
                <div style={{ display: 'flex', gap: 16 }}>
                    <Form.Item
                        name="state"
                        label="State"
                        style={{ width: '100%' }}
                        rules={[
                            {
                                validator: validateStateInput,
                            },
                        ]}
                    >
                        <StateAutoComplete />
                    </Form.Item>
                    <Form.Item
                        name={'zip_code'}
                        label="Zip Code"
                        style={{ width: '100%', maxWidth: '40%' }}
                        rules={[
                            {
                                // pattern: /^\d{5}$/,
                                pattern: /^(?!0{5})[0-9]{5}$/,
                                message: 'Please enter valid zip code!'
                            },
                        ]}
                    >
                        <InputMask
                            mask="99999"
                            maskChar=""
                        >
                            {() => <Input size="large" type="text" placeholder="Enter Zipcode" />}
                        </InputMask>
                    </Form.Item>
                </div>
                <SingleImageUploader
                    input={logo}
                    output={setLogo}
                    revalidate={() => { }}
                />
            </Form>
        </Modal>
    ) : null
}

export default CompanyForm
