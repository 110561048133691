import axios from "axios";
// require("dotenv").config();

const myApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    Authorization: `JWT ${localStorage.getItem("access_token")}`,
    "content-type": "application/json",
  },
});

myApi.interceptors.request.use(
  (config) => {
    try {
      config.headers.Authorization = `JWT ${localStorage.getItem("access_token")}`;
      return config;
    } catch {
      return null;
    }
  },
  (error) => Promise.reject(error)
);

export default myApi;
