import React from "react";
import { Typography, Card, Form, Input, Row, Col } from "antd";
import InputMask from "react-input-mask";
import StateAutoComplete, { validateStateInput } from "../../../common/StateAutoComplete";

const ContactInfoForm = () => {
  const { Title } = Typography;

  return (
    <Card className="booking-card" style={{ marginBottom: 24 }}>
      <Title level={4}>Contact Information</Title>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Form.Item
            name="first_name"
            label="First Name*"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <Input size="large" placeholder="Enter your first name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="last_name"
            label="Last Name*"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <Input size="large" placeholder="Enter your last name" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name={"email"}
            label="Email*"
            rules={[
              {
                required: true,
                type: "email",
              },
            ]}
          >
            <Input size="large" type="email" placeholder="Enter your email" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"phone_number"}
            label="Phone*"
            rules={[
              {
                required: true,
                message: "Phone number is required!",
              },
              {
                pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
                message: "Please enter valid phone!",
              },
            ]}
          >
            <InputMask mask="(999) 999-9999" maskChar="">
              {() => (
                <Input size="large" type="text" placeholder="Enter your phone number" />
              )}
            </InputMask>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="address"
            label="Address*"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <Input maxLength={100} size="large" placeholder="Enter your address" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name={["zip_code", "city"]}
            label="City*"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <Input maxLength={25} size="large" placeholder="Enter your city name" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name={["zip_code", "state"]}
            label="State*"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
              {
                validator: validateStateInput,
              },
            ]}
          >
            <StateAutoComplete />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={["zip_code", "zip"]}
            label="Zip*"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
              {
                pattern: /^(?!0{5})[0-9]{5}$/,
                message: "Please enter valid zip code!",
              },
            ]}
          >
            <InputMask mask="99999" maskChar="">
              {() => <Input size="large" placeholder="Enter zipcode" />}
            </InputMask>
          </Form.Item>
        </Col>

        {/* <Col span={12}>
                    <Form.Item
                        name={['zip_code', 'country']}
                        label="Country*"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required!'
                            },
                        ]}
                    >
                        <Input size="large" placeholder="Type..." />
                    </Form.Item>
                </Col> */}
      </Row>
    </Card>
  );
};

export default ContactInfoForm;
