import { Modal } from "antd";
import React, { useState, useEffect } from "react";
import "./AddNewLocation.scss";

import { ReactComponent as CrossIcon } from "../../../../assets/Modals/CrossIcon.svg";
import { ReactComponent as BackArrow } from "../../../../assets/Modals/backArrow.svg";
import { Input, Row, Col } from "antd";
import AddNewRoom from "../AddNewRoom";
import AddImage from "../AddImage";
import axios from "axios";
import { Spin } from "antd";
import PhoneMask from "../../../../common/PhoneMask";
import ZipCodeMask from "../../../../common/ZipCodeMask";
import MessageModal from "../MessageModal";
import ImageUploader from "../../../../components/ImageUploader";
import StateAutoComplete from "../../../../common/StateAutoComplete";

const options = [];
for (let i = 10; i < 36; i++) {
    options.push({
        label: i.toString(36) + i,
        value: i.toString(36) + i,
    });
}

const AddNewLocation = ({ open, setOpen, getVenues }) => {
    const [isfirstScreen, setIsFirstScreen] = useState(true);
    // eslint-disable-next-line
    const [isSecondScreen, setIsSecondScreen] = useState(false);
    const [isAddNewRoom, setIsAddNewRoom] = useState(false);
    const [isAddImage, setIsAddImage] = useState(false);
    const [locationName, setLocationName] = useState("");
    const [locationAddress, setLocationAddress] = useState("");
    const [locationCity, setLocationCity] = useState("");
    const [locationState, setLocationState] = useState("");
    const [isSuccessModalOpen, setIsSuccessModelOpen] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);

    const [locationZipCode, setLocationZipCode] = useState("");
    const [isZipCodeError, seeIsZipCodeError] = useState(false);
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [isSpin, setIsSpin] = useState(false);
    const [file, setFile] = useState(null);
    const [uploadImageURL, setUploadImageURL] = useState("");

    // Add new room form
    const [roomName, setRoomName] = useState("");
    const [price, setPrice] = useState("");
    const [maxGuest, setMaxGuest] = useState("");
    const [roomAmenities, setRoomAmenities] = useState("");
    const [roomSpaces, setRoomSpaces] = useState("");
    const [roomCeremonyTypes, setRoomCeremonyTypes] = useState("");
    const [isEmailError, setEmailError] = useState(false);
    const [imageModal, setImageModal] = useState(false);
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (isSuccessModalOpen) {
            const timer = setTimeout(() => {
                closeSuccessModal();
            }, 2000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [isSuccessModalOpen]);

    const closeSuccessModal = () => {
        setIsSuccessModelOpen(false);
    };


    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const submitLocation = () => {
        try {
            setIsSpin(true);
            const formData = new FormData();
            file && formData.append("image", file);
            locationName && formData.append("name", locationName);
            locationAddress && formData.append("address_line1", locationAddress);
            locationCity && formData.append("city", locationCity);
            locationState && formData.append("state", locationState);
            locationZipCode && formData.append("zip_code", locationZipCode);
            phone && formData.append("phone", phone);
            email && formData.append("email", email);
            if (images.length > 0) {
                images.forEach((image) => formData.append('images', image.originFileObj));
            }
            axios
                .post(
                    `${process.env.REACT_APP_BASE_API_URL}/api/company/location/`,
                    formData,
                    {
                        headers: {
                            Authorization: `JWT ${localStorage.getItem("access_token")}`,
                        },
                    }
                )
                .then((res) => {
                    setIsSuccessModelOpen(true);
                    setUploadImageURL(null);
                    setImages([]);
                    setOpen(false);
                    setLocationName("");
                    setLocationAddress("");
                    setLocationCity("");
                    setLocationState("");
                    setLocationZipCode("");
                    setPhone("");
                    setEmail("");
                    setRoomName("");
                    setPrice("");
                    setMaxGuest("");
                    setRoomAmenities("");
                    setRoomSpaces("");
                    setRoomCeremonyTypes("");
                    setIsSpin(false);
                    getVenues();
                }).catch((error) => {
                    setIsSpin(false);
                })
        } catch (error) {
            setIsSpin(false);
        }
    };

    const dummyRequest = async ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const props = {
        name: "file",
        multiple: false,
        maxCount: 1,
        customRequest: dummyRequest,
        onRemove: (file) => {
            // setFile(null);
            // setUploadImageURL("");
        },

        // beforeUpload(file, fileList) {
        //   const isJpgOrPng =
        //     file.type === "image/jpeg" || file.type === "image/png";
        //   const isLt2M = file.size / 1024 / 1024 < 2;
        //   if (!isJpgOrPng) {
        //     setMsgModalData({
        //       title: "Image Upload",
        //       data: "You can only upload <b>JPG/PNG</b> file!",
        //       error: true,
        //     });
        //   }
        //   if (!isLt2M) {
        //     setMsgModalData({
        //       title: "Image Upload",
        //       data: "Image must smaller than <b>2MB</b>!",
        //       error: true,
        //     });
        //   }
        //   return isJpgOrPng && isLt2M;
        // },
        onChange(info) {
            const { status } = info.file;

            if (status === "done") {
                // message.success(`${info.file.name} file uploaded successfully.`);
                setFile(info.file.originFileObj);
                setUploadImageURL(URL.createObjectURL(info.file.originFileObj));
            } else if (status === "error") {
                // message.error(`${info.file.name} file upload failed.`);
            }
        },

        onDrop(e) {
            console.log("Dropped files", e);
        },
    };

    return (
        <>
            <Modal
                title={null}
                centered
                open={open}
                onOk={() => setOpen(false)}
                footer={null}
                className="add-new-location"
                closable={false}
                onCancel={() => {
                    setIsSubmit(false);
                    setEmailError(false);
                    setOpen(false);
                    setLocationName("");
                    setLocationAddress("");
                    setLocationCity("");
                    setLocationState("");
                    setLocationZipCode("");
                    setPhone("");
                    setEmail("");
                    setRoomName("");
                    setPrice("");
                    setMaxGuest("");
                    setRoomAmenities("");
                    setUploadImageURL(null);
                    setRoomSpaces("");
                    setRoomCeremonyTypes("");
                    setImages([]);
                }}
            >
                <div className="header">
                    <div className="header-left">
                        <BackArrow
                            onClick={() => {
                                setIsSubmit(false);
                                if (isfirstScreen) {
                                    setOpen(false);
                                } else {
                                    setIsFirstScreen(true);
                                    setIsSecondScreen(false);
                                }
                            }}
                            style={{
                                cursor: "pointer",
                            }}
                        />
                    </div>
                    <div className="header-center">Add New Location</div>
                    <div className="header-right">
                        <CrossIcon
                            onClick={() => {
                                setIsSubmit(false);
                                setLocationName("");
                                setLocationAddress("");
                                setLocationCity("");
                                setLocationState("");
                                setLocationZipCode("");
                                setPhone("");
                                setEmail("");
                                setRoomName("");
                                setPrice("");
                                setMaxGuest("");
                                setEmailError(false);
                                setImages([]);
                                setRoomAmenities("");
                                setRoomSpaces("");
                                setRoomCeremonyTypes("");
                                setEmailError(false);
                                setOpen(false);
                            }}
                            style={{
                                cursor: "pointer",
                            }}
                        />
                    </div>
                </div>

                {isfirstScreen && (
                    <Spin spinning={isSpin} delay={500}>
                        <div className="content-edit-company-2">
                            <div className="content-edit-company-input-wrapper">
                                <Input
                                    placeholder="Enter Location Name*"
                                    size="large"
                                    value={locationName}
                                    onChange={(e) => {
                                        setLocationName(e.target.value);
                                    }}
                                />
                                {isSubmit && !locationName && (
                                    <span style={{ color: "red" }}>
                                        Please enter location name
                                    </span>
                                )}
                            </div>
                            <div className="content-edit-company-input-wrapper">
                                <Input
                                    placeholder="Enter Address"
                                    size="large"
                                    value={locationAddress}
                                    required
                                    onChange={(e) => setLocationAddress(e.target.value)}
                                />
                            </div>

                            <Row gutter={[16, 16]}>
                                <Col md={8}>
                                    <div className="content-edit-company-input-wrapper">
                                        {/* <Select
                    defaultValue="City"
                    style={{
                      width: "100%",
                    }}
                    size="large" // onChange={handleChange}
                    options={[
                      {
                        value: "jack",
                        label: "Jack",
                      },
                      {
                        value: "city",
                        label: "City",
                      },
                      {
                        value: "Yiminghe",
                        label: "yiminghe",
                      },
                    ]}
                  /> */}
                                        <Input
                                            placeholder="Enter City*"
                                            size="large"
                                            value={locationCity}
                                            onChange={(e) => setLocationCity(e.target.value)}
                                        />
                                        {isSubmit && !locationCity && (
                                            <span style={{ color: "red" }}>Please enter city</span>
                                        )}
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div className="content-edit-company-input-wrapper">
                                        <StateAutoComplete
                                            placeholder="Enter State*"
                                            value={locationState}
                                            onChange={setLocationState}
                                        />
                                        {isSubmit && !locationState && (
                                            <span style={{ color: "red" }}>Please enter state</span>
                                        )}
                                    </div>
                                </Col>

                                <Col md={8}>
                                    <div className="content-edit-company-input-wrapper">

                                        <ZipCodeMask

                                            value={locationZipCode}
                                            onChange={(e) => setLocationZipCode(e.target.value)}

                                        >
                                            {() => {
                                                <Input

                                                    placeholder="Enter Zip Code*"
                                                    size="large"
                                                    value={locationZipCode}
                                                    onChange={(e) => setLocationZipCode(e.target.value)}
                                                />;
                                            }}
                                        </ZipCodeMask>

                                        {isSubmit &&
                                            (!locationZipCode || locationZipCode.length < 5) && (
                                                <span style={{ color: "red" }}>
                                                    Please enter a valid zip code
                                                </span>
                                            )}
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]}>
                                <Col md={12}>
                                    <div className="content-edit-company-input-wrapper">
                                        <PhoneMask
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                        >
                                            {() => {
                                                <Input
                                                    size="large"
                                                    placeholder="(123) 456-7890"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                />;
                                            }}
                                        </PhoneMask>
                                        {isSubmit && !phone && (
                                            <span style={{ color: "red" }}>
                                                Please enter phone number
                                            </span>
                                        )}
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="content-edit-company-input-wrapper">
                                        <Input
                                            placeholder="Email"
                                            size="large"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        {isEmailError && (
                                            <span style={{ color: "red" }}>
                                                Please enter valid email
                                            </span>
                                        )}
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]}>
                                {images.length ? images.map((file, i) => (
                                    <Col key={i} sm={12}>
                                        <div className="uploaded-image-wrapper">
                                            <img key={file.uid} src={file.thumbUrl || URL.createObjectURL(file.originFileObj)} alt={file.name} />
                                        </div>
                                    </Col>
                                )) : null}

                                <Col sm={12}>
                                    <div className="add-new-img" onClick={() => setImageModal(true)}>
                                        <span>Add Image</span>
                                        <button>Add</button>
                                    </div>
                                    {imageModal ? (
                                        <ImageUploader
                                            open={imageModal}
                                            setOpen={setImageModal}
                                            images={images}
                                            setImages={(images) => {
                                                setImages(images);
                                            }}
                                        />
                                    ) : null}
                                </Col>
                            </Row>
                        </div>
                    </Spin>
                )}

                <div className="footer">
                    <div className="footer-left"></div>
                    <div className="footer-center"></div>
                    <div className="footer-right">
                        <div className="next-btn">
                            <button
                                type="button"
                                className="btn"
                                onClick={() => {
                                    if (
                                        locationName &&
                                        locationCity &&
                                        locationState &&
                                        locationZipCode &&
                                        phone
                                    ) {
                                        setIsSubmit(false);
                                        if (isValidEmail(email)) {
                                            setIsAddNewRoom(true);
                                            setEmailError(false);
                                            setOpen(false);
                                        } else {
                                            setEmailError(true);
                                        }
                                    } else {
                                        setIsSubmit(true);
                                    }
                                }}
                            >
                                Add Rooms
                            </button>
                        </div>
                        <div className="back-btn">
                            <button
                                type="button"
                                className="btn"
                                onClick={() => {
                                    if (
                                        locationName &&
                                        locationCity &&
                                        locationState &&
                                        locationZipCode &&
                                        phone
                                    ) {
                                        setIsSubmit(false);
                                        if (email) {
                                            if (isValidEmail(email)) {
                                                submitLocation();
                                            } else {
                                                setEmailError(true);
                                            }
                                        } else {
                                            submitLocation();
                                        }
                                    } else {
                                        setIsSubmit(true);
                                    }
                                }}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <AddNewRoom
                open={isAddNewRoom}
                setOpen={setIsAddNewRoom}
                handleLocationModal={setOpen}
                roomName={roomName}
                setRoomName={setRoomName}
                price={price}
                setPrice={setPrice}
                maxGuest={maxGuest}
                setMaxGuest={setMaxGuest}
                roomAmenities={roomAmenities}
                setRoomAmenities={setRoomAmenities}
                file={file}
                roomSpaces={roomSpaces}
                locationAddress={locationAddress}
                setRoomSpaces={setRoomSpaces}
                roomCeremonyTypes={roomCeremonyTypes}
                setRoomCeremonyTypes={setRoomCeremonyTypes}
                locationName={locationName}
                setUploadImageURL={setUploadImageURL}
                setLocationName={setLocationName}
                setLocationAddress={setLocationAddress}
                locationCity={locationCity}
                setLocationCity={setLocationCity}
                locationState={locationState}
                setLocationState={setLocationState}
                getVenues={getVenues}
                locationZipCode={locationZipCode}
                setLocationZipCode={setLocationZipCode}
                phone={phone}
                setPhone={setPhone}
                email={email}
                setEmail={setEmail}
                loactionImages={images}
                setLoactionImages={setImages}
            />
            <AddImage
                open={isAddImage}
                setOpen={setIsAddImage}
                openLocationModal={open}
                setOpenLocationModal={setOpen}
            />
            <MessageModal
                open={isSuccessModalOpen}
                setOpen={setIsSuccessModelOpen}
                content="Your Location has been added successfully"
            />
        </>
    );
};
export default AddNewLocation;