import { useEffect } from "react";
import { Modal } from "antd";
import "./MessageModal.scss";
// import { ReactComponent as EditIcon } from "../../../../assets/CustomModal/editIcon.svg";
import { ReactComponent as CrossIcon } from "../../../../assets/Modals/CrossIcon.svg";
import done from "../../../../assets/check.png";

const MessageModal = ({ open, setOpen, content, error }) => {

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  }, [open]);

  return (
    <>
      <Modal
        title={null}
        centered
        open={open}
        onOk={() => setOpen(false)}
        footer={null}
        className="MessageModal"
        closable={true}
        onCancel={() => setOpen(false)}
      >
        <div className="header">
          <div className="header-left"></div>
          <div className="header-center">Success</div>
          <div className="header-right">
            {/* <CrossIcon
              onClick={() => {
                // setEditCompanyDetails(true);
                setOpen(false);
              }}
              style={{
                cursor: "pointer",
              }}
            /> */}
          </div>
        </div>

        <div className="content">
          <p
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          ></p>
        </div>
        <div className="footer">
          <div className="footer-left"></div>
          <div className="footer-center"></div>
          <div className="footer-right">
            <div className="close-btn">
              {/* <button
                type="button"
                className="btn"
                onClick={() => setOpen(false)}
              >
                Close
              </button> */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default MessageModal;
