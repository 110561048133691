import React, { useState, useCallback } from "react";
import { Card, Button, Row, Col } from "antd";
import "./paymentIntegration.scss";
import UpdateCompanyInformation from "./UpdateCompanyInformation";

const CompanyInformation = ({ company, accountID, callback }) => {

  const [editModal, setEditModal] = useState(false);
  const [companyData, setCompanyData] = useState(company);

  const formatAddress = (address) => {

    if (!address) return "";
    // check if any of the info is null then replace it with ''
    let newAddress = "";
    if (address.line1) newAddress += address.line1 + ", ";
    if (address.line2) newAddress += address.line2 + ", ";
    if (address.city) newAddress += address.city + ", ";
    if (address.state) newAddress += address.state + " ";
    if (address.postal_code) newAddress += address.postal_code;
    return newAddress;
  };

  return (
    <Card className="inner-card" style={{ marginTop: "20px" }}>
      <div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>Company Information</h2>
          <Button type="link" onClick={() => setEditModal(true)}>
            Change
          </Button>
        </div>

        <UpdateCompanyInformation
          open={editModal}
          setEditModal={setEditModal}
          companyData={companyData}
          setCompanyData={setCompanyData}
          accountID={accountID}
          callback={callback}
        />

        <Row style={{ marginTop: "15px" }} gutter={[15, 15]}>
          <Col span={24} md={12}>
            <p className="info-heading-stripe">Name</p>
            <p style={{ marginTop: "0px" }}>{company?.name}</p>
          </Col>
          <Col span={24} md={12}>
            <p className="info-heading-stripe">Phone</p>
            <p style={{ marginTop: "0px" }}>{company?.phone}</p>
          </Col>
          <Col span={24} md={12}>
            <p className="info-heading-stripe">City</p>
            <p style={{ marginTop: "0px" }}>{company?.address?.city}</p>
          </Col>
          <Col span={24} md={12}>
            <p className="info-heading-stripe">State</p>
            <p style={{ marginTop: "0px" }}>{company?.address?.state}</p>
          </Col>
          <Col span={24} md={12}>
            <p className="info-heading-stripe">Address</p>
            <p style={{ marginTop: "0px" }}>
              {formatAddress(company?.address)}
            </p>
          </Col>
        </Row>

      </div>
    </Card>
  );
};

export default CompanyInformation;
