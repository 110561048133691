import React, { useState, useEffect } from 'react';
import usePopup from '../../../../../common/MessageModal/usePopup';

// UI's
import { Card, Checkbox, Empty, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Loader from '../../../../../common/Loader';

import AddNewTask from './AddNewTask';

import { getTasks, updateTask } from '../../../../../services/events';

const Tasks = ({ eventId }) => {
    const popup = usePopup();
    const [loading, setLoading] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [addNewTask, setAddNewTask] = useState(false);

    // Tasks
    const fetchTasks = async (noLoading) => {
        setLoading(!noLoading);
        try {
            const res = await getTasks(eventId);
            setTasks(res?.data?.results);
        } catch (error) {
            popup.error('Oops!', error?.response?.data?.message, error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (eventId) {
            fetchTasks();
        }
    }, [eventId]);

    const updateATask = async (id, status) => {
        try {
            const res = await updateTask(id, {
                status: status ? "completed" : 'pending'
            });
            popup.success('Success!', res?.data?.message);
            fetchTasks(true);
        } catch (error) {
            popup.error('Oops!', error?.response?.data?.message);
        }
    }

    if (loading) {
        return (
            <Card>
                <Loader size={'default'} />
            </Card>
        )
    }

    return (
        <>
            <Card
                title="To-Do List"
                extra={<a onClick={() => setAddNewTask(true)} style={{ color: "#0B98D2" }}> <PlusOutlined /> Add Task</a>}

            >
                {tasks?.length ? <h4>Task List</h4> : null}
                {tasks?.length ? tasks.map((item, i) => (
                    <div key={i} className='task-todo'>
                        <Checkbox
                            checked={(item?.status === 'completed')}
                            style={{ marginTop: "10px", marginBottom: "10px", fontSize: "14px" }}
                            onChange={(e) => updateATask(item?.id, e.target.checked)}
                        >
                            {item.name}
                        </Checkbox>
                        <div className='check-box-task-checked'>
                            <p style={{ fontSize: "14px", color: item?.status === 'completed' ? "#1A9882" : '#F2884C' }}>{item.status}</p>
                        </div>
                    </div>
                )) : (
                    <Empty description={'No Tasks'} />
                )}
            </Card>
            <AddNewTask
                open={addNewTask}
                eventId={eventId}
                handelClose={() => setAddNewTask(false)}
                refetch={() => fetchTasks()}
            />
        </>
    )
}

export default Tasks