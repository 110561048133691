// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Profile-Container{
  background-color:#fff;
}
.red-asterisk::before {
  content: '*';
  color: red !important;
  margin-right: 4px; /* Add some spacing between the asterisk and the label */
}

`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/components/ProfileSetting.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;AACA;EACE,YAAY;EACZ,qBAAqB;EACrB,iBAAiB,EAAE,wDAAwD;AAC7E","sourcesContent":[".Profile-Container{\n  background-color:#fff;\n}\n.red-asterisk::before {\n  content: '*';\n  color: red !important;\n  margin-right: 4px; /* Add some spacing between the asterisk and the label */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
