import React, { useState, useEffect } from "react";
import { Typography, Card, Checkbox, Empty, Divider, Avatar } from "antd";
import { toast } from "react-hot-toast";
import { TagOutlined } from "@ant-design/icons";
import { getServices } from "../../../services/services";
import { serviceIcons } from "./utils";
// import { ReactComponent as PackagesIcon } from "../../../assets/icons/packages.svg";
import "../styles.scss";
import axios from "../../../network/axios";

const Services = ({
  selectedPackage,
  filteredServices,
  selected,
  setSelected,
  location,
}) => {
  const { Title } = Typography;
  const [services, setServices] = useState([]);

  const fetchServices = async () => {
    // try {
    const url = window.location.href;
    axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/api/company/addon/guest/?exclude_package=${selectedPackage}&generated_url=${url}`
      )
      .then((res) => {
        setServices(res?.data?.results);
      })
      .catch((err) => {
        const errorCode = err?.response?.status || 500; 
        if (errorCode === 500 || errorCode === 503) {
          window.location.href = '/server-error';
          console.log("Redirecting to server error page...");
        } else {
          console.log(err);
          toast.error(err?.response?.data?.message);
        }
      
      });
    // const res = await getServices(location);
    // } catch (error) {
    //   
    // }
  };

  useEffect(() => {
    fetchServices();
  }, [location, selectedPackage]);

  return (
    <Card className="booking-card">
      <Title level={4}>Additional Services</Title>
      <Divider />
      <div className="cards-wrapper services">
        <Checkbox.Group
          style={{ display: "flex", gap: 24, flexWrap: "nowrap" }}
          value={selected}
          onChange={(value) => {
            const find = services?.filter((item) => value.includes(item.id));
            setSelected(find);
          }}
        >
          {services?.length ? (
            services.map((item, i) => (
              <Card
                className={`service-card ${
                  selected.includes(item.id) && "seletced"
                }`}
                key={i}
                style={{ minWidth: 310, maxWidth: 310, flexGrow: 1 }}
              >
                <Checkbox value={item.id} checked={selected.includes(item.id)}>
                  {" "}
                  Select
                </Checkbox>
                <div className="wrapper">
                  {/* <Avatar icon={serviceIcons[i]?.icon} size={40} style={{ backgroundColor: serviceIcons[i]?.color }} /> */}
                  <Avatar
                    icon={<TagOutlined />}
                    size={40}
                    style={{ backgroundColor: "#F4ECFB", color: "#0499CB" }}
                  />
                  <div>
                    <Title
                      level={5}
                      style={{ fontWeight: 700, marginBottom: "0px" }}
                    >
                      {item.name}
                    </Title>
                    <p style={{ fontSize: "12px", marginTop: "5px" }}>
                      <span style={{ fontWeight: 700 }}>Price:&nbsp; $</span>{" "}
                      {item?.total_price}
                    </p>
                    <p style={{ fontSize: "12px", marginTop: "8px" }}>
                      {item?.description}
                    </p>
                  </div>
                </div>
              </Card>
            ))
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Services"
            />
          )}
        </Checkbox.Group>
      </div>
    </Card>
  );
};

export default Services;
