import React, { useState } from 'react'
import { Card, Typography, Progress } from 'antd'

const TaskSummary = () => {
    const { Text, Title } = Typography;
    const [statusFilter, setStatusFilter] = useState('all');

    return (
        <div>
            <Card className="task-summary">
                <Title level={3} style={{ fontSize: "20px" }} >
                    Task Summary
                </Title>
                <p style={{ color: "#777980" }}>Based on Status</p>

                <div className="monthly-summary">
                    <Title level={6} style={{ fontSize: "24px", marginBottom: "0px" }} >
                        10,000
                    </Title>
                    <p className="info-summary">
                        This Month
                    </p>
                </div>


                <div style={{ display: "flex" }}>
                    <Progress percent={100} strokeColor='#F86624' showInfo={false} style={{ width: "500px", margin: "0px 2px" }} />
                    <Progress percent={100} strokeColor='#22CAAD' showInfo={false} style={{ width: "250px", margin: "0px 2px" }} />
                    <Progress percent={100} strokeColor='#EB3D4D' showInfo={false} style={{ width: "150px", margin: "0px 2px" }} />
                    <Progress percent={100} strokeColor='#E0E2E7' showInfo={false} style={{ width: "100px", margin: "0px 2px" }} />
                </div>



                <div className="summary-box" style={{ marginBottom: "10px" }}>
                    <div className="head-info">
                        <div className="dot-info" style={{ background: "#F86624" }} />
                        <p className="info-summary">In Progress</p>
                    </div>

                    <div className="head-info">
                        <p className="figure-summary">5,000</p>
                        <p className="figure-summary-box" style={{ color: "#F86624", background: "#FFF0EA" }}>50%</p>
                    </div>

                </div>

                <div className="summary-box" style={{ marginBottom: "7px" }}>
                    <div className="head-info">
                        <div className="dot-info" style={{ background: "#22CAAD" }} />
                        <p className="info-summary">Finished</p>
                    </div>

                    <div className="head-info">
                        <p className="figure-summary">2,500</p>
                        <p className="figure-summary-box" style={{ color: "#1A9882", background: "#E9FAF7" }}>25%</p>
                    </div>

                </div>

                <div className="summary-box" style={{ marginBottom: "7px" }}>
                    <div className="head-info">
                        <div className="dot-info" style={{ background: "#EB3D4D" }} />
                        <p className="info-summary">Unfinished</p>
                    </div>
                    <div className="head-info">
                        <p className="figure-summary">1,500</p>
                        <p className="figure-summary-box" style={{ color: "#EB3D4D", background: "#FEECEE" }}>15%</p>
                    </div>
                </div>

                <div className="summary-box" style={{ marginBottom: "7px" }}>
                    <div className="head-info">
                        <div className="dot-info" style={{ background: "#E0E2E7" }} />
                        <p className="info-summary">Not Started</p>
                    </div>

                    <div className="head-info">
                        <p className="figure-summary">1000</p>
                        <p className="figure-summary-box" style={{ color: "#4A4C56", background: "#F0F1F3" }}>10%</p>
                    </div>

                </div>


            </Card>
        </div>
    )
}

export default TaskSummary