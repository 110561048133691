import { Modal } from "antd";
import "./CompanyDetails.scss";

import { ReactComponent as EditIcon } from "../../../../assets/CustomModal/editIcon.svg";

const CompanyDetails = ({ open, setOpen, setEditCompanyDetails, company }) => {
  return (
    <>
      <Modal
        title={null}
        centered
        open={open}
        onOk={() => setOpen(false)}
        footer={null}
        className="CompanyDetails"
        closable={false}
        onCancel={() => setOpen(false)}
      >
        <div className="header">
          <div className="header-left"></div>
          <div className="header-center">Company Details</div>
          <div className="header-right">
            <EditIcon
              onClick={() => {
                setEditCompanyDetails(true);
                setOpen(false);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </div>

        <div className="content">
          <div className="content-div">
            <div className="img">
              <img
                src={company?.logo ? company?.logo : null}
                alt=""
                srcset=""
              />
            </div>
            <div className="details">
              <div className="title">{company?.name}</div>
              <div className="sub-details">
                <p className="heading-sub">Phone</p>
                <p className="content-sub">{company?.phone}</p>
              </div>
              <div className="sub-details">
                <p className="heading-sub">Email</p>
                <p className="content-sub">{company?.email}</p>
              </div>
              <div className="sub-details">
                <p className="heading-sub">Address</p>
                <p className="content-sub">{company?.address_line1}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="footer-left"></div>
          <div className="footer-center"></div>
          <div className="footer-right">
            <div className="close-btn">
              <button
                type="button"
                className="btn"
                onClick={() => setOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default CompanyDetails;
