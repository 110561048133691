import React, { useState } from "react";
import { Typography, Card, Radio, Empty } from "antd";
import placeholderImage from "../../../assets/background.jpg";

// import { ReactComponent as PackagesIcon } from "../../../assets/icons/packages.svg";
import "../styles.scss";

const Rooms = ({ room, setRoom, rooms, validateFields }) => {
  const { Title } = Typography;

  return (
    <Card
      className="booking-card"
      style={{
        border: room === "" && validateFields === true ? "1px solid red" : null,
      }}
    >
      <Title level={4}>Select Available Rooms</Title>
      <div className="cards-wrapper">
        <Radio.Group
          style={{ display: "flex", gap: 24 }}
          onChange={(e) => {
            // const selected = venues?.find(item => item.id === e.target.value);
            setRoom(e.target.value);
          }}
          value={room}
        >
          {rooms?.length ? (
            rooms.map((item, i) => (
              <Card
                className={item.id === room && "seletced"}
                key={i}
                style={{ minWidth: 310, maxWidth: 310 }}
              >
                <div>
                  <img
                    src={
                      item?.images?.length
                        ? item?.images[0]?.image
                        : placeholderImage
                    }
                    class="img-fluid venue-image"
                    alt="room"
                  />
                </div>
                <p style={{ fontWeight: 700 }}>{item?.name}</p>
                <div className="packages-info">
                  <p className="packages-desc">Room Type</p>
                  <p
                    className="packages-desc value"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gat: 4,
                      maxWidth: "60%",
                    }}
                  >
                    {item?.ceremony_types?.length
                      ? item?.ceremony_types.map((item, i) => (
                          <p key={i}>{item}</p>
                        ))
                      : "-"}
                  </p>
                </div>
                {/* <div className="packages-info">
                                <p className="packages-desc">Price</p>
                                <p className="packages-desc value">${item?.base_price}</p>
                            </div> */}
                <div className="packages-info" style={{ marginBottom: 24 }}>
                  <p className="packages-desc">Maximum Capacity</p>
                  <p className="packages-desc value">{item?.max_guests}</p>
                </div>
                <div className="border-bottom" />
                <div
                  style={{
                    display: "flex",
                    gap: 8,
                    alignItems: "center",
                    paddingBlock: 6,
                  }}
                >
                  {/* <PackagesIcon /> <Text style={{ fontSize: 12 }}>0 Packages</Text> */}
                  <Radio
                    style={{ marginTop: "10px", marginLeft: "auto" }}
                    size="large"
                    value={item.id}
                  />
                </div>
              </Card>
            ))
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Rooms"
            />
          )}
        </Radio.Group>
      </div>
    </Card>
  );
};

export default Rooms;
