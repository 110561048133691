import React, { useState } from "react";
import "./paymentIntegration.scss";
import { Card, Col, Row, Input, DatePicker, Button } from "antd";
import { toast } from "react-hot-toast";
import { useStripe } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import MessageModal from "./Modals/MessageModal";
import ReactInputMask from "react-input-mask";
import myApi from "../../../network/axios";
import StateAutoComplete from "../../../common/StateAutoComplete";

const phoneRegExp = /^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/;
const zipCode = /^\d{5}-\d{4}|\d{5}|[A-Z]\d[A-Z] \d[A-Z]\d$/;

const IndividualStripe = ({ setGetAccount }) => {
  const stripe = useStripe();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [msgModalData, setMsgModalData] = useState(false);
  const [showError, setShowError] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [ssDigits, setSSDigits] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [fieldStatus, setFieldStatus] = useState(false);

  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const checkHandling = () => {
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      year === "" ||
      phoneNumber === "" ||
      ssDigits === "" ||
      streetAddress === "" ||
      city === "" ||
      state === "" ||
      postalCode === "" ||
      accountHolderName === "" ||
      accountNumber === "" ||
      routingNumber === ""
    ) {
      toast.error("Please fill in the required fields", {
        position: "top-right",
      });
      setFieldStatus(true);
    } else if (!phoneRegExp.test(phoneNumber)) {
      toast.error("Phone number is not valid", {
        position: "top-right",
      });
    } else if (!zipCode.test(postalCode)) {
      toast.error("Postal code is not valid", {
        position: "top-right",
      });
    } else if (ssDigits.length !== 9 || !/^[0-9]+$/.test(ssDigits)) {
      toast.error("SSN is not valid", {
        position: "top-right",
      });
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setFieldStatus(false);
    setShowError(false);
    setLoading(true);
    const result = await stripe.createToken("bank_account", {
      country: 'US',
      currency: 'usd',
      routing_number: routingNumber,
      account_number: accountNumber.padStart(12, "0"),
      account_holder_name: accountHolderName,
      account_holder_type: "individual",
    });

    if (result?.error) {
      setShowError([result?.error?.message]);
      toast.error([result?.error?.message]);
      setLoading(false);
    } else {
      await sendInformationToServer(result?.token?.id);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const sendInformationToServer = async (token) => {
    try {
      let payload = {
        email: email,
        external_account: token,
        business_type: "individual",
        individual: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phoneNumber,
          ssn: ssDigits,
          address: {
            city: city,
            line1: streetAddress,
            postal_code: postalCode,
            state: state,
          },
          dob: {
            day: day,
            month: month,
            year: year,
          },
          relationship: {
            owner: true,
            director: true,
            executive: true,
            percent_ownership: 100,
            representative: true,
            title: "COO",
          },
        },
      };
      await myApi
        .post(
          `${process.env.REACT_APP_BASE_API_URL}/api/company/integration/stripe/`,
          payload
        )
        .then((response) => {
          setMsgModalData({
            title: "Stripe Account",
            data: response.data.message,
            error: false,
          });
          navigate("/integration/StripeInfo");
          // setGetAccount("recalled");
        })
        .catch((error) => {
          let message = error?.response?.data?.message;
          let data = message?.map((el) => {
            if (typeof el === "object") {
              // { "email": [ "Enter a valid email address." ], "individual": { "email": [ "Enter a valid email address." ] } }
              return Object.values(el)[0][0];
            } else if (typeof el === "string") {
              return el;
            }
          });
          // setMsgModalData({
          //   title: "Stripe Account",
          //   data: data,
          //   error: true,
          // });
          toast.error(error?.response?.data?.message);
          setShowError(error?.response?.data?.message);
        });
    } catch (err) {
      console.log(err);
      // setShowError(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  // const handleDateChange = (date, dateString) => {
  //   const selectedDate = new Date(dateString);

  //   setDay(selectedDate.getDate());
  //   setMonth(selectedDate.getMonth() + 1);
  //   setYear(selectedDate.getFullYear());
  // };

  const handleDateChange = (date, dateString) => {
    const selectedDate = new Date(dateString);
    let parts = dateString.split('-');
    let year = parseInt(parts[0], 10);
    let month = parseInt(parts[1], 10);
    let day = parseInt(parts[2], 10);
    setDay(day);
    setMonth(month);
    setYear(year);
  };

  return (
    <div className="create-account-wrapper">
      <Card className="create-account-card">
        <h1>Create Stripe Account</h1>
        <p className="account-info">
          Add Information to create your individual stripe account.
        </p>

        <p className="line-breaker">Person Information</p>

        <div>
          <Row gutter={[16, 16]} style={{ margin: "10px" }}>
            <Col span={24} lg={12}>
              <p className="input-heading">First Name*</p>
              <Input
                style={{
                  borderColor:
                    firstName === "" && fieldStatus === true
                      ? "red"
                      : "#ced4da",
                }}
                placeholder="James"
                size="large"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Col>
            <Col span={24} lg={12}>
              <p className="input-heading">Last Name*</p>
              <Input
                style={{
                  borderColor:
                    lastName === "" && fieldStatus === true ? "red" : "#ced4da",
                }}
                placeholder="Eliot"
                size="large"
                onChange={(e) => setLastName(e.target.value)}
              />
            </Col>
            <Col span={24} lg={18}>
              <p className="input-heading">Email*</p>
              <Input
                style={{
                  borderColor:
                    email === "" && fieldStatus === true ? "red" : "#ced4da",
                }}
                placeholder="James@yahoo.com"
                size="large"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Col>
            <Col span={24} lg={6}>
              <p className="input-heading">Date of Birth*</p>
              <DatePicker
                style={{
                  borderColor:
                    year === "" && fieldStatus === true ? "red" : "#ced4da",
                }}
                size="large"
                onChange={(date, dateString) =>
                  handleDateChange(date, dateString)
                }
              />
            </Col>
            <Col span={24} lg={18}>
              <p className="input-heading">Phone Number*</p>
              <ReactInputMask
                mask="(999) 999-9999"
                maskChar=""
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              >
                {() => (
                  <Input
                    style={{
                      borderColor:
                        phoneNumber === "" && fieldStatus === true
                          ? "red"
                          : "#ced4da",
                    }}
                    name="phone"
                    maxLength={14}
                    placeholder="123-456-7890"
                    size="large"
                  />
                )}
              </ReactInputMask>
            </Col>
            <Col span={24} lg={6}>
              <p className="input-heading">SSN/EIN*</p>
              <Input
                style={{
                  borderColor:
                    ssDigits === "" && fieldStatus === true ? "red" : "#ced4da",
                }}
                value={ssDigits}
                maxLength={9}
                placeholder="Social Security Number"
                size="large"
                name="ssnLast"
                onChange={(e) => {
                  const input = e.target.value.replace(/\D/g, "");
                  setSSDigits(input);
                }}
              />
            </Col>
            <Col span={24}>
              <p className="input-heading">Street Address*</p>
              <Input
                style={{
                  borderColor:
                    streetAddress === "" && fieldStatus === true
                      ? "red"
                      : "#ced4da",
                }}
                placeholder="Complete street address"
                size="large"
                onChange={(e) => setStreetAddress(e.target.value)}
              />
            </Col>
            <Col span={24} lg={10}>
              <p className="input-heading">City*</p>
              <Input
                style={{
                  borderColor:
                    city === "" && fieldStatus === true ? "red" : "#ced4da",
                }}
                placeholder="City name"
                onChange={(e) => setCity(e.target.value)}
                size="large"
              />
            </Col>
            <Col span={24} lg={8}>
              <p className="input-heading">State*</p>
              <StateAutoComplete
                value={state}
                onChange={(value) => setState(value)}
                style={{
                  width: '100%',
                  borderColor: state === "" && fieldStatus === true ? "red" : "#ced4da",
                }}
              />
            </Col>
            <Col span={24} lg={6}>
              <p className="input-heading">Postal Code*</p>
              <Input
                style={{
                  borderColor:
                    postalCode === "" && fieldStatus === true
                      ? "red"
                      : "#ced4da",
                }}
                placeholder="Postal code"
                onChange={(e) =>
                  setPostalCode(
                    e.target.value.replace(/\D/g, "").substring(0, 5)
                  )
                }
                onKeyDown={(e) => {
                  if (
                    e.target.value.length >= 5 &&
                    e.key !== "Backspace" &&
                    e.key !== "Delete"
                  ) {
                    e.preventDefault();
                  }
                }}
                size="large"
              />
            </Col>
          </Row>


          {/* updating stripe' */}

          <p className="line-breaker">Bank Information</p>
          <Row gutter={[16, 16]} style={{ margin: "10px" }}>
            <Col span={24}>
              <p className="input-heading">Account Holder Name*</p>
              <Input
                style={{
                  borderColor:
                    accountHolderName === "" && fieldStatus === true
                      ? "red"
                      : "#ced4da",
                }}
                placeholder="Complete account holder name"
                onChange={(e) => setAccountHolderName(e.target.value)}
                size="large"
              />
            </Col>
            <Col span={24}>
              <p className="input-heading">Account Number*</p>
              <Input
                style={{
                  borderColor:
                    accountNumber === "" && fieldStatus === true
                      ? "red"
                      : "#ced4da",
                }}
                maxLength={12}
                value={accountNumber}
                placeholder="Account Number"
                onChange={(e) => {
                  const input = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                  setAccountNumber(input.substring(0, 12)); // Limit to 12 digits
                }}
                size="large"
                type="number"
              />
            </Col>
            <Col span={24}>
              <p className="input-heading">Routing Number*</p>
              <Input
                style={{
                  borderColor:
                    routingNumber === "" && fieldStatus === true
                      ? "red"
                      : "#ced4da",
                }}
                value={routingNumber}
                maxLength={9}
                placeholder="Routing Number"
                onChange={(e) => {
                  const input = e.target.value.replace(/\D/g, "");
                  setRoutingNumber(input.substring(0, 9));
                }}
                size="large"
              />
            </Col>

            <div style={{ marginLeft: "auto" }}>
              <Button disabled={loading} onClick={handleCancel} size="large">
                Back
              </Button>
              &nbsp;&nbsp;
              <Button
                size="large"
                type="primary"
                variant="contained"
                onClick={checkHandling}
                loading={loading}
              >
                Submit
              </Button>
              &nbsp;&nbsp;
            </div>
          </Row>
        </div>
      </Card>
      <MessageModal
        open={msgModalData}
        title={msgModalData?.title}
        content={msgModalData?.data}
        error={msgModalData?.error}
        onClose={setMsgModalData}
      />
    </div>
  );
};

export default IndividualStripe;
