import React from 'react'
import { Button, Modal, Row, Col, Input } from 'antd';


const ChangeInfo = ({ editModal, setEditModal, account }) => {
    return (
        <div>
            <Modal open={editModal} onOk={setEditModal} onCancel={() => setEditModal(false)}>
                <h2>Company Information</h2>
                <h3>Update Your Company Information</h3>
                <Row gutter={[8, 8]} style={{ marginBottom: "10px" }}>
                    <Col span={24} >
                        <p>Company Name</p>
                        <Input type='text' placeholder="Complete Company Name"
                            size="large"
                            value={account?.individual?.first_name + " " + account?.individual?.last_name} />
                    </Col>
                </Row>
                <Row gutter={[8, 8]} style={{ marginBottom: "10px" }}>
                    <Col span={24} >
                        <p>Phone Number</p>
                        <Input placeholder="Phone Number"
                            size="large"
                            value={account?.individual?.phone}
                        />
                    </Col>
                </Row>
                <Row gutter={[8, 8]} style={{ marginBottom: "10px" }}>
                    <Col span={24} >
                        <p>Street Address</p>
                        <Input placeholder="Complete address of company"
                            size="large"
                        />
                    </Col>
                </Row>
                <Row gutter={[8, 8]} style={{ marginBottom: "10px" }}>
                    <Col span={9} >
                        <p>City</p>
                        <Input placeholder="City Name"
                            size="large" value={account?.individual?.address?.city} />
                    </Col>
                    <Col span={8} >
                        <p>State</p>
                        <Input placeholder="State Name"
                            size="large" />
                    </Col>
                    <Col span={7} >
                        <p>Postal Code</p>
                        <Input placeholder="Postal Code"
                            size="large" />
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default ChangeInfo