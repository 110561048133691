import React from 'react';
import { Row, Col, Card, Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import HeroImg from './Assets/Login.png';
import { Link } from 'react-router-dom';
import logo2 from './Assets/logo2.png';
import './OnBoardAdmin.scss'; 

const OnBoardAdmin = () => {
  return (
    <div className='onboard-Container'>
      <div className="onboard-card-column">
        <div className="onboard-card">
            <img className='planspace-logo' src={logo2} alt="" />
            <p className='welcome-heading'>Welcome to Planspace</p>
            <spane className='welcome-para '>We are a one-stop shop for Venue Owners to book events and easily manage daily tasks.</spane>
          <Link to="/businesstype">
            <button className='started-btn'>
              Let's get Started   <ArrowRightOutlined style={{ fontSize: '16px', marginTop: '3px' }} />
            </button>
          </Link>
        </div>
      </div>
      <div className='onbaord-image-column'>
        <img src={HeroImg} alt="Hero image" className="onboard-image" />
      </div>
    </div>
  );
}

export default OnBoardAdmin;
