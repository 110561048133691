import { Modal } from "antd";
import "./AddImage.scss";
import { ReactComponent as CrossIcon } from "../../../../assets/Modals/CrossIcon.svg";
import { ReactComponent as UploadIcon } from "../../../../assets/Modals/uploadIcon.svg";
import { ReactComponent as BackIcon } from "../../../../assets/Modals/backArrow.svg";
import { ReactComponent as PictureIcon } from "../../../../assets/Modals/pictureIcon.svg";

const AddImage = ({
  open,
  setOpen,
  openLocationModal,
  setOpenLocationModal,
}) => {
  return (
    <>
      <Modal
        title={null}
        centered
        open={open}
        onOk={() => setOpen(false)}
        footer={null}
        className="add-image"
        closable={false}
        onCancel={() => setOpen(false)}
      >
        <div className="header">
          <div className="header-left">
            <BackIcon
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setOpen(false);
                setOpenLocationModal(true);
              }}
            />
          </div>
          <div className="header-center">Add Images</div>
          <div className="header-right">
            <CrossIcon
              onClick={() => {
                // setEditCompanyDetails(true);
                setOpen(false);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </div>

        <div className="content">
          <div className="content-div">Select an option</div>
          <div className="content-btn-wrapper">
            <button className="btn-left">
              <UploadIcon />
              &nbsp; Upload
            </button>
            <button className="btn-right">
              <PictureIcon /> &nbsp; Media Library
            </button>
          </div>
        </div>
        {/* <div className="footer">
          <div className="footer-left"></div>
          <div className="footer-center"></div>
          <div className="footer-right">
            <div className="close-btn">
              <button
                type="button"
                className="btn"
                onClick={() => setOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div> */}
      </Modal>
    </>
  );
};
export default AddImage;
