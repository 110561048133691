import React, { useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Card, Typography, Button, Carousel, Dropdown, Divider, Switch } from 'antd';
import { MoreOutlined, DeleteFilled } from '@ant-design/icons';
import usePopup from '../../../common/MessageModal/usePopup';

import { ReactComponent as NextArrow } from '../../../assets/icons/arrow_circle_left.svg';
import { ReactComponent as PriceIcon } from '../../../assets/icons/dollar.svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/arrow-small-right.svg';
import bgImage from '../../../assets/background.jpg';

import { deleteService, updateService } from '../../../services/services';

import '../styles/packages.scss';
import ServicesForm from './forms/ServicesForm';

const ServiceCard = ({ data, fetchServices, room_id, venue_id, status_switch }) => {
    const popup = usePopup();
    const current_venue = useSelector((state) => state.venues.selectedVenue?.id);
    const navigate = useNavigate();
    const { Title, Text } = Typography;
    const sliderRef = useRef(null);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const deleteThisService = async () => {
        try {
            const res = await deleteService(data?.id);
            popup.success("Success!", res?.data?.message);
            fetchServices();
        } catch (error) {
            const errorCode = error?.response?.status || 500; 
            if (errorCode === 500 || errorCode === 503) {
              window.location.href = '/server-error';
              console.log("Redirecting to server error page...");
            } else {
              popup.error("Oops...", error?.response?.data?.message);     
            }
          }
          
    }

    const items = [
        {
            label: <a onClick={() => setEdit(true)}><Text>Edit</Text></a>,
            key: 'edit',
        },
        {
            label: <a onClick={() => popup.confirm('Delete ' + data?.name, "Are you sure? This action is permanent and cannot be undone.", deleteThisService)}><Text type='danger'>Delete</Text></a>,
            key: 'delete',
        },
    ];

    const updateServiceStatus = async (payload) => {
        setLoading(true);
        try {
            await updateService(data?.id, payload);
            popup.success("Success!", 'Service status changed successfully.');
            fetchServices();
        } catch (error) {
            if (typeof error?.response?.data?.message === 'object') {
                popup.error("Oops...", error?.response?.data?.message[0]?.name[0]);
            } else {
                popup.error("Oops...", error?.response?.data?.message);
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <Card className='service-card'>
            <div className="slider">
                <Carousel ref={sliderRef}>
                    {data?.images?.length ? data?.images?.map((item, i) => (
                        <img key={i} className='card-img' src={item?.image} alt="cover" />
                    )) : (
                        <img className='card-img' src={bgImage} alt="cover" />
                    )}
                </Carousel>
                {data?.images?.length > 1 ? (
                    <div className="controls">
                        <div className="btn back" onClick={() => sliderRef?.current?.prev()}>
                            <NextArrow />
                        </div>
                        <div className="btn" onClick={() => sliderRef?.current?.next()}>
                            <NextArrow />
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="header">
                <div>
                    <Title level={5}>{data?.name}</Title>
                    <Text type="secondary">
                        {data?.description}
                    </Text>
                </div>
                <Dropdown menu={{ items }} placement="bottomRight" trigger={['click']}>
                    <Button type="text" shape="circle" icon={<MoreOutlined />} />
                </Dropdown>
            </div>
            {data?.room ? (
                <div className="content">
                    <div className="item">
                        <label>Room</label>
                        <div className={`value ${!room_id && 'link'}`} onClick={() => {
                            if (!room_id) {
                                navigate(`/company/venues/${current_venue}/rooms/${data?.room?.id}`);
                            }
                        }}>

                            {data?.room?.name || '-'} {!room_id ? (<LinkIcon />) : null}
                        </div>
                    </div>
                </div>
            ) : null}
            <div style={{ flexGrow: 1 }} />
            <Divider style={{ borderBlockStartStyle: 'dashed', marginBlock: 16, }} />
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <div className="price">
                    <PriceIcon />
                    <Text>{data?.total_price || '0'}</Text>
                </div>
                {status_switch ? (
                    <Switch
                        style={{ marginLeft: 'auto' }}
                        checked={data?.is_active}
                        loading={loading}
                        onChange={(checked) => {
                            updateServiceStatus({ is_active: checked });
                        }}
                    />
                ) : null}
            </div>

            <ServicesForm
                fetchServices={fetchServices}
                open={edit}
                room_id={room_id}
                venue_id={venue_id}
                handelClose={() => setEdit(false)}
                update_service={{ ...data, type: 'edit' }}
            />
        </Card>
    )
}

export default ServiceCard