import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import logo2 from '../Assets/logo2.png';
import { Link } from 'react-router-dom';
import './BizName.css';

const BusinessName = () => {

    return (
        <Row className="businessName-type-container">
            <Col span={3}>
                <div className="businessName-logo-container">
                    <img src={logo2} alt="Logo" className="businessName-logo" />
                </div>
            </Col>
            <Col span={21} className='businessName-Form-container'>
                <div className='businessName-setup-title'>
                <p>Base Setup</p>
                </div>
                <div className="businessName-setup-content">
                    <div className="businessName-card-container">
                        <div className="businessName-input-group-active">
                            <p className='businessName-active-setup-name'>Business Name</p>
                            <input
                                style={{
                                    backgroundColor: "#F8F8F8",
                                    color: "#B6B6B6",
                                    fontFamily: "Inter, sans-serif",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 100,
                                    lineHeight: "15px",
                                }}
                                type="text"
                                id="businessname"
                                placeholder="Your business name"
                                name="businessname"
                            />

                        </div>
                        <Link to="/businessaddress">
                            <button className="businessName-setup-next-button">
                                Save & go next
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="businessName-setup-input-container">
                    <div className="businessName-setup-address-group">
                        <p className='businessName-setup-address'>Business Address</p>
                        <input
                            type="text"
                            id="businessaddress"
                            placeholder="Your business address"
                            name="businessname"
                            disabled
                        />
                    </div>
                    <div className="businessName-setup-contact-group">
                        <p className='businessName-setup-contact'>Add Business Contact</p>
                        <input
                            type="text"
                            id="businesscontact"
                            placeholder="Your business contact"
                            name="businesscontact"
                            disabled
                        />
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default BusinessName;
