import React, { useState, useEffect } from 'react';
import myApi from '../../../../network/axios';
import { Button, Modal, Form, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';
import { toast } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';

const EditAddTeamModal = ({ editModal, openEditModal, getUsers, user }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // convert a normal phone number into US formate 
  const formatPhoneNumber = (phoneNumber) => {
    const digits = phoneNumber.replace(/\D/g, ''); 
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
  };

 useEffect(() => {
  const initialPhoneValue = user?.mobile ? formatPhoneNumber(user.mobile) : ''; // Assuming 'mobile' is the property containing the phone numbe
    form.setFieldsValue({
      name: getFullName(user),
      email: user?.username,
      phone: initialPhoneValue,
    });
  }, [openEditModal, user]);
  const updateUserData = async (values) => {
    try {
      setLoading(true);
      // If name has space, split it and add first, middle and last name
      if (values.name) {
        let name = values.name.replace(/\s{2,}/g, ' ').trim();
        name = name.split(' ');
        let firstName;
        let middleName;
        let lastName;
        if (name.length === 3) {
          firstName = name[0];
          middleName = name[1];
          lastName = name[2];
        } else if (name.length === 2) {
          firstName = name[0];
          middleName = '';
          lastName = name[1];
        } else if (name.length === 1) {
          firstName = name[0];
          middleName = '';
          lastName = '';
        }
      }
      const userData = {
        first_name: firstName || '',
        middle_name: middleName || '',
        last_name: lastName || '',
        username: values.email,
        mobile: (values.phone || '').replaceAll('-', ''),
        location: values.location,
      };

      const result = await myApi.put(`api/auth/user/${user?.id}/`, userData);
      toast.success(result.data.message);
      resetForm();
      getUsers();
      editModal();
    } catch (error) {
      handleUpdateError(error);
    } finally {
      setLoading(false);
    }
  };

  const validatePhoneNumber = (rule, value) => {
    const phoneNumberRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    if (value && !phoneNumberRegex.test(value)) {
      return Promise.reject('Invalid phone number');
    } else {
      return Promise.resolve();
    }
  };

  const handleUpdateError = (error) => {
    if (error && error.response && error.response.data) {
      const errorMessage = error.response.data.message;
      if (Array.isArray(errorMessage) && errorMessage.length === 1 && errorMessage[0].username) {
        const usernameError = errorMessage[0].username[0];
        if (usernameError.includes('already exists')) {
          toast.error('Email is already exists. Please choose a different email.');
        } else {
          toast.error(usernameError);
        }
      } else {
        toast.error('An error occurred while updating the user.');
      }
    } else {
      console.error('Invalid error format:', error);
    }
  };
  
// getting full name 
  const getFullName = (user) => [user?.first_name, user?.middle_name, user?.last_name].filter(Boolean).join(' ');

  const resetForm = () => {
    form.resetFields();
  };

  return (
    <div>
      <Modal
        style={{ marginTop: '50px' }}
        title={(
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            Edit Team Members
            <CloseOutlined onClick={() => { resetForm(); editModal(); }} />
          </div>
        )}
        visible={openEditModal}
        closable={false}
        footer={[
          <Button key="cancel" onClick={() => { resetForm(); editModal(); }} disabled={loading}>
            Cancel
          </Button>,
          <Button key="add" type="primary" onClick={() => form.submit()} loading={loading}>
            Update
          </Button>,
        ]}
      >
        <Form
          form={form}
          onFinish={updateUserData}
          layout="vertical"
        >
          <Form.Item
            label="Name*"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter a name',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email*"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter an email',
              },
              {
                type: 'email',
                message: 'Invalid email format',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Phone*"
            name="phone"
            rules={[
                {
                  required: true,
                  message: 'Please enter a phone number',
                },
                {
                  validator: validatePhoneNumber,
                },
              ]}
          >
            <InputMask
              mask="(999) 999-9999"
              maskChar=""
              type="tel"
              placeholder="(123) 345-6789"
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditAddTeamModal;
