import { Modal, Upload } from "antd";
import React, { useState, useEffect } from "react";
import "./AddNewService.scss";

import { ReactComponent as CrossIcon } from "../../../../assets/Modals/CrossIcon.svg";
import { ReactComponent as BackArrow } from "../../../../assets/Modals/backArrow.svg";
import { Input, Select, Row, Col, Spin } from "antd";
// import AddImage from "../AddImage";
import axios from "axios";
import { useSelector } from "react-redux";
import MessageModal from "../MessageModal";
import ImageUploader from "../../../../components/ImageUploader";

const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}

const AddNewService = ({ open, setOpen, getServices, selectedRoom }) => {
  const location = useSelector((state) => state.venues.selectedVenue);
  const [isfirstScreen, setIsFirstScreen] = useState(true);
  const [isSecondScreen, setIsSecondScreen] = useState(false);
  const [uploadImageURL, setUploadImageURL] = useState("");
  const [file, setFile] = useState(null);
  const [serviceName, setServiceName] = useState("");
  const [isSpin, setIsSpin] = useState(false);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [room, setRoom] = useState(null);
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [isNameError, setIsNameError] = useState(false);
  const [isDescriptionError, setIsDescriptionError] = useState(false);
  const [isPriceError, setIsPriceError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [rooms, setRooms] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [images, setImages] = useState([]);

  const submitData = async (active) => {
    setIsSpin(true);
    const formData = new FormData();
    formData.append("name", serviceName);
    formData.append("description", description);
    formData.append("price", price);
    if (file) {
      formData.append("image", file);
    }
    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        formData.append('images', images[i].originFileObj);
      }
    }
    if (room) {
      formData.append("room", room?.value ? room?.value : room);
    }
    if (active) {
      formData.append("is_active", active);
    } else {
      formData.append("is_active", false);
    }
    formData.append("location", location?.id);

    await axios
      .post(
        `${process.env.REACT_APP_BASE_API_URL}/api/company/addon/`,
        formData,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("access_token")}`,
          },
        }
      )
      .then((res) => {
        setFile(null);
        setUploadImageURL(null);
        setServiceName("");
        setDescription("");
        setPrice("");
        setIsSpin(false);
        // setRooms([]);
        setRoom("");
        setOpen(false);
        getServices();
        setImages([]);
      })
      .catch((eror) => {
        setErrorMessage(eror?.response?.data?.message[0]?.name[0]);
        setIsSpin(false);
        setIsErrorModal(true);

        // setErrorMessage()
      });
  };

  const getRooms = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/api/company/room/?location=${location?.id}&page_size=50`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("access_token")}`,
          },
        }
      )
      .then((res) => {
        setRooms(res.data.results);
      });
  };

  useEffect(() => {
    getRooms();
  }, []);

  const dummyRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    customRequest: dummyRequest,
    onRemove: (file) => {
      // setFile(null);
      // setUploadImageURL("");
    },

    // beforeUpload(file, fileList) {
    //   const isJpgOrPng =
    //     file.type === "image/jpeg" || file.type === "image/png";
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isJpgOrPng) {
    //     setMsgModalData({
    //       title: "Image Upload",
    //       data: "You can only upload <b>JPG/PNG</b> file!",
    //       error: true,
    //     });
    //   }
    //   if (!isLt2M) {
    //     setMsgModalData({
    //       title: "Image Upload",
    //       data: "Image must smaller than <b>2MB</b>!",
    //       error: true,
    //     });
    //   }
    //   return isJpgOrPng && isLt2M;
    // },
    onChange(info) {
      const { status } = info.file;

      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
        setFile(info.file.originFileObj);
        setUploadImageURL(URL.createObjectURL(info.file.originFileObj));
      } else if (status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },

    onDrop(e) {
      console.log("Dropped files", e);
    },
  };

  useEffect(() => {
    if (selectedRoom) {
      const selected = { label: selectedRoom?.name, value: selectedRoom?.id }
      setRoom(selected);
    }
  }, [selectedRoom, rooms]);

  return (
    <>
      <Modal
        title={null}
        centered
        open={open}
        onOk={() => setOpen(false)}
        footer={null}
        className="add-new-service"
        closable={false}
        onCancel={() => setOpen(false)}
      >
        <div className="header">
          <div className="header-left">
            <BackArrow
              onClick={() => {
                if (isfirstScreen) {
                  setOpen(false);
                } else {
                  setIsFirstScreen(true);
                  setIsSecondScreen(false);
                }
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          <div className="header-center">Add New Service</div>
          <div className="header-right">
            <CrossIcon
              onClick={() => {
                setOpen(false);
                setFile(null);
                setUploadImageURL(null);
                setServiceName("");
                setDescription("");
                setPrice("");
                setIsSpin(false);
                setIsNameError(false);
                setIsPriceError(false);
                setIsDescriptionError(false);
                setImages([]);
                setRooms([]);
                setRoom("");
                setOpen(false);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </div>

        {isfirstScreen && (
          <Spin spinning={isSpin} delay={400}>
            <div className="content-edit-company-2">
              <div className="content-edit-company-input-wrapper">
                <Input
                  placeholder="Service Name*"
                  size="large"
                  value={serviceName}
                  onChange={(e) => setServiceName(e.target.value)}
                />
                {isNameError && (
                  <span style={{ color: "red" }}>Service Name is required</span>
                )}
              </div>
              <div className="content-edit-company-input-wrapper">
                <Input
                  placeholder="Description*"
                  size="large"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                {isDescriptionError && (
                  <span style={{ color: "red" }}>
                    Service description is required
                  </span>
                )}
              </div>

              <Row gutter={[16, 16]}>
                <Col md={24}>
                  <div className="content-edit-company-input-wrapper">
                    <Input
                      placeholder="Price*"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      size="large"
                    />
                    {isPriceError && (
                      <span style={{ color: "red" }}>
                        Service price is required
                      </span>
                    )}
                  </div>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col md={24}>
                  <div className="content-edit-company-input-wrapper">
                    <Select
                      placeholder="Select Room to associate with"
                      style={{
                        width: "100%",
                      }}
                      onChange={(value) => {
                        setRoom(value);
                      }}
                      disabled={selectedRoom?.id}
                      value={room}
                      size="large" // onChange={handleChange}
                      options={rooms.map((room) => {
                        return {
                          value: room.id,
                          label: room.name,
                        };
                      })}
                    />
                  </div>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                {images.length ? images.map(file => (
                  <Col sm={12}>
                    <div className="uploaded-image-wrapper">
                      <img key={file.uid} src={file.thumbUrl || URL.createObjectURL(file.originFileObj)} alt={file.name} />
                    </div>
                  </Col>
                )) : null}
                <Col sm={12}>
                  <div className="add-new-img" onClick={() => setImageModal(true)}>
                    <span>Add Image</span>
                    <button>Add</button>
                  </div>
                  {imageModal ? (
                    <ImageUploader
                      open={imageModal}
                      setOpen={setImageModal}
                      images={images}
                      setImages={(images) => {
                        setImages(images);
                      }}
                    />
                  ) : null}
                </Col>
              </Row>

              {/* <Row gutter={[16, 16]}>
              <Col sm={12}>
                <div className="add-new-img">
                  <span>Add Image</span>
                  <button
                    onClick={() => {
                      setIsAddImage(true);
                      setOpen(false);
                    }}
                  >
                    Add
                  </button>
                </div>
              </Col>
              <Col md={12}></Col>
            </Row> */}
            </div>
          </Spin>
        )}

        <div className="footer">
          <div className="footer-left"></div>
          <div className="footer-center"></div>
          <div className="footer-right">
            <div className="back-btn">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  if (serviceName && description && price) {
                    setIsActive(false);
                    setIsNameError(false);
                    setIsDescriptionError(false);
                    setIsPriceError(false);
                    submitData();
                  } else {
                    if (!serviceName) {
                      setIsNameError(true);
                    }
                    if (!description) {
                      setIsDescriptionError(true);
                    }
                    if (!price) {
                      setIsPriceError(true);
                    }
                  }
                }}
              >
                Save
              </button>
            </div>
            <div className="next-btn">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  if (serviceName && description && price) {
                    submitData(true);
                    setIsNameError(false);
                    setIsDescriptionError(false);
                    setIsPriceError(false);
                  } else {
                    if (!serviceName) {
                      setIsNameError(true);
                    }
                    if (!description) {
                      setIsDescriptionError(true);
                    }
                    if (!price) {
                      setIsPriceError(true);
                    }
                  }
                }}
              >
                Save & Activate
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <MessageModal
        open={isErrorModal}
        setOpen={setIsErrorModal}
        content={errorMessage}
      />

      {/* <AddImage open={isAddImage} setOpen={setIsAddImage} /> */}
    </>
  );
};
export default AddNewService;
