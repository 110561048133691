import React, { useState, useEffect } from 'react';
import { Card, Typography, Button, Empty, Row, Col, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import Loader from '../../../common/Loader';
import PackageCard from '../comps/PackageCard';
import PackageForm from '../comps/forms/PackageForm';
import { getPackages } from '../../../services/packages';
import usePopup from '../../../common/MessageModal/usePopup';

import '../styles/venue-details.scss';

const Packages = ({ room_id, venue_id, room }) => {
    const popup = usePopup();
    const { Title } = Typography;
    const [loading, setLoading] = useState(false);
    const [packages, setPackages] = useState([]);
    const [addPackage, setAddPackage] = useState(false);

    const fetchPackages = async () => {
        setLoading(true);
        try {
            const res = await getPackages({ location: venue_id, room: room_id });
            setPackages(res?.data?.results);
        } catch (error) {
            const errorCode = error?.response?.status || 500; 
            if (errorCode === 500 || errorCode === 503) {
              window.location.href = '/server-error';
              console.log("Redirecting to server error page...");
            } else {
              popup.error("Oops...", error?.response?.data?.message);     
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (room_id) {
            fetchPackages();
        }
    }, [room_id, venue_id]);

    return (
        <Card className="sub-section-card">
            <div className='section-header'>
                <Title level={4}>Packages</Title>
                <Button
                    size='small'
                    type="text" icon={<PlusOutlined />}
                    className='add-room'
                    disabled={!room_id}
                    onClick={() => setAddPackage(true)}
                >
                    Add Package
                </Button>
            </div>
            {!packages?.length && loading ? (
                <Loader minHeight={200} />
            ) : (
                <Spin spinning={loading}>
                    <Row gutter={[24, 24]} style={{ minHeight: 200 }}>
                        {packages?.length ? packages?.map((item, i) => (
                            <Col key={i} span={24} md={12} xl={8} xxl={6}>
                                <PackageCard
                                    data={item}
                                    fetchPackages={fetchPackages}
                                    room_id={room_id}
                                    venue_id={venue_id}
                                    status_switch={true}
                                />
                            </Col>
                        )) : (
                            <Col span={24}>
                                <Empty description="No Packages" />
                            </Col>
                        )}
                    </Row>
                </Spin>
            )}
            <PackageForm
                fetchPackages={fetchPackages}
                open={addPackage}
                room={room}
                venue_id={venue_id}
                handelClose={() => setAddPackage(false)}
            />
        </Card>
    )
}

export default Packages
