import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { Modal, Space, Button, Typography, Card, Input, Radio, Checkbox, Spin, Empty } from 'antd';
import { PlusOutlined, EnvironmentOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import usePopup from '../../../../common/MessageModal/usePopup';
import PackageForm from '../../../CompanySettings/comps/forms/PackageForm';
import ServicesForm from '../../../CompanySettings/comps/forms/ServicesForm';
import { getPackages } from '../../../../services/packages';
import { getServices } from '../../../../services/services';
import { updateEvent } from '../../../../services/events';
import "./styles.scss";


const ModifyPackage = ({ isModalOpen, showModal, currentEvent, setCurrentEvent, setPackageCount }) => {
    const popup = usePopup();
    const { Title } = Typography;
    const location = useSelector((state) => state.venues.selectedVenue);
    const [event, setEvent] = useState({});
    const [packages, setPackages] = useState([]);
    const [services, setServices] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState('');
    const [selectedServices, setSelectedServices] = useState([]);
    const [customPrice, setCustomPrice] = useState('');
    const [numOfGuests, setNumOfGuests] = useState('');
    const [updateLoading, setUpdateLoading] = useState(false);
    const [addNewPackageModal, setAddNewPackageModal] = useState(false);
    const [addNewServiceModal, setAddNewServiceModal] = useState(false);
    const [numOfGuestsDisable, setNumOfGuestsDisable] = useState(false);
    const [pkgLoading, setPkgLoading] = useState(false);
    const [pkgSearch, setPkgSearch] = useState('');
    const [servLoading, setServLoading] = useState(false);
    const [servSearch, setServSearch] = useState('');

    useEffect(() => {
        if (currentEvent) {
            setEvent(currentEvent);
        }
    }, [currentEvent]);

    useEffect(() => {
        if (event?.chosen_package) {
            setSelectedPackage(event?.chosen_package?.id);
        }
        if (event) {
            const services = event?.addons?.length ? event?.addons?.map(item => item?.id) : [];
            setSelectedServices(services);
        }
        if (event?.total_price) {
            setCustomPrice(event?.total_price);
        }
        if (event?.num_of_guests || event?.chosen_package?.room?.max_guests) {
            const roomGuests = event?.chosen_package?.room?.max_guests
            setNumOfGuests(roomGuests ? roomGuests : event?.num_of_guests);
        }
    }, [event, isModalOpen]);

    const fetchPackages = async (value) => {
        // setSelectedPackage('');
        setPkgLoading(true);
        try {
            const res = await getPackages({ location: location?.id, status: 'activated', search: value ? value : '' });
            setPackages(res?.data?.results);
            setPackageCount(res?.data?.count);
        } catch (err) {
            console.error("Error: ", err);
        } finally {
            setPkgLoading(false);
        }
    }
    const fetchServices = async (value) => {
        // setSelectedServices([]);
        setServLoading(true);
        try {
            const res = await getServices({ location: location?.id, is_active: true, search: value ? value : '' });
            setServices(res?.data?.results);
        } catch (err) {
            console.error("Error: ", err);
        } finally {
            setServLoading(false);
        }
    }

    useEffect(() => {
        if (location?.id) {
            fetchPackages();
        }
        if (isModalOpen && location?.id) {
            fetchServices();
        }
    }, [isModalOpen, location]);

    useEffect(() => {
        if (isModalOpen && location?.id)
            fetchPackages(pkgSearch);
    }, [pkgSearch]);

    useEffect(() => {
        if (isModalOpen && location?.id) {
            fetchServices(servSearch);
        }
    }, [servSearch]);

    const checkPackageServices = (id) => {
        const selectedPackageObj = packages?.find(item => item.id === selectedPackage);
        const packageServices = selectedPackageObj?.addons?.map(item => (item.id));

        return !packageServices?.includes(id);
    }

    const totalPrice = () => {
        const totalOfPackage = packages?.find(item => item.id === selectedPackage)?.total_price || 0
        const totalOfServices = services?.filter(item => selectedServices?.includes(item.id))?.reduce((a, b) => (a + b?.total_price), 0) || 0
        return Number((totalOfPackage + totalOfServices) || 0)
    }

    const updateCurrentEvent = async () => {
        setUpdateLoading(true);
        try {
            const res = await updateEvent(event.id, {
                chosen_package: selectedPackage,
                addons: selectedServices,
                total_price: customPrice ? customPrice : null,
                num_of_guests: numOfGuests ? numOfGuests : null,
            });
            popup.success("Success!", res?.data?.message);
            setEvent(res?.data?.data);
            setCurrentEvent(res?.data?.data);
            showModal(false);
        } catch (error) {
            popup.error("Oops!", error?.response?.data?.message);
        } finally {
            setUpdateLoading(false);
        }
    }

    return (
        <div>
            <Modal
                title="Modify Package"
                centered
                open={isModalOpen}
                okText={'Update'}
                onCancel={() => {
                    showModal(false);
                }}
                onOk={updateCurrentEvent}
                confirmLoading={updateLoading}
            >
                <Space className='navigationBar'>
                    <Title level={5}>Package</Title>
                    <Button className='modal-main-btn' size={'medium'} onClick={() => setAddNewPackageModal(true)}>
                        <PlusOutlined /> &nbsp;New Package
                    </Button>
                </Space>

                <Card style={{ marginTop: "10px" }}>
                    <Input
                        className='text-area'
                        placeholder="Search Packages"
                        prefix={<SearchOutlined />}
                        onChange={(e) => {
                            const searchValue = e.target.value;
                            if (searchValue?.length > 2) {
                                setPkgSearch(searchValue);
                            } else {
                                setPkgSearch('');
                            }
                        }}
                    />
                    <Spin spinning={pkgLoading}>
                        <div className="outer-wrapper">
                            <Radio.Group
                                onChange={(e) => {
                                    setSelectedPackage(e.target.value);
                                    if (e.target.value) {
                                        const selectedPackageObj = packages?.find(item => item.id === e.target.value);
                                        if (selectedPackageObj.num_of_guests) {
                                            setNumOfGuestsDisable(true);
                                        } else {
                                            setNumOfGuestsDisable(false);
                                        }
                                        const roomGuests = event?.chosen_package?.room?.max_guests
                                        setNumOfGuests(roomGuests ? roomGuests : selectedPackageObj?.num_of_guests);
                                    }
                                    setCustomPrice('');
                                }}
                                value={selectedPackage}
                                style={{ width: '100%', height: 'auto' }}
                            >
                                {packages?.length ? packages?.map((pkg, i) => (
                                    <div className='item' key={i}>
                                        <div className='search-packages' style={{ marginBottom: "10px" }}>
                                            <Radio value={pkg.id}>
                                                <div style={{ marginLeft: "5px" }}>
                                                    <h4>{pkg?.name}</h4>
                                                    <UserOutlined style={{ color: "#0499CB" }} /> {pkg?.num_of_guests || 0} &nbsp;&nbsp;
                                                    <EnvironmentOutlined style={{ color: "#0499CB" }} />&nbsp;{pkg?.location}
                                                </div>
                                                <h4 style={{ marginLeft: "auto" }}>${pkg?.total_price || 0}</h4>
                                            </Radio>
                                        </div>

                                        {packages?.length !== i + 1 ? (
                                            <hr style={{ background: "#F0F1F3", height: "1px", border: "0" }} />
                                        ) : null}
                                    </div>
                                )) : (
                                    <Empty description={'No Packages'} />
                                )}
                            </Radio.Group>
                        </div>
                    </Spin>
                </Card>

                <Space className='navigationBar' style={{ marginTop: "10px" }}>
                    <Title level={5}>Services</Title>
                    <Button className='modal-main-btn' size={'medium'} onClick={() => setAddNewServiceModal(true)}>
                        <PlusOutlined /> &nbsp;New Services
                    </Button>
                </Space>

                <Card style={{ marginTop: "10px" }}>
                    <Input
                        className='text-area'
                        placeholder="Search Services"
                        prefix={<SearchOutlined />}
                        onChange={(e) => {
                            const searchValue = e.target.value;
                            if (searchValue?.length > 2) {
                                setServSearch(searchValue);
                            } else {
                                setServSearch('');
                            }
                        }}
                    />
                    <Spin spinning={servLoading}>
                        <div className='outer-wrapper services'>
                            <Checkbox.Group
                                onChange={(val) => {
                                    setSelectedServices(val);
                                    setCustomPrice('');
                                }}
                                value={selectedServices}
                                style={{ width: '100%', display: 'block' }}
                            >
                                {services?.length ? services?.map((serv, i) => checkPackageServices(serv.id) ? (
                                    <div className='item' key={i}>
                                        <div className='search-packages' style={{ marginBottom: "10px" }}>
                                            <Checkbox value={serv.id}>
                                                <div style={{ marginLeft: "5px" }}>
                                                    <h4>{serv?.name}</h4>
                                                    <p>{serv?.description}</p>
                                                </div>
                                            </Checkbox>
                                        </div>
                                        {services?.length !== i + 1 ? (
                                            <hr style={{ background: "#F0F1F3", height: "1px", border: "0" }} />
                                        ) : null}
                                    </div>
                                ) : null) : (
                                    <Empty description={'No Services'} />
                                )}
                            </Checkbox.Group>
                        </div>
                    </Spin>
                </Card>

                <p style={{ marginTop: "10px", }}>Number of Guests</p>
                <Input
                    className='text-area'
                    placeholder="No of guests"
                    type='number'
                    value={numOfGuests}
                    disabled={numOfGuestsDisable}
                    onChange={(e) => setNumOfGuests(e.target.value)}
                />

                <p style={{ marginTop: "10px", }}>Pricing</p>
                <Input
                    value={customPrice}
                    className='text-area'
                    placeholder="Coustom Pricing"
                    type='number'
                    onChange={(e) => setCustomPrice(e.target.value)}
                />

                <div className='card card-outer' style={{ width: "100%" }}>
                    <Card className='card-inner'>
                        <div className='pricing-event'>
                            <p style={{ color: "#0499CB" }}>Total Package Cost</p>
                            <strong>$ {Boolean(customPrice) && customPrice?.length ? customPrice : totalPrice()}</strong>
                        </div>
                    </Card>
                </div>
            </Modal>
            {addNewPackageModal ? (
                <PackageForm
                    open={addNewPackageModal}
                    fetchPackages={fetchPackages}
                    handelClose={() => setAddNewPackageModal(false)}
                    autoSelectPackage={setSelectedPackage}
                />
            ) : null}
            {addNewServiceModal ? (
                <ServicesForm
                    open={addNewServiceModal}
                    fetchServices={fetchServices}
                    handelClose={() => setAddNewServiceModal(false)}
                    autoSelectAddOn={(val) => {
                        if (selectedServices?.length) {
                            setSelectedServices(prev => ([...prev, val]));
                        } else {
                            setSelectedServices([val]);
                        }
                    }}
                />
            ) : null}

        </div>
    )
}

export default ModifyPackage