import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Typography, Button, Row, Col, Form } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { getIntigrationDetails } from "../../services/integrations";
import Loader from "../../common/Loader";
import logo from "../../assets/Header/planspace-logo.svg";
import "./styles.scss";

import SelectType from "./comps/SelectType";
import Rooms from "./comps/Rooms";
import DateTime from "./comps/DateTime";
import Packages from "./comps/Packages";
import Services from "./comps/Services";
import TransactionDetail from "./comps/TransactionDetail";
import PaymentForm from "./comps/PaymentForm";
import ContactInfoForm from "./comps/ContactInfoForm";
import { createEndUserEvent } from "../../services/integrations";

import usePopup from "../../common/MessageModal/usePopup";
import { duration } from "moment";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const EndUserFlow = () => {
  const popup = usePopup();
  const [form] = Form.useForm();
  const { Title, Text } = Typography;
  const [loading, setLoading] = useState(false);
  const [paymentContact, setPaymentContact] = useState(false);
  const [details, setDetails] = useState([]);
  const [eventType, setEventType] = useState();
  const [room, setRoom] = useState("");
  const [selectedPackage, setSelectedPackage] = useState("");
  const [services, setServices] = useState([]);
  const [paymentInfo, setPaymentInfo] = useState({
    is_partial_allowed: false,
    payment_method_id: "",
  });
  const [createPaymentId, setCreatePaymentId] = useState(0);
  const [dateDuration, setDateDuration] = useState({
    duration_minutes: "",
    event_date: "",
  });
  const [startTime, setStartTime] = useState("");
  const [validateFields, setValidateFields] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");

  const [submitLoading, setSubmitLoading] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState(null);
  const [paymentDue, setPaymentDue] = React.useState(
    selectedPackage?.total_price
  );

  const [filteredServices, setFilteredServices] = useState([]);

  const [additionalServices, setAdditionalServices] = React.useState([]);
  const [paymentDue1, setPaymentDue1] = React.useState(null);
  const [paymentDue2, setPaymentDue2] = React.useState(null);

  // console.log(filteredServices)

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = async () => {
    setLoading(true);
    try {
      const url = window.location.href;
      const res = await getIntigrationDetails(url);
      setDetails(res.data?.data);
      setPaymentTerms(res.data?.data?.payment_terms);
    } catch (error) {
      const errorCode = error?.response?.status || 500; 
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = '/server-error';
        console.log("Redirecting to server error page...");
      } else {
        toast.error(error?.response?.data?.message, {
          position: "top-right",  
        }); 
      }
    } finally {
      setLoading(false);
    }
  };

  const moveToNextScreen = () => {
    if (room === "") {
      toast.error("Please choose a room ");
      setValidateFields(true);
    } else if (
      !dateDuration?.duration_minutes ||
      !dateDuration?.event_date ||
      startTime === ""
    ) {
      toast.error("Please select event date & duration!");
      setValidateFields(true);
    } else {
      setPaymentContact(true);
      setValidateFields(false);
    }
  };

  const handelProceed = () => {
    if (cardHolderName === "" || paymentInfo.payment_method_id === "") {
      toast.error("Please enter complete card details!");
    } else if (paymentInfo.payment_method_id) {
      form.submit();
    } else {
      setCreatePaymentId((prev) => prev + 1);
    }
  };

  const handleSubmit = async (values) => {
    if (!paymentInfo.payment_method_id) {
      handelProceed();
      return;
    }
    const payload = {
      start_time: startTime,
      location: details?.location?.id,
      // event_type: "93c71a34-d453-4afc-902a-62c12f911540",
      chosen_package: selectedPackage,
      ...paymentInfo,
      rooms: room ? [room] : [],
      addons: services?.length ? services.map((item) => item.id) : [],
      ...dateDuration,
      num_of_installments: details?.payment_terms?.length,
      terms: details?.terms[0],
      contact: values,
      status: "booked",
      is_partial_allowed: paymentInfo?.is_partial_allowed,
    };
    setSubmitLoading(true);
    try {
      const res = await createEndUserEvent(payload);
      // toast.success(res?.data?.message);
      popup.success(res?.data?.message);
      form.resetFields();
      setEventType("");
      setRoom("");
      setSelectedPackage("");
      setPaymentContact(false);
      setServices([]);
      setPaymentInfo({
        is_partial_allowed: false,
        payment_method_id: "",
      });
      setCreatePaymentId(0);
      setSubmitLoading(false);
      setDateDuration({
        duration_minutes: "",
        event_date: "",
      });
    } catch (error) {
      toast.error(JSON.stringify(error?.response?.data?.message));
    } finally {
      setSubmitLoading(false);
    }
  };

  const handlePartialPayment = () => {
    if (paymentInfo?.is_partial_allowed) {
      if (paymentTerms.length === 2) {
        setPaymentDue(
          (selectedPackage?.total_price +
            (additionalServices
              ? additionalServices.reduce((a, b) => a + b?.servicePrice, 0)
              : 0)) /
            2
        );
        setPaymentDue1({
          amount: Math.floor(paymentDue / 2),
          date: new Date(
            dateDuration?.event_date?.setDate(
              dateDuration?.event_date?.$d?.getDate() -
                (paymentTerms[0]?.payment_day === 0
                  ? paymentTerms[1]?.payment_day
                  : paymentTerms[0]?.payment_day)
            )
          ),
        });
      } else if (paymentTerms.length === 3) {
        let arr = paymentTerms.filter((item) => item?.payment_day !== 0);

        if (arr.length === 2) {
          setPaymentDue(
            (selectedPackage?.total_price +
              (additionalServices
                ? additionalServices.reduce((a, b) => a + b?.servicePrice, 0)
                : 0)) /
              3
          );

          setPaymentDue2({
            amount1: Math.floor(paymentDue / 3),
            date1: new Date(
              dateDuration?.event_date?.setDate(
                dateDuration?.event_date?.$d?.getDate() -
                  (arr[0]?.payment_day > arr[1]?.payment_day
                    ? arr[0]?.payment_day
                    : arr[1]?.payment_day)
              )
            ),
            amount2: Math.floor(paymentDue / 3),
            date2: new Date(
              dateDuration?.event_date?.setDate(
                dateDuration?.event_date?.$d?.getDate() -
                  (arr[1]?.payment_day < arr[0]?.payment_day
                    ? arr[1]?.payment_day
                    : arr[0]?.payment_day)
              )
            ),
          });
        } else {
          setPaymentDue(
            (selectedPackage?.total_price +
              (additionalServices
                ? additionalServices.reduce((a, b) => a + b?.servicePrice, 0)
                : 0)) /
              3
          );

          setPaymentDue2({
            amount1: Math.floor(paymentDue / 3),
            date1: new Date(
              dateDuration?.event_date?.setDate(
                dateDuration?.event_date?.$d?.getDate() - 0
              )
            ),
            amount2: Math.floor(paymentDue / 3),
            date2: new Date(
              dateDuration?.event_date?.setDate(
                dateDuration?.event_date?.$d?.getDate() - arr[0].payment_day
              )
            ),
          });
        }
      }
    } else {
      setPaymentDue(
        selectedPackage?.total_price +
          (additionalServices
            ? additionalServices.reduce((a, b) => a + b?.servicePrice, 0)
            : 0)
      );
    }
  };

  if (loading) {
    return <Loader minHeight={600} />;
  }

  return (
    <div className="booking-wrapper">
      <div className="header">
        <img src={logo} alt="main logo" />
      </div>
      {!paymentContact ? (
        <>
          <div className="page-title">
            <Title level={3}>Please Fill in the required information</Title>
            <Text>Please fill in the details to confirm your order</Text>
          </div>
          <div className="content-wrapper">
            {/* <SelectType eventType={eventType} setEventType={setEventType} /> */}
            <Rooms
              setRoom={setRoom}
              validateFields={validateFields}
              room={room}
              rooms={details?.location?.rooms}
            />
            <DateTime
              validateFields={validateFields}
              startTime={startTime}
              setStartTime={setStartTime}
              dateDuration={dateDuration}
              setDateDuration={setDateDuration}
              venue_id={details?.location?.id}
            />
            <Packages
              handelChange={setSelectedPackage}
              value={selectedPackage}
              packages={details?.packages}
              setFilteredServices={setFilteredServices}
            />
            <Services
            selectedPackage={selectedPackage}
              filteredServices={filteredServices}
              selected={services?.length ? services.map((item) => item.id) : []}
              setSelected={setServices}
              location={details?.location?.id}
            />
            <Button
              type="primary"
              size="large"
              style={{ marginBlock: 16, maxWidth: 320, marginInline: "auto" }}
              block
              onClick={moveToNextScreen}
            >
              Next
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="page-title">
            <Title level={3}>Kindly share a few more details</Title>
            <Text>Please fill in the details to confirm your order</Text>
          </div>

          <Form
            className={"contact-form"}
            form={form}
            name="validateOnly"
            layout="vertical"
            autoComplete="off"
            onFinish={handleSubmit}
          >
            <div className="content-wrapper pay-contact-wrapper">
              <Button
                icon={<ArrowLeftOutlined />}
                type="text"
                style={{
                  width: "fit-content",
                  marginBottom: "-16px",
                  marginLeft: "auto",
                  fontWeight: "bold",
                }}
                onClick={() => setPaymentContact(false)}
              >
                Back
              </Button>
              <Row gutter={[24, 24]}>
                <Col span={16}>
                  <Elements stripe={stripePromise}>
                    <PaymentForm
                      setCardHolderName={setCardHolderName}
                      cardHolderName={cardHolderName}
                      paymentTerms={paymentTerms}
                      details={details}
                      createPaymentId={createPaymentId}
                      setPaymentInfo={setPaymentInfo}
                      paymentInfo={paymentInfo}
                      form={form}
                      setSubmitLoading={setSubmitLoading}
                      submitLoading={submitLoading}
                      handlePartialPayment={handlePartialPayment}
                    />
                  </Elements>
                  <ContactInfoForm />
                </Col>
                <Col span={8}>
                  <TransactionDetail
                    paymentInfo={paymentInfo}
                    paymentTerms={paymentTerms}
                    dateDuration={dateDuration}
                    services={services}
                    selectedPackage={
                      details?.packages?.find(
                        (item) => item.id === selectedPackage
                      ) || null
                    }
                    details={details}
                    sebmitButton={(checked) => (
                      <Button
                        loading={submitLoading}
                        disabled={!checked}
                        type="primary"
                        onClick={handelProceed}
                        block
                        size="large"
                      >
                        Proceed
                      </Button>
                    )}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        </>
      )}
    </div>
  );
};

export default EndUserFlow;
