import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-hot-toast";
import moment from "moment";
// UI's
import { Typography, Card, Input, Checkbox, Spin, Empty } from "antd";

import { SearchOutlined } from "@ant-design/icons";

import templateImage from "../../../assets/background.jpg";
import { getPackages } from "../../../services/packages";

// CSS
import "../integrationStyles.scss";
import useItems from "antd/es/menu/hooks/useItems";

const Packages = ({ error, setErrors, venue, selected, setSelected }) => {

  const { Title, Text } = Typography;
  const scrollerRef = useRef(null);
  const [packages, setPackages] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredPackages = packages.filter((item) =>
  item.name.toLowerCase().includes(searchTerm.toLowerCase())
);  

  const fetchPackages = async () => {
    if (!venue?.id) return;
    setLoading(true);
    try {
      const res = await getPackages({
        page: page,
        location: venue?.id,
      });
      if (page > 1) {
        setPackages((prev) => [...prev, ...res.data.results]);
      } else {
        setPackages(res.data.results);
      }
      setHasMore(res.data.next);
    }   catch (error) {
      const errorCode = error?.response?.status || 500; 
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = '/server-error';
        console.log("Redirecting to server error page...");
      } else {
          toast.error(error.response.data.message);   
      }
  } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (venue?.id) {
      fetchPackages();
    }
  }, [venue]);

  useEffect(() => {
    if (page > 1) {
      fetchPackages();
    }
  }, [page]);

  useEffect(() => {
    const scrollContainer = scrollerRef.current;

    const handleScroll = () => {
      const totalWidth =
        scrollContainer.scrollWidth - scrollContainer.clientWidth;
      const currentScrollPosition = scrollContainer.scrollLeft;
      if (currentScrollPosition === totalWidth && hasMore) {
        setPage((prev) => prev + 1);
      }
    };

    scrollContainer.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [scrollerRef, hasMore]);

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const hoursText = hours > 0 ? `${hours} hour${hours !== 1 ? "s" : ""}` : "";
    const minutesText =
      remainingMinutes > 0
        ? `${remainingMinutes} minute${remainingMinutes !== 1 ? "s" : ""}`
        : "";

    if (hoursText && minutesText) {
      return `${hoursText} and ${minutesText}`;
    } else {
      return hoursText || minutesText || "-";
    }
  };

  return (
    <Card style={{ marginTop: "20px", borderColor: error && "#ff4d4f" }}>
      <Title level={4}>Select Packages</Title>
      <Input
        style={{ borderColor: "#E0E2E7" }}
        size="large"
        placeholder="Search..."
        prefix={<SearchOutlined />}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Spin spinning={loading}>
        <div ref={scrollerRef} className="cards-wrapper packages">
          <Checkbox.Group
            style={{ display: "flex", gap: 24, flexWrap: "nowrap" }}
            // options={packages?.map(item => (item.id))}
            value={selected}
            onChange={(value) => {
              setSelected(value);
              if (value?.length) {
                setErrors((prev) => ({ ...prev, packages: "" }));
              }
            }}
          >
            {filteredPackages?.length ? (
              filteredPackages.map((item, i) => (
                <Card
                  className={selected.includes(item.id) && "seletced"}
                  key={i}
                  style={{ minWidth: 310, maxWidth: 310, flexGrow: 1 }}
                >
                  <div>
                    <img
                      src={
                        item?.images?.length
                          ? item?.images[0]?.image
                          : templateImage
                      }
                      class="img-fluid venue-image"
                      alt=""
                    />
                  </div>
                  <p style={{ fontWeight: 700 }}>{item.name}</p>
                  <p style={{ fontSize: "12px" }}>{item?.description || "-"}</p>

                  {/* <div className="packages-info">
                                    <p className="packages-desc">Time Availability</p>
                                    <p className="packages-desc value">{item?.days ? item?.days?.days?.join(', ') : '-'}</p>
                                </div> */}
                  <div className="packages-info">
                    <p className="packages-desc">Package Duration</p>
                    {/* <p className="packages-desc value">{item?.duration_minutes ? moment.duration(item?.duration_minutes, 'minutes').humanize() : '-'}</p> */}
                    <p className="packages-desc value">
                      {formatDuration(item?.duration_minutes)}
                    </p>
                  </div>

                  <div className="packages-info">
                    <p className="packages-desc">Number of Guests</p>
                    <p className="packages-desc value">
                      {item?.num_of_guests || 0}
                    </p>
                  </div>
                  {item.addons.length > 1 ? (
                    <p
                      className="packages-desc"
                      style={{ marginTop: "10px", fontWeight: 800 }}
                    >
                      Service(s) Name:
                    </p>
                  ) : null}
                  {item?.addons.map((service) => {
                    return (
                      <>
                        <div
                          className="packages-info"
                          style={{ marginBottom: "-7px" }}
                        >
                          <p className="packages-desc">● {service?.name}</p>
                          <p className="packages-desc value">
                            ${service.price}
                          </p>
                        </div>
                      </>
                    );
                  })}

                  <div className="border-bottom" />

                  <div className="packages-info">
                    <p>
                      <span style={{ color: "#0499CB" }}>$</span>{" "}
                      {item?.total_price || 0}
                    </p>
                    <Checkbox
                      value={item.id}
                      checked={selected.includes(item.id)}
                    />
                  </div>
                </Card>
              ))
            ) : !loading ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Packages"
              />
            ) : null}
          </Checkbox.Group>
        </div>
      </Spin>

      {error ? (
        <Text style={{ marginTop: 24, display: "block" }} type="danger">
          {error}
        </Text>
      ) : null}
    </Card>
  );
};

export default Packages;