import React, { useState, useEffect } from 'react';
// UI's
import { Space, Card, Timeline, Typography, Spin } from 'antd';
import moment from 'moment';
// import { ArrowRightOutlined } from '@ant-design/icons';
import { getUpdates } from '../../../../../services/events';
// import img from '../../SourceFiles/img.png';
import timeline from '../../SourceFiles/timeline.svg';

import emptyScreen from '../../../../../assets/no-updates.svg';
import '../Contract/styles.scss';

const Updates = ({ event }) => {
    const { Title } = Typography;
    const [loading, setLoading] = useState(false);
    const [updates, setUpdates] = useState([]);

    const fetchUpdates = async () => {
        setLoading(true);
        try {
            const res = await getUpdates(event?.id);
            setUpdates(res?.data?.results);
        } catch (error) {
            console.error('Error', error.response);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchUpdates();
    }, []);



    if (loading) {
        return (
            <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
                <Spin size={'large'} />
            </Card>
        )
    }

    return (
        updates.length ? (
            <div>
                <Card
                >
                    <Space>
                        <Title level={4}>Activity</Title>
                    </Space>

                    <Timeline
                        style={{ margin: "20px" }}
                        items={updates.map(item => ({
                            dot: <img src={timeline} />,
                            children:
                                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                    <div>
                                        <h4>Payment</h4>
                                        <p> {item?.description}</p>
                                    </div>
                                    <p>{moment(item?.modified_date).format('DD MMM YYYY')}</p>
                                </div>
                        }))}
                    // items={[
                    //     {
                    //         dot: <img src={timeline} />,
                    //         children: <>
                    //             <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    //                 <div>
                    //                     <h4>New Task Added</h4>
                    //                     <p><span className='info-timeline'>Jay Peter</span> created a new task.</p>
                    //                 </div>
                    //                 <p>24 May 2023</p>
                    //             </div>

                    //         </>,
                    //     },
                    //     {
                    //         dot: <img src={timeline} />,
                    //         children: <>
                    //             <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    //                 <div>
                    //                     <h4>Task Status Changed</h4>
                    //                     <p><span className='info-timeline'>Jay Peter</span> move <span className='info-timeline'>Moodboard</span> task to done</p>
                    //                     <div style={{ display: "flex" }}>
                    //                         <div className='check-box-task'>
                    //                             <p style={{ fontSize: "14px", color: "#F2884C" }}>In Progress</p>
                    //                         </div>
                    //                         <ArrowRightOutlined style={{ marginLeft: "10px", marginRight: "10px" }} />
                    //                         <div className='check-box-task-checked'>
                    //                             <p style={{ fontSize: "14px", color: "#1A9882" }}>Done</p>
                    //                         </div>
                    //                     </div>

                    //                 </div>
                    //                 <p>24 May 2023</p>
                    //             </div>

                    //         </>,
                    //     },
                    //     {
                    //         dot: <img src={timeline} />,
                    //         children: <>
                    //             <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    //                 <div>
                    //                     <h4>New Comment</h4>
                    //                     <p><span className='info-timeline'>Jay Peter</span>Commented "Oke, Veronica Thank you ~~" in <span className='info-timeline'>WireFrame</span> Task</p>
                    //                 </div>
                    //                 <p>24 May 2023</p>
                    //             </div>

                    //         </>,
                    //     },
                    //     {
                    //         dot: <img src={timeline} />,
                    //         children: <>
                    //             <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    //                 <div>
                    //                     <h4>New Task Added</h4>
                    //                     <p><span className='info-timeline'>Jay Peter</span> uploaded new attachment</p>
                    //                     <img src={img} className='attachment-images-timeline' alt="" />
                    //                     <img src={img} className='attachment-images-timeline' alt="" />
                    //                 </div>
                    //                 <p>24 May 2023</p>
                    //             </div>

                    //         </>,
                    //     },
                    // ]}
                    />
                </Card>
            </div>
        ) : (
            <Card>
                <div className="no-data">
                    <img src={emptyScreen} alt="no contracts" />
                    <h3>Oops! Its Empty Here...</h3>
                    <p>You will get the updates based on your activity.</p>
                </div>
            </Card>
        )
    )
}

export default Updates
