import { createSlice } from "@reduxjs/toolkit";

const companySlice = createSlice({
    name: "company",
    initialState: {
        company: null,
        loading: false
    },
    reducers: {
        setCompany: (state, action) => {
            state.company = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
});

// this is for dispatch
export const { setCompany, setLoading } = companySlice.actions;

// this is for configureStore
export default companySlice.reducer;
