// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  background-color: #fafbfc;
  border-bottom: 2px solid #eaedf4;
}
.header-wrapper .right-items {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header-wrapper .right-items .drop-down {
  margin: 0 10px;
}
.header-wrapper .right-items .drop-down .header-dropdown .ant-select-selector {
  background-color: #eaedf4;
  border-color: transparent;
}
.header-wrapper .right-items .drop-down .header-dropdown .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-weight: 800;
  color: #5c7099;
}
.header-wrapper .right-items .drop-down .header-dropdown .ant-select-selector .ant-select-selection-item svg {
  margin-right: 10px;
}
.header-wrapper .right-items .notifications {
  margin: 0 10px;
}
.header-wrapper .right-items .notifications .ant-badge .ant-badge-count {
  background-color: #e62e2e;
  color: white;
  transform: translate(17%, -35%);
}
.header-wrapper .right-items .buttons {
  margin: 0 10px;
}
.header-wrapper .right-items .buttons .btn-event {
  background-color: #0499cb;
  color: white;
  font-family: "Inter", sans-serif;
  padding: 1px 19px;
  margin: 0 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/components/Header.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,gCAAA;AAAF;AACE;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;AACJ;AAAI;EACE,cAAA;AAEN;AAAQ;EACE,yBAAA;EACA,yBAAA;AAEV;AADU;EACE,aAAA;EACA,mBAAA;EACA,gCAAA;EACA,gBAAA;EACA,cAAA;AAGZ;AAFY;EACE,kBAAA;AAId;AAEI;EACE,cAAA;AAAN;AAEQ;EACE,yBAAA;EACA,YAAA;EACA,+BAAA;AAAV;AAII;EACE,cAAA;AAFN;AAGM;EACE,yBAAA;EACA,YAAA;EACA,gCAAA;EACA,iBAAA;EACA,aAAA;AADR","sourcesContent":[".header-wrapper {\n  // width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 20px 20px;\n  background-color: #fafbfc;\n  border-bottom: 2px solid #eaedf4;\n  .right-items {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    .drop-down {\n      margin: 0 10px;\n      .header-dropdown {\n        .ant-select-selector {\n          background-color: #eaedf4;\n          border-color: transparent;\n          .ant-select-selection-item {\n            display: flex;\n            align-items: center;\n            font-family: \"Inter\", sans-serif;\n            font-weight: 800;\n            color: #5c7099;\n            svg {\n              margin-right: 10px;\n            }\n          }\n        }\n      }\n    }\n    .notifications {\n      margin: 0 10px;\n      .ant-badge {\n        .ant-badge-count {\n          background-color: #e62e2e;\n          color: white;\n          transform: translate(17%, -35%);\n        }\n      }\n    }\n    .buttons {\n      margin: 0 10px;\n      .btn-event {\n        background-color: #0499cb;\n        color: white;\n        font-family: \"Inter\", sans-serif;\n        padding: 1px 19px;\n        margin: 0 5px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
