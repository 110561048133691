import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card, Typography, Button, Progress, Spin } from 'antd';
import CompanyForm from './comps/forms/CompanyForm';
// import { getCompanyDetails } from '../../services/dashboard';

import { ReactComponent as EditIcons } from '../../assets/icons/pencil.svg';
import noImage from '../../assets/empty-bg.jpg';
import './style.scss';

const Company = ({ company, loading }) => {
    const { Title } = Typography
    const [updateCompany, setUpdateCompany] = useState(false);

    // const fetchCompany = async () => {
    //     dispatch(setLoading(true));
    //     try {
    //         const res = await getCompanyDetails();
    //         dispatch(setCompany(res?.data?.results[0]));
    //     } finally {
    //         dispatch(setLoading(false));
    //     }
    // }


    function formatPhoneNumber(phoneNumber) {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber;
    }

    return (<>
        <Spin spinning={loading}>
            <Card className='company-details'>
                <div className="logo">
                    <img src={company?.logo ? company?.logo : noImage} alt="company-logo" />
                </div>
                <div className="details">
                    <div className="title">
                        <Title level={4}>{company?.name || '-'}</Title>
                        <Button
                            type="text"
                            shape="circle"
                            icon={<EditIcons />}
                            // disabled
                            onClick={() => setUpdateCompany(true)}
                        />
                    </div>
                    <div className="table-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ display: 'flex', justifyContent: 'space-between', minWidth: 200 }}>Progress <b>{Math.round(company?.progress) || 0}%</b></th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><Progress strokeColor='#0499CB' percent={company?.progress || 0} size={['100%', 12]} showInfo={false} /></td>
                                    <td>{formatPhoneNumber(company?.phone) || '-'}</td>
                                    <td>{company?.email || '-'}</td>
                                    <td>{company?.address_line1 ? `${company?.address_line1},` : ''} {company?.city ? company?.city + ',' : ''} {company?.state ? company?.state + ',' : ''} {company?.zip_code ? company?.zip_code : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Card>
        </Spin>
        <CompanyForm
            open={updateCompany}
            handelClose={() => setUpdateCompany(false)}
            company={company}
        // getCompanyDetails={fetchCompany}
        />
    </>)
}

const mapStateToProps = state => ({
    company: state.company.company,
    loading: state.company.loading,
});

export default connect(mapStateToProps)(Company);
