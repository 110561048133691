import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Typography, Button, Carousel, Dropdown } from 'antd';
import { MoreOutlined, DeleteFilled, PlusOutlined } from '@ant-design/icons';
import VenueForm from './forms/VenueForm';
import usePopup from '../../../common/MessageModal/usePopup';

import { ReactComponent as NextArrow } from '../../../assets/icons/arrow_circle_left.svg';
import bgImage from '../../../assets/background.jpg';

import { deleteVenue } from '../../../services/venues';
import RoomForm from './forms/RoomForm';

const VenueCard = ({ item, fetchVenues }) => {
    const popup = usePopup();
    const navigate = useNavigate();
    const { name, address_line1, images, rooms, zip_code } = item
    const { Title, Text } = Typography;
    const sliderRef = useRef(null);
    const [edit, setEdit] = useState(false);
    const [addRoom, setAddRoom] = useState(false);

    const deleteThisVenue = async () => {
        try {
            const res = await deleteVenue(item?.id);
            popup.success("Success!", res?.data?.message);
            fetchVenues();
        } catch (error) {
            const errorCode = error?.response?.status || 500; 
            if (errorCode === 500 || errorCode === 503) {
              window.location.href = '/server-error';
              console.log("Redirecting to server error page...");
            } else {
              popup.error("Oops...", error?.response?.data?.message);     
            }
          }
          

    }

    const items = [
        {
            label: <a onClick={() => setEdit(true)}><Text>Edit</Text></a>,
            key: 'edit',
        },
        {
            label: <a onClick={() => popup.confirm("Delete " + item?.name, "Are you sure? This action is permanent and cannot be undone.", deleteThisVenue)}><Text type='danger'>Delete</Text></a>,
            key: 'delete',
        },
    ];

    return (<>
        <Card className='venue-card'>
            <div className="card-body">
                <div style={{ cursor: 'pointer' }} onClick={() => navigate(`/company/venues/${item?.id}`)}>
                    <Title level={5} style={{ marginBottom: 0 }}>{name}</Title>
                    <Text type='secondary'>{address_line1 ? `${address_line1},` : ''} {zip_code?.city ? zip_code?.city + ',' : ''} {zip_code?.state ? zip_code?.state + ',' : ''} {zip_code?.zip_code ? zip_code?.zip_code : ''}</Text>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <div className="rooms">
                        {rooms?.length > 1 ? `${rooms.length} Rooms` : `${rooms.length} Room`}
                    </div>
                    <Dropdown menu={{ items }} placement="bottomRight" trigger={['click']}>
                        <Button type="text" shape="circle" icon={<MoreOutlined />} />
                    </Dropdown>
                </div>
            </div>
            <div className="slider">
                <Carousel ref={sliderRef}>
                    {images?.length ? images.map((item, i) => (
                        <img key={i} className='card-img' src={item?.image} alt="cover" />
                    )) : (
                        <img className='card-img' src={bgImage} alt="cover" />
                    )}
                </Carousel>
                {images?.length > 1 ? (
                    <div className="controls">
                        <div className="btn back" onClick={() => sliderRef?.current?.prev()}>
                            <NextArrow />
                        </div>
                        <div className="btn" onClick={() => sliderRef?.current?.next()}>
                            <NextArrow />
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="action-wrapper">
                <Button
                    size='large'
                    type="text" icon={<PlusOutlined />}
                    className='add-room'
                    onClick={() => setAddRoom(true)}
                >
                    Add Room
                </Button>
            </div>
        </Card>
        <VenueForm
            fetchVenues={fetchVenues}
            open={edit}
            handelClose={() => setEdit(false)}
            venue={{ ...item, type: 'edit' }}
        />
        <RoomForm
            fetchRooms={fetchVenues}
            open={addRoom}
            venue_id={item?.id}
            handelClose={() => setAddRoom(false)}
        />
    </>)
}

export default VenueCard
