import { createSlice } from "@reduxjs/toolkit";

const LocationSlice = createSlice({
  name: "location",
  initialState: {
    selectedLocation: null,
    locations: [],
  },
  reducers: {
    setLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
  },
});

// this is for dispatch
export const { setLocation, setLocations } = LocationSlice.actions;

// this is for configureStore
export default LocationSlice.reducer;
