import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

// UI's
import { Typography, Space, Button, Input, Row, Col, Result, Spin } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";

// utils
import { getEvents } from "../../services/events";
// comps
import { filters, layout } from "./comps/utils";
import ListCard from "./comps/ListCard";
import GridCard from "./comps/GridCard";
import CalendarView from "./comps/CalendarView";
import CreateEvent from "./CreateEvent";
import styles from "./events.module.scss";
import usePopup from "../../common/MessageModal/usePopup";

const Events = () => {
  const popup = usePopup();
  const location = useSelector((state) => state.venues.selectedVenue);
  const { Title } = Typography;
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [activeFilter, setActiveFilter] = useState("all");
  const [currentLayout, setCurrentLayout] = useState("grid");
  const [createNewEvent, setCreateNewEvent] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [eventSearch, setEventSearch] = useState("");
  const scrollWrapper = useRef(null);

  const fetchEvents = async (noLoading, search) => {
    if (noLoading === "loadmore" && page > 1) {
      setLoadingMore(true);
    } else if (!noLoading) {
      setPage(1);
      setLoading(true);
      setLoadingMore(false)
    } else {
      setPage(1);
      setLoadingMore(false)
      setLoading(false);
    }
    try {
      const res = await getEvents({
        location: location?.id,
        page: noLoading ? page : 1,
        status: activeFilter !== "all" ? activeFilter : "",
        search: search ? search : "",
      });
      const checkhasMore = Boolean(res?.data?.next);
      setHasMore(checkhasMore);
      if (page > 1) {
        setEvents((prev) => [...prev, ...res?.data?.results]);
      } else {
        setEvents(res?.data?.results);
      }
    } catch (error) {
      const errorCode = error?.response?.status || 500; 
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = '/server-error';
        console.log("Redirecting to server error page...");
      } else {
        popup.error("Oops...", error?.response?.data?.message ,error);     
      }
  }finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    setPage(1);
    if (location?.id) {
      fetchEvents();
    }
  }, [location, activeFilter]);

  useEffect(() => {
    if (location?.id && loadingMore === false && hasMore) {
      fetchEvents("loadmore");
    }
  }, [page]);

  useEffect(() => {
    if (location?.id && events.length) {
      fetchEvents("", eventSearch);
    }
  }, [eventSearch]);

  const handleScroll = () => {
    if (scrollWrapper?.current) {
      const check = Math.round(scrollWrapper?.current?.scrollTop + scrollWrapper?.current?.clientHeight) === scrollWrapper?.current?.scrollHeight;
      if (check && loadingMore === false && hasMore) {
        setPage((prev) => prev + 1);
      }
    }
  };

  useEffect(() => {
    if (scrollWrapper?.current) {
      scrollWrapper?.current?.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollWrapper?.current) {
        scrollWrapper?.current?.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollWrapper, hasMore, loadingMore]);

  return (
    <>
      <Space className={styles.header}>
        <Title level={2}>Events</Title>
        <Button
          type="primary"
          size={"large"}
          icon={<PlusOutlined />}
          onClick={() => {
            if (location?.id) {
              setCreateNewEvent(true);
            } else {
              popup.error(
                "No Venue",
                "Please select a venue before creating events."
              );
            }
          }}
        >
          New Event
        </Button>
      </Space>

      <Spin spinning={loadingMore} size={"large"}>
        <div className={styles.actions}>
          <Space className={styles.filters}>
            {filters.map((item, i) => (
              <Button
                key={i}
                className={`${styles.button} ${activeFilter === item.value && styles.active
                  }`}
                type="text"
                onClick={() => setActiveFilter(item.value)}
              >
                {item.title}
              </Button>
            ))}
          </Space>
          <Space className={styles.searchbar}>
            <Input
              size="large"
              placeholder="Search"
              styles={{ width: '100%' }}
              prefix={<SearchOutlined />}
              // onChange={(e) => {
              //   const search = e.target.value;
              //   if (search.length > 2) {
              //     setEventSearch(search);
              //   } else {
              //     setEventSearch('');
              //   }
              // }}

              onChange={(e) => {
                const search = e.target.value.trim();

                if (search.length > 2 || search === "") {
                  setPage(1);
                  setEventSearch(search);
                } else {
                  setEventSearch("");
                }
              }}
            />
          </Space>
          <Space className={styles.filters}>
            {layout.map((item, i) => (
              <Button
                key={i}
                className={`${styles.button} ${styles.icons} ${currentLayout === item.value && styles.active
                  }`}
                type="text"
                icon={item.icon}
                onClick={() => setCurrentLayout(item.value)}
              />
            ))}
          </Space>
        </div>
        {loading && currentLayout !== "calendar" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "calc(100vh - 400px)",
            }}
          >
            <Spin size={"large"} />
          </div>
        ) : (
          <div className={styles.main_wrapper} ref={scrollWrapper}>
            <div style={{ width: "100%" }}>
              <Row gutter={[24, 24]}>
                {(!loading && events?.length) ||
                  currentLayout === "calendar" ? (
                  currentLayout === "calendar" ? (
                    <Col span={24}>
                      <Spin size={"large"} spinning={loading}>
                        <CalendarView
                          fetchEvents={fetchEvents}
                          events={events}
                          setCreateNewEvent={setCreateNewEvent}
                        />
                      </Spin>
                    </Col>
                  ) : (
                    events.map((item, i) =>
                      currentLayout === "grid" ? (
                        <GridCard
                          fetchEvents={fetchEvents}
                          key={i}
                          event={item}
                          setCreateNewEvent={setCreateNewEvent}
                        />
                      ) : (
                        <ListCard
                          fetchEvents={fetchEvents}
                          key={i}
                          event={item}
                          setCreateNewEvent={setCreateNewEvent}
                        />
                      )
                    )
                  )
                ) : !loading ? (
                  <Col span={24}>
                    <Result
                      status="404"
                      title="Oops! No events are found!"
                      subTitle="Sorry, you don't have any events here."
                    />
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>
        )}
      </Spin>
      {createNewEvent ? (
        <CreateEvent
          open={createNewEvent}
          handelClose={(val) => setCreateNewEvent(val)}
          refreshEvents={fetchEvents}
        />
      ) : null}
    </>
  );
};

export default Events;
