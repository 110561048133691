import { ReactComponent as DashboardIcon } from "../../../assets/SideBar/dashboard.svg";
import { ReactComponent as EventsIcon } from "../../../assets/SideBar/events.svg";
import { ReactComponent as CompanySettingsIcon } from "../../../assets/SideBar/companySettings.svg";
import { ReactComponent as IntegrationsIcon } from "../../../assets/SideBar/integrations.svg";
import { ReactComponent as SubscriptionsIcon } from "../../../assets/SideBar/subscriptions.svg";
import { ReactComponent as FaqIcon } from "../../../assets/SideBar/faq.svg";

export const menuItems = [
  {
    key: "/",
    icon: <DashboardIcon />,
    label: "Dashboard",
  },
  {
    key: "/events",
    label: "Events",
    icon: <EventsIcon />,
  },
  {
    label: "Company Settings",
    key: "/company/venues/",
    icon: <CompanySettingsIcon />,
    children: [
      {
        label: "Venues",
        key: "/company/venues",
      },
      {
        label: "Packages",
        key: "/company/packages",
      },
      {
        label: "Services",
        key: "/company/services",
      },
      {
        label: "Team",
        key: "/team",
      },
    ],
  },

  {
    label: "Integrations",
    icon: <IntegrationsIcon />,
    children: [
      {
        label: "Website",
        key: "/integrations",
      },
      {
        label: "Setup Payments",
        key: "/Integration/LinkStripe",
        key: "/integration/StripeInfo",
      },
    ],
  },
  {
    label: "Subscriptions",
    key: "/subscriptions",
    icon: <SubscriptionsIcon />,
  },
  {
    label: "FAQs",
    key: "/FAQs",
    icon: <FaqIcon />,
  },
];
