import React, { useEffect, useState } from "react";
import "./Menu.scss";
import { Radio, Progress } from "antd";
import { ReactComponent as EditIcon } from "../../../assets/Menu/edit.svg";
import CompanyDetails from "../Modals/CompanyDetails";
import EditCompanyDetails from "../Modals/EditCompanyDetails";
import MessageModal from "../Modals/MessageModal";
import axios from "axios";

const Menu = ({ tabPosition, setTabPosition, setIsVenueDetailsMenu }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditCompanyDetails, setIsEditCompanyDetails] = useState(false);
  const [company, setCompany] = useState(null);
  const [companyRequired, setCompanyRequired] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [isSpining, setIsSpining] = useState(false); // [1
  const [companyState, setCompanyState] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [companyZipCode, setCompanyZipCode] = useState("");
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [file, setFile] = useState(null);
  const [companyLogo, setCompanyLogo] = useState("");

  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);
  };

  const getCompanyDetails = () => {
    try {
      axios
        .get(`${process.env.REACT_APP_BASE_API_URL}/api/company/`, {
          headers: {
            Authorization: `JWT ${localStorage.getItem("access_token")}`,
          },
        })
        .then((res) => {
          const data = res.data.results[0];
          setCompany(data);
          setCompanyId(data?.id);
          setCompanyName(data?.name);
          setCompanyEmail(data?.email);
          setCompanyPhone(data?.phone);
          setCompanyAddress(data?.address_line1);
          setCompanyCity(data?.city);
          setCompanyLogo(data?.logo);
          setCompanyState(data?.state);
          setCompanyZipCode(data?.zip_code);
          if (!data?.name) {
            setCompanyRequired(true);
            setIsEditCompanyDetails(true);
          }
        });
    } catch (error) { }
  };
  const updateCompanyDetails = () => {
    try {
      setIsSpining(true);
      const formData = new FormData();
      if (file) {
        formData.append("logo", file);
      }
      formData.append("name", companyName);
      formData.append("email", companyEmail);
      formData.append("phone", companyPhone);
      formData.append("address_line1", companyAddress);
      if (companyCity) {
        formData.append("city", companyCity);
      }
      if (companyState) {
        formData.append("state", companyState);
      }
      if (companyZipCode) {
        formData.append("zip_code", companyZipCode);
      }

      axios
        .put(
          `${process.env.REACT_APP_BASE_API_URL}/api/company/${companyId}/`,
          formData,
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem("access_token")}`,
            },
          }
        )
        .then((res) => {
          getCompanyDetails();
          setIsSpining(false);
          setIsEditCompanyDetails(false);
          setCompanyRequired(false);
          setIsMessageModal(true);
        });
    } catch (error) { }
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  return (
    <>
      <div className="menu-wrapper">
        <div className="company-info">
          {company?.logo ? (
            <img
              src={company?.logo ? company?.logo : null}
              alt="logo"
              srcset=""
              onClick={() => {
                setIsModalOpen(true);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          ) : (
            "Logo Unavailable"
          )}

          <span>{company?.name}</span>
          <EditIcon
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setIsEditCompanyDetails(true);
            }}
          />
        </div>
        <div className="menu">
          <Radio.Group
            value={tabPosition}
            className="menu-antd"
            size="large"
            onChange={changeTabPosition}
          >
            <Radio.Button
              onClick={() => {
                setIsVenueDetailsMenu();
              }}
              value="Venues"
              className="first-child"
            >
              Venues
            </Radio.Button>
            <Radio.Button value="Packages">Packages</Radio.Button>
            <Radio.Button value="Services">Services</Radio.Button>
            <Radio.Button value="Team">Team</Radio.Button>
            <Radio.Button value="Automation" className="last-child">
              Automation
            </Radio.Button>
          </Radio.Group>
        </div>
        <div className="progress">
          <Progress
            type="circle"
            strokeWidth={12}
            strokeColor="#29CC39"
            percent={75}
          />
        </div>
      </div>
      <CompanyDetails
        open={isModalOpen}
        setOpen={setIsModalOpen}
        company={company}
        setEditCompanyDetails={setIsEditCompanyDetails}
      />
      <EditCompanyDetails
        open={isEditCompanyDetails}
        company={company}
        companyName={companyName}
        file={file}
        setFile={setFile}
        setCompanyName={setCompanyName}
        companyEmail={companyEmail}
        setCompanyEmail={setCompanyEmail}
        companyPhone={companyPhone}
        companyLogo={companyLogo}
        setCompanyPhone={setCompanyPhone}
        companyAddress={companyAddress}
        setCompanyAddress={setCompanyAddress}
        companyCity={companyCity}
        setCompanyCity={setCompanyCity}
        updateCompanyDetails={updateCompanyDetails}
        isSpining={isSpining}
        companyState={companyState}
        setCompanyState={setCompanyState}
        companyZipCode={companyZipCode}
        setCompanyZipCode={setCompanyZipCode}
        setOpen={setIsEditCompanyDetails}
        companyRequired={companyRequired}
      />
      <MessageModal
        open={isMessageModal}
        setOpen={setIsMessageModal}
        content={"Company details updated successfully"}
      />
    </>
  );
};

export default Menu;
