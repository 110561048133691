import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button, Checkbox, Modal, Spin } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import axios from "axios";
import LoginHero from "../Register/Assets/Login.png";
import googlelogo from "../Register/Assets/Google.png";
import logo from "../Register/Assets/logo.png";
import "./Register.css";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { toast } from 'react-hot-toast';
import InputMask from "react-input-mask";
import PassowrdCheckList from "./PassowrdCheckList";
import PasswordStrengthBar from 'react-password-strength-bar';
import axiosInstance from '../../common/axiosInstance';
const RegisterInvited = () => {
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [showResendEmail, setShowResendEmail] = useState(false);
    const [isTermsModalVisible, setTermsModalVisible] = useState(false);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordValue, setPasswordValue] = useState("");
    const [strength, setStrength] = useState(0);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState('');
    const [passwordBorderColor, setPasswordBorderColor] = useState('');
    const [showPasswordStrengthModal, setShowPasswordStrengthModal] = useState(false);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    // get token from params
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");

    const getInvitedUser = async () => {
        await axiosInstance.get(
            `${process.env.REACT_APP_BASE_API_URL}/api/auth/user/invited/?token=${token}`,
        ).then((response) => {
            setInitialLoading(false);
            setUser(response.data.data);
        }).catch((error) => {
            setInitialLoading(false);
        })
    }

    useEffect(() => {
        getInvitedUser();
    }, []);

    const onFinish = async (values) => {
        setLoading(true);
        // remove douple spaces from name
        values.first_name = values.first_name?.replace(/\s{2,}/g, " ").trim();
        values.last_name = values.last_name?.replace(/\s{2,}/g, " ").trim();
        // if first_name contains space and there are 2 words, then make second word as middle name
        if (values.first_name.includes(" ")) {
            const words = values.first_name.split(" ");
            if (words.length === 2) {
                values.first_name = words[0];
                values.middle_name = words[1];
            }
        }
        // if first_name does not contain space and last_name contains space and there are 2 words, then make first word as middle name
        if (!values.first_name.includes(" ") && values.last_name.includes(" ")) {
            const words = values.last_name.split(" ");
            if (words.length === 2) {
                values.middle_name = words[0];
                values.last_name = words[1];
            }
        }
        // if both first_name and last_name contain space, then make second word of the first name as middle name and first word of the last name as last name
        if (values.first_name.includes(" ") && values.last_name.includes(" ")) {
            const words = values.first_name.split(" ");
            if (words.length === 2) {
                values.first_name = words[0];
                values.middle_name = words[1];
            }
            const words2 = values.last_name.split(" ");
            if (words2.length === 2) {
                values.middle_name += " " + words2[0];
                values.last_name = words2[1];
            }
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_API_URL}/api/auth/invite/register/`,
                {
                    username: values.username,
                    password: values.password,
                    first_name: values.first_name,
                    middle_name: values.middle_name,
                    last_name: values.last_name,
                    mobile: values.mobile,
                    token: token
                }
            );
            localStorage.setItem("access_token", response.data.data.access);
            localStorage.setItem("planspace_user_obj", JSON.stringify(response.data.data));
            navigate("/");
            setLoading(false);
            setEmail(values.username);
            setShowResendEmail(true);
        } catch (error) {
            setLoading(false);
            toast.error("Email already registered. Log in or use a different email");
        }
    };

    const validatePassword = (_, value) => {
        if (!value) {
            return Promise.reject("Please enter your password.");
        }
        // const passwordRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&#.^()_]{8,}$/;
        if (value.length < 8) {
            return Promise.reject("Password is too short");
        }
        return Promise.resolve();
    };


    // password Strength chekker 
    const [passwordErrors, setPasswordErrors] = useState({
        length: false,
        hasUpperCase: false,
        hasDigit: false,
        hasSpecialChar: false,
    });
    const handlePassword = (passwordValue) => {
        const strengthChecks = {
            length: passwordValue.length >= 8,
            hasUpperCase: /[A-Z]+/.test(passwordValue),
            hasDigit: /[0-9]+/.test(passwordValue),
        };

        setPassword(passwordValue);
        setPasswordValue(passwordValue);
        setPasswordErrors(strengthChecks);

        const newStrength = Object.values(strengthChecks).filter(Boolean).length;
        setStrength(newStrength);
        setProgress((newStrength / 4) * 100);

        if (newStrength === 1) {
            setMessage("Too Short");
        } else if (newStrength === 2) {
            setMessage("Weak");
        } else if (newStrength === 3) {
            setMessage("Okay");
        } else if (newStrength === 4) {
            setMessage("Good");
        } else if (newStrength == 5) {
            setMessage("Strong")
        }
        const borderColor = getPasswordBorderColor(newStrength);
        setPasswordBorderColor(borderColor);
        setShowPasswordStrengthModal(true);
    };


    const getPasswordBorderColor = (strength) => {
        const barColors = ['#ddd', '#ef4836', '#f6b44d', '#2b90ef', '#25c281'];
        return barColors[Math.min(strength, barColors.length)];
    };


    const handlePasswordBlur = (e) => {
        const isClickOutsideCard = !e.relatedTarget || !e.relatedTarget.closest('.password-strength-modal');
        if (isClickOutsideCard) {
            setTimeout(() => {
                setShowPasswordStrengthModal(false);
            }, 0);
        }
    };
    // fomate number into us formate 
    const formatPhoneNumber = (phoneNumber) => {
        const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
        const formattedPhoneNumber = `(${numericPhoneNumber.slice(0, 3)}) ${numericPhoneNumber.slice(3, 6)}-${numericPhoneNumber.slice(6, 10)}`;
        return formattedPhoneNumber;
    };

    const googleLogin = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            await axios
                .post(`${process.env.REACT_APP_BASE_API_URL}/api/auth/login/google/`, {
                    access_token: codeResponse.access_token,
                    source: "register",
                })
                .then((res) => {
                    localStorage.setItem("access_token", res.data.data.access);
                    localStorage.setItem(
                        "planspace_user_obj",
                        JSON.stringify(res.data.data)
                    );
                    navigate("/");
                })
                .catch((err) => {
                    toast.error(
                        err?.response?.data?.message.errors
                            ? err?.response?.data?.message.errors[0]
                            : "Email Already Registered"
                    );
                });
        },
        onError: (error) => console.log("Login Failed:", error),
    });

    const showTermsModal = () => {
        setTermsModalVisible(true);
        setAgreedToTerms(false);
    };
    return (
        <>
            <Row>{/* if initialLoaing then show spinner */}
                {initialLoading ? (
                    <div style={{ textAlign: "center", margin: "50px auto" }} >
                        <Spin />
                    </div>
                ) : (
                    <div className="register-container">
                        <Col span={11} className="register-image-column">
                            <div className="register-image">
                                <img src={LoginHero} alt="register image" />
                            </div>
                        </Col>

                        {/* if token in not valid i.e user is null then show error message */}
                        {user ? (
                            <>
                                <Col span={13} className="register-column">
                                    <div className="register-logo">
                                        <img src={logo} alt="" />
                                    </div>
                                    <div className="register-form-container">
                                        <div className="register-center-content">
                                            <p className="register-heading">Welcome to PlanSpace</p>
                                            <p
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "400",
                                                    lineHeight: "24px",
                                                    letterSpacing: "0.08px",
                                                    marginTop: "6px",
                                                    color: "#667085",
                                                }}
                                            >
                                                Register now and start your adventure.
                                            </p>
                                        </div>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="register-google-button"
                                            onClick={() => googleLogin()}
                                        >
                                            <div className="button-content">
                                                <img
                                                    src={googlelogo}
                                                    alt="Google Logo"
                                                    className="google-logo"
                                                />
                                                <span className="button-text">Register with Google</span>
                                            </div>
                                        </Button>
                                        <div className="register-divider">
                                            <div className="register-divider-line"></div>
                                            <div className="register-divider-text">Or</div>
                                            <div className="register-divider-line"></div>
                                        </div>{" "}
                                        <br />
                                        <Form
                                            name="register-form"
                                            initialValues={{
                                                remember: true,
                                                username: user?.username,
                                                first_name: user?.first_name,
                                                last_name: user?.last_name,
                                                company_name: user?.company,
                                                mobile: user?.mobile ? formatPhoneNumber(user?.mobile) : '',
                                            }}
                                            onFinish={onFinish}
                                        >
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <label
                                                        htmlFor="first_name"
                                                        style={{ fontSize: "16px", color: "#777980" }}
                                                    >
                                                        First Name*
                                                    </label>
                                                    <Form.Item
                                                        name="first_name"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please enter your first name.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Your first name"
                                                            className="register-form-item"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <label
                                                        htmlFor="last_name"
                                                        style={{ fontSize: "16px", color: "#777980" }}
                                                    >
                                                        Last Name*
                                                    </label>
                                                    <Form.Item
                                                        name="last_name"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please enter your last name.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Your last name"
                                                            className="register-form-item"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <label
                                                        htmlFor="company_name"
                                                        style={{ fontSize: "16px", color: "#777980" }}
                                                    >
                                                        Company
                                                    </label>
                                                    <Form.Item
                                                        name="company_name"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please enter your company name.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            disabled
                                                            placeholder="Your company name"
                                                            className="register-form-item"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <label
                                                        htmlFor="mobile"
                                                        style={{ fontSize: "16px", color: "#777980" }}
                                                    >
                                                        Mobile*
                                                    </label>
                                                    <Form.Item
                                                        name="mobile"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Phone number is required!",
                                                            },
                                                            {
                                                                validator(_, value) {
                                                                    const phoneMaskPattern = /\(\d{3}\) \d{3}-\d{4}/;
                                                                    if (!value || phoneMaskPattern.test(value)) {
                                                                        return Promise.resolve();
                                                                    }
                                                                    return Promise.reject(
                                                                        "Please enter a valid Phone Number!"
                                                                    );
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <InputMask
                                                            mask="(999) 999-9999"
                                                            maskChar="_"
                                                            placeholder=" (999) 999-9999"
                                                            className="register-form-item"
                                                            style={{
                                                                height: "32px",
                                                                borderRadius: "8px",
                                                                paddingLeft: "6px",

                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <label
                                                htmlFor="username"
                                                style={{ fontSize: "16px", color: "#777980" }}
                                            >
                                                Username (Email)*
                                            </label>
                                            <Form.Item
                                                name="username"
                                                rules={[
                                                    { required: true, message: "Please enter your email." },
                                                    {
                                                        type: "email",
                                                        message: "Please enter a valid email address.",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder="Your email"
                                                    className="register-form-item"
                                                />
                                            </Form.Item>
                                            <label
                                                htmlFor="password"
                                                style={{ fontSize: "16px", color: "#777980" }}
                                            >
                                                Password*
                                            </label>
                                            <Form.Item
                                                name="password"
                                                rules={[{ validator: validatePassword }]}
                                            >
                                                <Input.Password
                                                    placeholder="Your password"
                                                    className="register-form-item"
                                                    onChange={({ target }) => handlePassword(target.value)}
                                                    style={{ borderColor: getPasswordBorderColor(strength) }}
                                                    onBlur={(e) => handlePasswordBlur(e)}
                                                />
                                            </Form.Item>
                                            {showPasswordStrengthModal && (
                                                <div className="overlay">
                                                    <div className="password-strength-modal">
                                                        <p>Password Strength</p>
                                                        <PasswordStrengthBar password={passwordValue} />
                                                        <PassowrdCheckList passwordErrors={passwordErrors} />
                                                    </div>
                                                </div>
                                            )}
                                            <Form.Item
                                                name="remember"
                                                valuePropName="checked"
                                                className="register-checkbox-item">
                                                <div className="register-checkbox-container">
                                                    <Checkbox
                                                        className="register-checkbox"
                                                        checked={agreedToTerms}
                                                        onChange={(e) => setAgreedToTerms(e.target.checked)}
                                                    >
                                                        <span className="register-checkbox-label">
                                                            I agree to all the{" "}
                                                        </span>
                                                        <a
                                                            href="#"
                                                            style={{ color: "#0499CB", fontWeight: "bold" }}
                                                            className="register-policy-link"
                                                            onClick={showTermsModal}
                                                        >
                                                            Terms
                                                        </a>
                                                        <span> & </span>
                                                        <a
                                                            href="#"
                                                            style={{ color: "#0499CB", fontWeight: "bold" }}
                                                            className="register-policy-link"
                                                            onClick={showTermsModal}
                                                        >
                                                            Privacy Policy
                                                        </a>
                                                    </Checkbox>
                                                </div>
                                            </Form.Item>
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    className="register-login-button"
                                                    loading={loading}
                                                    disabled={!agreedToTerms}
                                                >
                                                    <span className="register-button-text">
                                                        {loading ? null : "Register"}
                                                    </span>
                                                </Button>
                                            </Form.Item>
                                            <p style={{ textAlign: "center" }}>
                                                Already have an account?
                                                <span>
                                                    {" "}
                                                    <span>
                                                        {" "}
                                                        <Link
                                                            to="/login"
                                                            style={{ color: "#0499CB", fontWeight: "bold" }}
                                                        >
                                                            Login
                                                        </Link>
                                                    </span>
                                                </span>
                                            </p>
                                        </Form>
                                    </div>
                                </Col>
                                <Modal
                                    title={(
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            Terms & Conditions
                                            <CloseOutlined
                                                onClick={() => setTermsModalVisible(false)}
                                            />
                                        </div>
                                    )}
                                    visible={isTermsModalVisible}
                                    closable={false}
                                    footer={[
                                        <Button
                                            key="Agree"
                                            type="primary"
                                            onClick={() => {
                                                setAgreedToTerms(true);
                                                setTermsModalVisible(false);
                                            }}
                                        >
                                            I Agree to Terms & Conditions
                                        </Button>,
                                    ]}
                                    width={600}

                                >
                                    {/* <CloseOutlined
                className="custom-close-icon" // Add a custom CSS class for styling
                onClick={() => setTermsModalVisible(false)}
              /> */}
                                    <p>
                                        Processing personal data is generally prohibited, unless it is
                                        expressly allowed by law, or the data subject has consented to
                                        the processing. While being one of the more well-known legal
                                        bases for processing personal data, consent is only one of six
                                        bases mentioned in the General Data Protection Regulation
                                        (GDPR). The others are: contract, legal obligations, vital
                                        interests of the data subject, public interest, and legitimate
                                        interest as stated in Article 6(1) GDPR. Processing personal
                                        data is generally prohibited, unless it is expressly allowed by
                                        law, or the data subject has consented to the processing. While
                                        being one of the more well-known legal bases for processing
                                        personal data, consent is only one of six bases mentioned in the
                                        General Data Protection Regulation (GDPR). The others are:
                                        contract, legal obligations, vital interests of the data
                                        subject, public interest, and legitimate interest as stated in
                                        Article 6(1) GDPR.
                                    </p>
                                </Modal>
                            </>
                        ) : (
                            <Col span={13} className="register-column">
                                <div className="register-form-container">
                                    <h2>Invalid Invitation</h2>
                                </div>
                            </Col>
                        )}
                    </div>
                )}
            </Row>
        </>
    );
};

export default RegisterInvited;
