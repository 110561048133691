import { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import IndividualStripe from "./IndividualStripe";
import CompanyStripe from "./CompanyStripe";
import { loadStripe } from '@stripe/stripe-js/pure';
import { useParams } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Index = () => {
  const [getAccount, setGetAccount] = useState("recalled");
  const { accountType } = useParams()

  return (
    <div>
      <Elements stripe={stripePromise}>
        {accountType === "company" ? (
          <CompanyStripe />
        ) : accountType === "individual" ? (
          <IndividualStripe setGetAccount={(val) => setGetAccount(val)} />
        ) : null}
      </Elements>
    </div>
  );
};

export default Index;
