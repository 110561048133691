import { createSlice } from "@reduxjs/toolkit";

const venuesSlice = createSlice({
    name: "venues",
    initialState: {
        selectedVenue: null,
        venues: [],
        venuesLoading: false
    },
    reducers: {
        setVenue: (state, action) => {
            state.selectedVenue = action.payload;
        },
        setVenues: (state, action) => {
            state.venues = action.payload;
        },
        setVenuesLoading: (state, action) => {
            state.venuesLoading = action.payload;
        },
    },
});

// this is for dispatch
export const { setVenue, setVenues, setVenuesLoading } = venuesSlice.actions;

// this is for configureStore
export default venuesSlice.reducer;
