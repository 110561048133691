import React from 'react';
import { Row, Col, Button } from 'antd';
import logo2 from '../Assets/logo2.png';
import { Link } from 'react-router-dom';
import './BizAddress.css';

const BusinessAddress = () => {

    return (
        <Row className="address-container">
            <Col span={3}>
                <div className="address-logo-container">
                    <img src={logo2} alt="Logo" className="address-logo" />
                </div>
            </Col>
            <Col span={21} className='address-form-container'>
                <div className="address-setup-title">
                    <p>Base Setup</p>
                </div>
                <div className="address-setup-content">
                    <div className="address-card-container">
                        <div className="address-input-group-active">
                            <p className='address-active-setup-address'>Business Address</p>
                            <input
                            style={{
                                backgroundColor: "#F8F8F8",
                                color: "#B6B6B6",
                                fontFamily: "Inter, sans-serif",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 100,
                                lineHeight: "15px",
                            }}
                                type="text"
                                id="address-businessaddress"
                                placeholder="Your business address"
                                name="businessaddress"
                            />
                        </div>
                        <Link to='/businesswebsite'>
                            <button type="primary" className="address-setup-next-button">
                                Save & go next
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="address-setup-input-container">
                    <div className="address-setup-contact-group">
                        <p className='address-setup-contact'>Add Business Contact</p>
                        <input
                            type="text"
                            id="businesscontact"
                            placeholder="Your business contact"
                            name="businesscontact"
                            disabled
                        />
                    </div>
                    <div className="address-setup-website-group">
                        <p className='address-setup-website'>Business Website</p>
                        <input
                            type="text"
                            id="address-businesswebsite"
                            placeholder="Your business website"
                            name="address-businesswebsite"
                            disabled
                        />
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default BusinessAddress;
