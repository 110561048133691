import React, { useState, useEffect } from 'react'
import { Card, Button, Row, Col } from 'antd';
import './paymentIntegration.scss'
import UpdatePersonalInformation from './UpdatePersonalInformation';

const PersonalInformation = ({ person, accountID, callback }) => {
    const [editModal, setEditModal] = useState(false)
    const [personData, setPersonData] = useState(person)

    const formatAddress = (address) => {
        if (!address) return ''
        // check if any of the info is null then replace it with ''
        let newAddress = ''
        if (address.line1) newAddress += address.line1 + ', '
        if (address.line2) newAddress += address.line2 + ', '
        if (address.city) newAddress += address.city + ', '
        if (address.state) newAddress += address.state + ' '
        if (address.postal_code) newAddress += address.postal_code
        return newAddress
    }


    const formatphoneNumber = (phoneNumber) => {
        return `${phoneNumber?.substring(0, 2)} (${phoneNumber?.substring(2, 5)})${phoneNumber?.substring(5, 8)}-${phoneNumber?.substring(8)}`;
    }
    const removeCountryCode = (phoneNumber) => {
        const updated = phoneNumber?.slice(2);
        return updated;
    }

    useEffect(() => {
        setPersonData({ ...person, phone: removeCountryCode(person?.phone) })
    }, [person])

    return (
        <Card style={{ marginTop: "20px" }} className="inner-card">
            <div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h2>Personal Information</h2>
                    <Button type="link" onClick={() => setEditModal(true)}>Change</Button>
                </div>

                <Row style={{ marginTop: 15 }} gutter={[15, 15]}>
                    <Col span={24} md={12}>
                        <p className='info-heading-stripe'>
                            Name
                        </p>
                        <p style={{ marginTop: "0px" }}>{person?.first_name + " " + person?.last_name}</p>
                    </Col>
                    <Col span={24} md={12}>
                        <p className='info-heading-stripe'>
                            Date of Birth
                        </p>
                        <p style={{ marginTop: "0px" }}>{person?.dob?.day}-{person?.dob?.month}-{person?.dob?.year}</p>
                    </Col>
                    <Col span={24} md={12}>
                        <p className='info-heading-stripe'>
                            Email
                        </p>
                        <p style={{ marginTop: "0px" }}>{person?.email}</p>
                    </Col>
                    <Col span={24} md={12}>
                        <p className='info-heading-stripe'>
                            Phone
                        </p>
                        <p style={{ marginTop: "0px" }}>{formatphoneNumber(person?.phone)}</p>
                    </Col>
                    <Col span={24} md={12}>
                        <p className='info-heading-stripe'>
                            City
                        </p>
                        <p style={{ marginTop: "0px" }}>{person?.address?.city}</p>
                    </Col>
                    <Col span={24} md={12}>
                        <p className='info-heading-stripe'>
                            State
                        </p>
                        <p style={{ marginTop: "0px" }}>{person?.address?.state}</p>
                    </Col>
                    <Col span={24} md={12}>
                        <p className='info-heading-stripe'>
                            Address
                        </p>
                        <p style={{ marginTop: "0px" }}>{formatAddress(person?.address)}</p>
                    </Col>
                </Row>
            </div>

            {editModal ? (
                <UpdatePersonalInformation
                    open={editModal}
                    setEditModal={setEditModal}
                    personData={personData}
                    setPersonData={setPersonData}
                    accountID={accountID}
                    callback={callback}
                />
            ) : null}
        </Card>
    )
}

export default PersonalInformation