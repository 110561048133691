import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ImageUploader from "../../../../components/ImageUploader";
import usePopup from "../../../../common/MessageModal/usePopup";
import { getRooms } from "../../../../services/rooms";

// UI's
import { Button, Modal, Checkbox, Select, Empty } from "antd";
import { Form, Input, Row, Col, Spin, Typography, Card } from "antd";

import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import ServicesForm from "./ServicesForm";
import { createPackage, updatePackage } from "../../../../services/packages";
import { getServices } from "../../../../services/services";

import "../../styles/packages.scss";

const PackageForm = ({
  open,
  handelClose,
  venue_id,
  room,
  fetchPackages,
  update_package,
  autoSelectPackage,
}) => {
  const popup = usePopup();
  const current_venue = useSelector((state) => state.venues.selectedVenue?.id);
  const [form] = Form.useForm();
  const { Title, Text } = Typography;
  const [images, setImages] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [services, setServices] = useState([]);
  const [roomsLoading, setRoomsLoading] = useState(false);
  const [servLoading, setServLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showGuests, setShowGuests] = useState(false);
  const [servicesModal, setServicesModal] = useState(false);
  const [servSearch, setServSearch] = useState('');

  const fetchRooms = async () => {
    setRoomsLoading(true);
    try {
      const res = await getRooms(venue_id ? venue_id : current_venue);
      const data = res?.data?.results?.map((room) => ({
        label: room.name,
        value: room.id,
        guests: room?.max_guests,
      }));
      setRooms(data);
    } finally {
      setRoomsLoading(false);
    }
  };

  const fetchServices = async (value) => {
    if (value) {
      setServices([]);
    }
    setServLoading(true);
    try {
      const res = await getServices({
        location: venue_id ? venue_id : current_venue,
        is_active: true,
        search: value ? value : ''
      });
      setServices(res?.data?.results);
    } finally {
      setServLoading(false);
    }
  };

  useEffect(() => {
    if (open && (current_venue || venue_id)) {
      fetchServices(servSearch);
    }
  }, [servSearch]);

  useEffect(() => {
    if (open && (current_venue || venue_id)) {
      fetchRooms();
      fetchServices();
    }
  }, [open, current_venue, venue_id]);

  useEffect(() => {
    if (room?.id) {
      form.setFieldValue("room", room?.id);
    }
    if (room?.max_guests) {
      setShowGuests(room?.max_guests);
      form.setFieldValue("num_of_guests", room?.max_guests);
    }
  }, [open, room]);

  useEffect(() => {
    if (update_package) {
      const { room, addons, images, duration_minutes, ...rest } = update_package;
      const durationInHours = duration_minutes / 60;
      const addons_ids = addons?.map((addOn) => addOn?.id);
      form.setFieldsValue({ ...rest, addons: addons_ids, room: room?.id, duration_minutes: durationInHours });
      setShowGuests(room?.max_guests);
    }
  }, [update_package, open]);

  const handleCancel = () => {
    handelClose();
    form.resetFields();
    setImages([]);
  };

  const renderdButtons = () => {
    let buttons = [
      <Button size={"large"} key="cancel" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button
        style={{ marginLeft: "auto" }}
        size={"large"}
        key="next"
        type="primary"
        loading={loading}
        onClick={() => form.submit()}
      >
        {update_package?.type === "edit" ? "Update" : "Create"}
      </Button>,
    ];
    return buttons;
  };

  const handleSubmit = async (values) => {
    values.duration_minutes = values.duration_minutes * 60;
    const formData = new FormData();
    if ((venue_id || current_venue) && update_package !== "edit") {
      formData.append("location", venue_id ? venue_id : current_venue);
    }
    formData.append("status", "activated");
    Object.entries(values).map(([key, value]) => {
      formData.append(
        key,
        typeof value === "array" || typeof value === "object"
          ? JSON.stringify(value)
          : value === undefined
            ? ""
            : value
      );
    });

    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i].originFileObj);
      }
    }

    setLoading(true);
    try {
      if (update_package?.type === "edit") {
        const res = await updatePackage(update_package?.id, formData);
        popup.success("Success!", res?.data?.message);
      } else {
        const res = await createPackage(formData);
        popup.success("Success!", res?.data?.message);
        if (autoSelectPackage !== undefined) {
          autoSelectPackage(res?.data?.data?.id);
        }
      }
      fetchPackages();
      handleCancel();
    } catch (error) {
      const errorCode = error?.response?.status || 500;

      if (errorCode === 500 || errorCode === 503) {
        window.location.href = '/server-error';
        console.log("Redirecting to server error page...");
      }
      if (typeof error?.response?.data?.message === "object" || "array") {
        const errors = error?.response?.data?.message[0];
        Object.entries(errors).map(([field, message]) => {
          form.setFields([
            {
              name: field,
              errors: message,
            },
          ]);
        });
      } else {
        popup.error("Oops...", error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return open ? (
    <>
      <Modal
        centered
        title={
          update_package?.type === "edit" ? "Update Package" : "Create Package"
        }
        open={open}
        onCancel={handleCancel}
        footer={renderdButtons()}
      >
        <Form
          style={{ marginTop: 24 }}
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          className="modal-form"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="name"
            label="Package Name*"
            rules={[
              {
                required: true,
                message: "Package name is required!",
              },
            ]}
          >
            <Input maxLength={50} size="large" placeholder="Enter package name" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description*"
            rules={[
              {
                required: true,
                message: "Description is required!",
              },
            ]}
          >
            <Input.TextArea
              maxLength={150}
              autoSize={{ minRows: 3, maxRows: 8 }}
              size="large"
              placeholder="Max 150 characters are allowed!"
            />
          </Form.Item>
          <Row gutter={[24, 24]}>
            <Col span={24} xs={12}>
              <Form.Item
                name={"price"}
                label="Package Price*"
                rules={[
                  {
                    validator: (rule, value) => {
                      const numericValue = parseFloat(value);
                      if (
                        isNaN(numericValue) ||
                        numericValue <= 0 ||
                        numericValue > 1000000
                      ) {
                        return Promise.reject(
                          "Please enter a valid amount between 1 and 1,000,000!"
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input size="large" type="number" prefix={<>$</>} />
              </Form.Item>
            </Col>
            <Col span={24} xs={12}>
              <Form.Item
                name={"duration_minutes"}
                label="Duration*"
                rules={[
                  {
                    required: true,
                    message: 'Package duration is required!'
                  },
                  {
                    pattern: /^[1-9]\d{0,2}$/,
                    message: 'Provide a valid time duration in hours!'
                  }
                ]}
              >
                <Input size="large" type="number" placeholder="Enter hours" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="room" label="Assign Room">
            <Select
              size="large"
              style={{ width: "100%" }}
              placeholder="select"
              options={rooms}
              allowClear
              loading={roomsLoading}
              disabled={room?.id}
              onChange={(e, val) => {
                if (val?.guests) {
                  setShowGuests(val?.guests);
                  form.setFieldValue("num_of_guests", val?.guests);
                } else {
                  setShowGuests(false);
                  form.setFieldValue("num_of_guests", "");
                }
              }}
              onDropdownVisibleChange={(open) => {
                if (open) {
                  !rooms?.length && fetchRooms();
                }
              }}
            />
          </Form.Item>
          <div className="modal-checkbox-options">
            <div className="header">
              <Title level={5}>Services</Title>
              <Button
                type="text"
                size={"medium"}
                onClick={() => setServicesModal(true)}
              >
                <PlusOutlined /> New Services
              </Button>
            </div>
            <Card className="options-wrapper">
              <Input
                placeholder="Search Services"
                prefix={<SearchOutlined />}
                onChange={(e) => {
                  const searchValue = e.target.value;
                  if (searchValue?.length) {
                    setServSearch(searchValue);
                  } else {
                    setServSearch('');
                  }
                }}
              />
              <Spin spinning={servLoading}>
                <div className="content-wrapper package-wrapper-services">
                  {services?.length ? (
                    <Form.Item name="addons">
                      <Checkbox.Group
                        style={{ width: "100%", display: "block" }}
                      >
                        {services?.map((serv, i) => (
                          <div key={i}>
                            <div
                              className="list-wrapper"
                              style={{ marginBottom: "10px" }}
                            >
                              <Checkbox value={serv.id} style={{ color: '#0499CB' }}>
                                <div style={{ marginLeft: "5px" }}>
                                  <Title
                                    style={{ fontSize: 14, marginBottom: 0 }}
                                  >
                                    {serv?.name}
                                  </Title>
                                  <Text type={"secondary"}>
                                    {serv?.description}
                                  </Text>
                                </div>
                              </Checkbox>
                            </div>
                          </div>
                        ))}
                      </Checkbox.Group>
                    </Form.Item>
                  ) : (
                    <Empty size="small" />
                  )}
                </div>
              </Spin>
            </Card>
          </div>

          <ImageUploader
            previous_images={update_package?.images}
            revalidate={fetchPackages}
            output={setImages}
          />
          <div style={{ marginBottom: 16 }} />
          <Form.Item
            name={"num_of_guests"}
            label="Number of guests*"
            rules={[
              {
                validator: (rule, value) => {
                  const numericValue = parseFloat(value);
                  if (
                    isNaN(numericValue) ||
                    numericValue <= 0 ||
                    numericValue > 500
                  ) {
                    return Promise.reject("Guest must be between 1 and 500");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              disabled={Boolean(showGuests)}
              size="large"
              type="number"
              placeholder="type here..."
            />
          </Form.Item>
        </Form>
      </Modal>

      <ServicesForm
        open={servicesModal}
        handelClose={() => {
          setServicesModal(false);
        }}
        fetchServices={fetchServices}
        autoSelectAddOn={(val) => {
          const old = form.getFieldValue("addons");
          if (old) {
            form.setFieldValue("addons", [...old, val]);
          } else {
            form.setFieldValue("addons", [val]);
          }
        }}
      />
    </>
  ) : null;
};

export default PackageForm;
