import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Typography, Button, Carousel, Dropdown, Divider } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import usePopup from '../../../common/MessageModal/usePopup';

import { ReactComponent as NextArrow } from '../../../assets/icons/arrow_circle_left.svg';
import { ReactComponent as PackagesIcon } from '../../../assets/icons/packages.svg';
import bgImage from '../../../assets/background.jpg';

import { deleteRoom } from '../../../services/rooms';
import RoomForm from './forms/RoomForm';

import '../styles/venue-details.scss';

const RoomCard = ({ room, fetchRooms }) => {
    const popup = usePopup();
    const navigate = useNavigate();
    const { id } = useParams();
    const { Title, Text } = Typography;
    const sliderRef = useRef(null);
    const [edit, setEdit] = useState(false);

    const deleteThisRoom = async () => {
        try {
            const res = await deleteRoom(room?.id);
            popup.success("Success!", res?.data?.message);
            fetchRooms();
        } catch (error) {
            const errorCode = error?.response?.status || 500; 
            if (errorCode === 500 || errorCode === 503) {
              window.location.href = '/server-error';
              console.log("Redirecting to server error page...");
            } else {
              popup.error("Oops...", error?.response?.data?.message);     
            }
                    
        }
    }

    const items = [
        {
            label: <a onClick={() => setEdit(true)}><Text>Edit</Text></a>,
            key: 'edit',
        },
        {
            label: <a onClick={() => popup.confirm('Delete ' + room?.name, "Are you sure? This action is permanent and cannot be undone.", deleteThisRoom)}><Text type='danger'>Delete</Text></a>,
            key: 'delete',
        },
    ];

    return (
        <Card className='room-card'>
            <div className="slider">
                <Carousel ref={sliderRef}>
                    {room?.images?.length ? room?.images?.map((item, i) => (
                        <img key={i} className='card-img' src={item?.image} alt="cover" />
                    )) : (
                        <img className='card-img' src={bgImage} alt="cover" />
                    )}
                </Carousel>
                {room?.images?.length > 1 ? (
                    <div className="controls">
                        <div className="btn back" onClick={() => sliderRef?.current?.prev()}>
                            <NextArrow />
                        </div>
                        <div className="btn" onClick={() => sliderRef?.current?.next()}>
                            <NextArrow />
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="header">
                <Title style={{ cursor: 'pointer' }} onClick={() => navigate(`/company/venues/${id}/rooms/${room?.id}`)} level={5}>{room?.name}</Title>
                <Dropdown menu={{ items }} placement="bottomRight" trigger={['click']}>
                    <Button type="text" shape="circle" icon={<MoreOutlined />} />
                </Dropdown>
            </div>
            <div className="content">
                <div className="item">
                    <label>Event Types</label>
                    <div className="value types">
                        {/* {room?.ceremony_types?.length ? room?.ceremony_types.map((item, i) => (<p key={i}>{item}</p>)) : '-'} */}
                        {room?.ceremony_types?.length ? room?.ceremony_types.join(', ') : '-'}
                    </div>
                </div>
                <div className="item">
                    <label>Price</label>
                    <div className="value">${room?.base_price || '0'}</div>
                </div>
                <div className="item">
                    <label>Maximum Capacity</label>
                    <div className="value">{room?.max_guests || '0'}</div>
                </div>
            </div>
            <Divider style={{ borderBlockStartStyle: 'dashed', marginBlock: 16 }} />
            <div className="packages">
                <PackagesIcon />
                <Text type='secondary'>{room?.packages?.length > 1 ? `${room?.packages?.length} Packages` : `${room?.packages?.length} Packages`}</Text>
            </div>

            <RoomForm
                fetchRooms={fetchRooms}
                open={edit}
                handelClose={() => setEdit(false)}
                room={{ ...room, type: 'edit' }}
            />
        </Card>
    )
}

export default RoomCard
