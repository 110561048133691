import React, { useState } from 'react';
import { Button, Modal, Card, Space } from 'antd';
import styles from '../../../events.module.scss';
import { ReactComponent as Uncheck } from '../../../../../assets/icons/uncheck.svg';
import { ReactComponent as Checked } from '../../../../../assets/icons/checked.svg';
import './billings.scss';
import { createPayment } from '../../../../../services/events';

const AddPayments = ({ open, handelClose, totalPrice, fetchAllPayments, eventId }) => {
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState('');

    const handleCancel = () => {
        handelClose(false);
    }

    const options = [
        {
            title: 'Single Payment',
            price: totalPrice,
            description: 'Full Invoice Payment will be taken at the checkout.',
            value: 1,
        },
        {
            title: '2 Payments',
            price: parseFloat((totalPrice / 2).toFixed(2)),
            description: 'Payment will be split into two equal Payments. One payment will be accepted now another 2nd will be scheduled.',
            value: 2,
        },
        {
            title: '3 Payments',
            price: parseFloat((totalPrice / 3).toFixed(2)),
            description: 'Payment will be split into three equal Payments. One payment will be accepted now another other will be scheduled.',
            value: 3,
        },
    ];

    const addNewpayment = async () => {
        setLoading(true);
        try {
            await createPayment({
                event: eventId,
                status: "sched_installment",
                payment_expected: totalPrice,
                no_of_installments: selected,
            });
            fetchAllPayments();
            handleCancel();
        } catch (err) {
            console.error('Error', err);
        } finally {
            setLoading(false);
        }
    }


    const renderdButtons = () => {
        return [
            <Button size={'large'} key="cancel" onClick={handleCancel}>
                Cancel
            </Button>,
            <Button
                loading={loading}
                onClick={addNewpayment}
                size={'large'}
                key="create"
                type="primary"
                disabled={!selected}
            >
                Create
            </Button>
        ]
    }

    return (
        <Modal
            className={styles.createModal}
            centered
            title="Select Payment Plan"
            open={open}
            onCancel={handleCancel}
            footer={renderdButtons()}
        >
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
                {options.map((item, i) => (
                    <Card
                        key={i}
                        title={item.title}
                        extra={selected === item.value ? <Checked /> : <Uncheck />}
                        className={`payment-modal-card ${selected === item.value && 'active'}`}
                        style={{
                            width: '100%',
                            cursor: 'pointer'
                        }}
                        onClick={() => setSelected(item.value)}
                    >
                        <h4>${item.price}</h4>
                        <p>{item.description}</p>
                    </Card>
                ))}
            </Space>
        </Modal>
    )
}

export default AddPayments
