import React, { useState, useEffect } from 'react';
import { Typography, Button, Card, Avatar, Divider, Spin, Alert, Dropdown, Menu, Modal, message, Popconfirm } from 'antd';
import './Team.css';
import { UserOutlined, EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { toast } from 'react-hot-toast';
import axios from '../../../../network/axios'
import myApi from '../../../../network/axios';
import EditAddTeamModal from './EditAddTeamModal';
import confirmicon from '../Assets/confirmicon.png'
import AddTeamModal from './AddTeamModal';
// toast.configure();
const Team = () => {

  const { Title, Paragraph } = Typography;
  const [openEditForm, setOpenEditForm] = useState(false);
  const [openAddForm, setOpenAddForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableRow, setTableRowData] = useState([]);
  const [editRecord, setEditRecord] = useState(null);
  const [count, setCount] = useState(null);
  const [activeCount, setActiveCount] = useState(0);
  const [inActiveCount, setInActiveCount] = useState(0);
  const [limit, setLimit] = useState(null);
  const [modal1Visible, setModal1Visible] = useState(false);
  const [isSomethingWrongActive, setIsSomethingWrongActive] = useState(false);
  const [isSomethingWrongInActive, setIsSomethingWrongInActive] = useState(false);
  const [suspendModal, setModalSuspend] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [confirmationText, setConfirmationText] = useState("");
  const [titleModal, setTitleModal] = useState("");
  const [modalHeaderColor, setModalHeaderColor] = useState("");

  const [statusFilter, setStatusFilter] = useState('Active');

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [openEditModal, setOpenEditModal] = useState(false)
  const [userDetails, setUserDetails] = useState({})

  const editModal = (user) => {
    setOpenEditModal((prev) => !prev);
    setUserDetails(user)
  }

  const modalHideShow = () => {
    setIsModalVisible((prev) => !prev)
  };

  const showAlert = () => {
    return (
      <Alert message="This  member's account is Deleted" type="success" />
    )
  }

  // delete user
  async function handleDelete(user) {
    // Display a confirmation modal
    Modal.confirm({
      width: "350px",
      title: `Delete ${user?.created_user}`,
      content: "Are you sure? This action is permanent and cannot be undone.",
      okText: 'Delete',
      icon: <img src={confirmicon} alt="Confirmation Icon" style={{ width: '40px', marginRight: '15px' }} />, 
      okType: 'danger',
      cancelText: 'Cancel',
      okButtonProps: {
        style: {
          background: '#DC3545',
          color: 'white',
          fontWeight: "bold"
        },
      },
      cancelButtonProps: {
        style: {
          background: 'white', // Set the background color for the Cancel button to white
          color: '#332D2D',
          fontWeight: "bold"
        },
      },
      onOk: async () => {
        try {
          await myApi.delete(`${process.env.REACT_APP_BASE_API_URL}/api/auth/user/${user.id}/`);
          setTitleModal("Delete Account");
          setModalHeaderColor("Red");
          showAlert();
          setModalSuspend(true);
          setTimeout(() => {
            setModalSuspend(false);
          }, 5000);
          setIsSuccess(true);
          // Update the UI or show a success message as needed
          toast.success('User deleted successfully!');
          getUsers();  // Refresh the user list
        } catch (error) {
          // Handle error, show error message or log the error
          setLoading(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message === "Logged-in user can't be deleted"
          ) {
            toast.error("Logged-in user can't be deleted");
          } else {
            toast.error('Error deleting user. Please try again.');
          }
          setModalSuspend(false);
          setIsSuccess(false);
        }
      },
    });
  }
  
  // suspend user
  const handleSuspend = async (values, helpers) => {
    await myApi
      .put(`${process.env.REACT_APP_BASE_API_URL}/api/auth/user/${values}/`, {
        status: "suspended",
      })
      .then((result) => {
        //toast.success(result.data.message);
        setTitleModal("Suspend Account");
        setConfirmationText("This  member's account is Suspended");
        setModalHeaderColor("Red");
        setModalSuspend(true);
        setIsSuccess(true);
        getUsers();
        toast.success("Account Deactivated  successfully!");
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong!!Try again in a moment");
        setModalSuspend(false);
        setIsSuccess(false);
      });
  };

  // reactivate the user that has been deleted
  // const handleReactivate = async (user) => {
  //   try {
  //     let formData = new FormData();
  //     formData.append("username", user.username);
  //     const result = await myApi.post(`${process.env.REACT_APP_BASE_API_URL}/api/auth/password_reset/request/`, formData);
  //     setModalHeaderColor("Green");
  //     setTitleModal("Active Account");
  //     setConfirmationText(`An email is sent to ${user.username}. Follow the instructions to re-activate the account!`);
  //     setModalSuspend(true);
  //     setIsSuccess(true);
  //     getUsers();
  //   } catch (error) {
  //     console.error('Error in handleReactivate:', error);

  //     if (error.response && error.response.data && error.response.data.message && error.response.data.message.errors) {
  //       const errorMessage = error.response.data.message.errors[0];
  //       if (errorMessage.includes('Account is deactivated')) {
  //         toast.error('Account is deactivated. Contact the PlanSpace support team via email support@theplanspace.io.');
  //       } else {
  //         // Handle other errors if needed
  //         toast.error(errorMessage);
  //       }
  //     } else {
  //       // Handle unexpected errors
  //       toast.error('An unexpected error occurred.');
  //     }
  //   }
  // };

  // reset the user status
  const handleResetPass = async (user) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("username", user.username);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_API_URL}/api/auth/password_reset/request/`,
        formData
      )
      .then((response) => {
        setLoading(false);
        setTitleModal("Reset Password");
        setModalHeaderColor("Yellow");
        setConfirmationText(
          "Password reset instructions are sent in your registered email"
        );
        toast.success("Password reset instructions sent successfully!");
        setModalSuspend(true);
        setTimeout(() => {
          setModalSuspend(false);
        }, 5000);
        setIsSuccess(true);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong!!Try again in a moment");
        setModal1Visible(false);
        setIsSuccess(false);
      });
  };

  // update the user information
  async function updateUser(status, uid) {
    try {
      // Send a PUT request to update the user's status
      await myApi.put(`${process.env.REACT_APP_BASE_API_URL}/api/auth/user/${uid}/`, {
        status: status,
      });

      // Show a success toast message
      toast.success(`User ${status === 'active' ? 'activated' : 'deactivated'} successfully`);

      // Update the user list after the status update
      getUsers();
    } catch (error) {
      console.error('Error in updateUser:', error);

      // Handle errors if needed
      toast.error('An error occurred while updating the user status.');
    }
  }


  const makeAToast = (message) => {
    toast.success(message);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      getUsers();
      setLoading(false);
    }, 1000);
  }, [isActive]);

  // get all users
  const getUsers = async (page, pageSize) => {
    try {
      let storedData = localStorage.getItem('userData');

      if (storedData) {
        // If data is available in local storage, use it to set the initial state
        storedData = JSON.parse(storedData);

        setTableRowData(storedData);
        setCount(storedData.length);
        setActiveCount(storedData.filter((user) => user.status === "active").length);
        setInActiveCount(storedData.filter((user) => ["new", "suspended", "Blocked"].includes(user.status)).length);

        // Assume that limit is not available in stored data, you might need to handle this accordingly
        setLimit(0);

        setLoading(false);
      } else {
        // If data is not available in local storage, perform the API call
        const status = isActive ? 'active' : 'inactive';
        const url = `${process.env.REACT_APP_BASE_API_URL}/api/auth/user/?status=${status}${page ? `&page=${page}` : ''}&page_size=50`;

        setLoading(true);

        const result = await myApi.get(url);
        // Update state with the received data
        setTableRowData(result.data.results);
        setCount(result.data.count);
        setActiveCount(result.data.results.filter((user) => user.status === 'active').length);
        setInActiveCount(result.data.results.filter((user) => ['new', 'suspended', 'Blocked'].includes(user.status)).length);
        setLimit(result.data.limit);



        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching user data:', error);
      // alert(error?.data?.message);
    }
  };


  // card drop down  ellipsis button :::
  const menu = (user) => (
    <Menu>
      <Menu.Item key="Edit" onClick={() => editModal(user)}>Edit</Menu.Item>
      {user.status === "suspended" && (
        <Menu.Item key="Reactivate" onClick={() => updateUser("active", user?.id)}>
          Reactivate
        </Menu.Item>
      )}

      {user.status === "active" && (
        <Menu.Item key="Reset" onClick={() => handleResetPass(user)}>
          Reset password
        </Menu.Item>
      )}
      {user.status == "active" && !user.is_owner && (
        <Menu.Item key="Deactivate" onClick={() => handleSuspend(user?.id)}>Deactivate</Menu.Item>
      )}
      {!user.is_owner && (
        <Menu.Item key="Delete" onClick={() => handleDelete(user)}>Delete</Menu.Item>
      )}
    </Menu>

  );


  const getFullName = (user) => {
    // concatenate first name middle name and last name if they exist
    let name = "";
    if (user?.first_name) {
      name += user?.first_name;
    }
    if (user?.middle_name) {
      name += ` ${user?.middle_name}`;
    }
    if (user?.last_name) {
      name += ` ${user?.last_name}`;
    }
    return name;
  }

  return (
    <div className='Team-container'>
      {loading ? (
        <div className="center-spinner">
          <Spin />
        </div>
      ) : (
        <>
          <div>
            <div className="team-info">
              <div>
                <div className={'filter-switch'}>
                  <Button
                    className={`button ${isActive ? 'active' : ''}`}
                    type="text"
                    onClick={() => setIsActive(true)}
                  >
                    Active ({activeCount})
                  </Button>
                  <Button
                    className={`button ${!isActive ? 'active' : ''}`}
                    type="text"
                    onClick={() => setIsActive(false)}
                  >
                    InActive ({inActiveCount})
                  </Button>
                </div>

              </div>
              <Button className='Add-btn' onClick={modalHideShow}>
                + Add Team
              </Button>
            </div>
          </div>

          <div className='Team-Cards'>
            {tableRow
              .filter(user => (isActive ? user.status === "active" : ["new", "suspended", "blocked"].includes(user.status)))
              .map((member, index) => (
                // style={{ width: "280px", height: "300px" }}
                <Card key={index} style={{ width: "285px", height: "300px" }} >
                  <div className='Select-memeber'>
                    <div className='active-box' style={{ color: member.status === "new" || member.status === "suspended" || member.status === "blocked" ? "#E53935" : "#50C878", background: member.status === "new" || member.status === "suspended" || member.status === "blocked" ? "#FFCDD2" : "#E8F5E9" }}>
                      {
                        member.status === "suspended" ? "DEACTIVATED" : member.status === "new" || member.status === "blocked" ? "PENDING" : "ACTIVE"
                      }

                    </div>
                    <Dropdown overlay={() => menu(member)} trigger={['click']}>
                      <EllipsisOutlined className='Circle-btn' />
                    </Dropdown>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ textAlign: "center" }}>
                        <Avatar className='Avatar' size={64} icon={<UserOutlined />} style={{ marginBottom: '10px' }} />
                        <h2 className='member-name'>
                          {
                            getFullName(member)
                          }
                        </h2>
                      </div>
                      <Divider dashed style={{ width: '80%' }} />
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: "25px" }}>
                        <div className='member-role'>
                          <p>Role</p>
                        </div>
                        <div className='role'>{member?.is_owner ? "Owner" : "Member"}</div>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: "25px" }}>
                        <div className='member-email'>
                          Email
                        </div>
                        <div className='email'>
                          {member?.username.length > 15
                            ? `${member?.username.substring(0, 20)}...`
                            : member?.username}
                        </div>

                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: "25px" }}>
                        <div className='member-phone'>
                          Phone
                        </div>
                        <div className='phone'>{member?.mobile}</div>
                      </div>
                    </div>
                  </div>
                </Card>
              ))}

            <EditAddTeamModal
              getUsers={getUsers}
              editModal={editModal}
              openEditModal={openEditModal}
              user={userDetails}
            />

            <AddTeamModal
              getUsers={getUsers}
              modalHideShow={modalHideShow}
              isModalVisible={isModalVisible}

            />
          </div>
        </>
      )}
    </div>
  );
}

export default Team;