import React, { useState, useRef } from 'react';
import { Cities } from 'countries-states-cities-service';
import { AutoComplete } from 'antd';
import './style.scss'

const CityAutoComplete = (props) => {
    const [cityDataSource, setCityDataSource] = useState([]);
    const searchQueryRef = useRef('');
    const usCities = Cities.getCities({
        filters: {
            country_code: 'US',
        },
    }) || [];

    const handleCitiesSearch = (value) => {
        const trimmedValue = value.trim();
        if (trimmedValue !== searchQueryRef.current) {
            searchQueryRef.current = trimmedValue;

            if (trimmedValue.length) {
                const filteredCities = usCities?.filter((city) => city?.name?.toLowerCase().startsWith(trimmedValue.toLowerCase()));

                // Use a Set to store unique city names
                const uniqueCityNames = new Set();
                const options = filteredCities.reduce((acc, city) => {
                    // Check if the city name is already in the Set
                    if (!uniqueCityNames.has(city.name)) {
                        uniqueCityNames.add(city.name); // Add the city name to the Set
                        acc.push({
                            label: city.name,
                            value: city.name
                        });
                    }
                    return acc;
                }, []);

                setCityDataSource(options);
            } else {
                setCityDataSource([]);
            }
        }
    };

    return (
        <AutoComplete
            {...props}
            size="large"
            options={cityDataSource}
            onSearch={handleCitiesSearch}
            allowClear
            placeholder="Select city name"
        />
    )
}

export default CityAutoComplete
