import React from 'react';
import { Typography } from 'antd';

import styles from '../events.module.scss';

const RenderdDetails = ({ packages, services, selectedPkgId, selectedServicesIds }) => {
    const { Text } = Typography;

    const selectedPkg = packages?.find(pkg => pkg.id === selectedPkgId);
    const selectedServices = services?.filter(service => selectedServicesIds?.includes(service.id));

    const details = [
        {
            label: 'Package',
            have: Boolean(selectedPkg),
            title: selectedPkg?.name,
            subTitle: selectedPkg?.description,
            amount: selectedPkg?.total_price ? '$' + selectedPkg?.total_price : '$0.00'
        },
        {
            label: 'Services',
            have: Boolean(selectedServices?.length),
            list: selectedServices?.length && selectedServices.map(service => ({
                title: service?.name,
                subTitle: service?.description,
                amount: service?.total_price ? '$' + service?.total_price : '$0.00'
            }))
        }
    ];

    const totalPrice = () => {
        const pkg = selectedPkg?.total_price ? selectedPkg?.total_price : 0;
        const service = selectedServices?.map(item => item?.total_price)?.reduce((a, b) => a + b, 0);
        return pkg + service;
    }

    return (
        <div className={styles.viewWrapper}>
            {details.map((item, i) => item?.have ? (
                <div key={i} className={styles.viewCard}>
                    <label>{item.label}</label>
                    {item?.list ? item?.list.map((serv, i) => (
                        <div key={i} className={styles.viewCardbody}>
                            <div className={styles.viewCardInner}>
                                <div className={styles.labelWrapper}>
                                    <Text style={{ fontWeight: 500, fontSize: 16 }}>
                                        {serv.title}
                                    </Text>
                                    <Text type="secondary" style={{ fontSize: 14 }}>
                                        {serv.subTitle}
                                    </Text>
                                </div>
                                <Text style={{ fontWeight: 500, fontSize: 16, width: '40%', textAlign: 'right' }}>
                                    {serv.amount}
                                </Text>
                            </div>
                        </div>
                    )) : (
                        <div className={styles.viewCardbody}>
                            <div className={styles.viewCardInner}>
                                <div className={styles.labelWrapper}>
                                    <Text style={{ fontWeight: 500, fontSize: 16 }}>
                                        {item.title}
                                    </Text>
                                    <Text type="secondary" style={{ fontSize: 14 }}>
                                        {item.subTitle}
                                    </Text>
                                </div>
                                <Text style={{ fontWeight: 500, fontSize: 16, width: '40%', textAlign: 'right' }}>
                                    {item.amount}
                                </Text>
                            </div>
                        </div>
                    )}
                </div>
            ) : null)}

            <div className={styles.viewCard}>
                <div className={styles.viewCardbody}>
                    <div className={styles.viewCardInner}>
                        <div className={styles.labelWrapper}>
                            <Text style={{ fontWeight: 500, fontSize: 16, color: '#0499CB' }}>
                                Total Cost
                            </Text>
                        </div>
                        <Text style={{ fontWeight: 700, fontSize: 16, width: '40%', textAlign: 'right' }}>
                            ${totalPrice()}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RenderdDetails
