import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Select } from 'antd';
import './FAQs.scss';

const FAQs = () => {
    const topics = [
        {
            name: 'Getting Started',
            items: [
                {
                    question: 'Can I add more than one venue in PlanSpace?',
                    answer: "PlanSpace does not limit the number of venues added."
                },
                {
                    question: 'What is a PlanSpace Application?',
                    answer: "It's a Venue Management System that helps venue owners run their daily operations including booking events, collecting payments, and managing tasks."
                },
                {
                    question: 'How do I get started?',
                    answer: 'Getting started is simple and easy! There is a checklist in the application you can follow, or you can create a Venue with a room and special packages to start booking events.'
                }
            ]
        },
        {
            name: 'Venue',
            items: [
                {
                    question: 'What is a room? And what if I don\'t have one?',
                    answer: 'A room is a space within the venue that will be booked for an event. In case you don\'t have a room, please add your venue name as the room name. For a walkthrough of the room feature, please see the video below.'
                }
            ]
        },
        {
            name: 'Events & Event Workspace',
            items: [
                {
                    question: 'How to create events?',
                    answer: 'Events can be created by clicking on the Add event button.'
                },
                {
                    question: 'Does PlanSpace provide catering booking?',
                    answer: 'This is not available yet, but we are always working on including new features.'
                }
            ],
        },
        {
            name: 'Website Booking',
            items: [
                {
                    question: 'Will PlanSpace create a booking website for venues?',
                    answer: 'We can absolutely create a booking site for your venue. Please contact us.'
                },
                {
                    question: 'Will PlanSpace host the website for venues?',
                    answer: 'Of course, PlanSpace can host the booking site. You can also decide to host with another service provider.'
                },
                {
                    question: 'Can my guests book events from my website?',
                    answer: 'Yes, PlanSpace app can create a code that your web developer can add to your website. If you decide to build your site with PlanSpace, we will seamlessly integrate the booking page with your website.'
                },
                {
                    question: 'Can PlanSpace help us with a website for a venue?',
                    answer: 'Of course, PlanSpace can help with website design, creation, and integration with the PlanSpace App. Please contact our sales team (need email) for more details.'
                }
            ]
        },
        {
            name: 'Payment',
            items: [
                {
                    question: 'Can I collect payments from my guests?',
                    answer: 'Yes, you can collect payments from credit/debit card or cash. We are working on adding more ways for you to collect payment from your guests.'
                },
                {
                    question: 'Can I create an invoice?',
                    answer: 'Yes, invoices can be created from within the payment processor. We are working on an independent module for flexibility.'
                },
                {
                    question: 'Can I email invoices to guests from the application?',
                    answer: 'At this time, PlanSpace can\'t generate invoices, but we are working on this feature. However, Payment processors can create invoices.'
                }
            ]
        },
        {
            name: 'Contracts',
            items: [
                {
                    question: 'Can I create a Contract?',
                    answer: 'Yes, we provide the ability to create a contract for each event. You can also save contracts as templates for future events. For a walkthrough of the contract feature, please see the video below.'
                }
            ]
        }
    ];
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [sortBy, setSortBy] = useState('most-relevant');

    useEffect(() => {
        
        setSelectedTopic(0);
    }, []);
    const handleTopicClick = (index) => {
        setSelectedTopic(index);
    };
    const handleSort = (value) => {
        setSortBy(value);
    };
    const sortingOptions = [
        { value: 'most-relevant', label: 'Most Relevant' },
        { value: 'alphabetical', label: 'Alphabetical' },
    ];

    return (
        <div className="container">
            <div className='FAQs-heading'>
                <p>FAQs</p>
            </div>
            <Row gutter={[16, 16]} className="FAQs-row">
                <Col span={8} className='topics-part'>
                    {topics.map((topic, index) => (
                        <Card
                            key={index}
                            style={{
                                width: '100%',
                                cursor: 'pointer',
                                color: selectedTopic === index ? '#0499CB' : 'inherit',
                                fontWeight: selectedTopic === index ? 'bold' : 'normal',
                            }}
                            onClick={() => handleTopicClick(index)}
                        >
                            {topic.name}
                        </Card>
                    ))}
                </Col>
                <Col span={14} className='questions-part'>
                    {selectedTopic !== null ? (
                        topics[selectedTopic].items.map((item, index) => (
                            <Card key={index} style={{ width: '100%' }} className='questions-cards'>
                                <p className='question'>{item.question}</p>
                                <p className='answer'>  {item.answer}</p>
                            </Card>
                        ))
                    ) : (
                        <p>Select a topic to see its questions and answers.</p>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default FAQs;
