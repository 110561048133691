import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-hot-toast";
// UI's
import {
  Typography,
  Card,
  Radio,
  Empty,
  Spin
} from "antd";
import { getVenues } from "../../../services/venues";
import templateImage from "../../../assets/background.jpg";

// CSS
import "../integrationStyles.scss";
const Venues = ({ error, setErrors, venue, setVenue }) => {
  const { Title, Text } = Typography;
  const scrollerRef = useRef(null);
  const [venues, setVenues] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchVenues = async () => {
    setLoading(true);
    try {
      const res = await getVenues({ page: page });
      if (page > 1) {
        setVenues(prev => [...prev, ...res.data.results]);
      } else {
        setVenues(res.data.results);
      }
      setHasMore(res.data.next);
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = '/server-error';
        console.log("Redirecting to server error page...");
      } else {
        toast.error(error?.response?.data.message);
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchVenues();
  }, [page]);

  useEffect(() => {
    const scrollContainer = scrollerRef.current;

    const handleScroll = () => {
      const totalWidth = scrollContainer.scrollWidth - scrollContainer.clientWidth;
      const currentScrollPosition = scrollContainer.scrollLeft;
      if (currentScrollPosition === totalWidth && hasMore) {
        setPage(prev => prev + 1);
      }
    };

    scrollContainer.addEventListener('scroll', handleScroll);

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, [scrollerRef, hasMore]);

  return (
    // <Card style={{ borderColor: error && '#ff4d4f' }}>
    //     <Title level={4}>Select Venue</Title>
    //     <Spin spinning={loading}>
    //         <div ref={scrollerRef} className="cards-wrapper">
    //             <Radio.Group
    //                 style={{ display: 'flex', gap: 24, alignItems: 'center' }}
    //                 onChange={(e) => {
    //                     const selected = venues?.find(item => item.id === e.target.value);
    //                     setVenue(selected);
    //                     setErrors(prev => ({ ...prev, venue: '' }));
    //                 }}
    //                 value={venue?.id}
    //             >
    //                 {venues?.length ? venues.map((item, i) => (
    //                     <Card className={item.id === venue?.id && 'seletced'} key={i} style={{ minWidth: 310, maxWidth: 310 }}>
    //                         <div>
    //                             <img src={item?.images?.length ? item?.images[0]?.image : templateImage} class="img-fluid venue-image" alt="" />
    //                         </div>
    //                         <p style={{ fontWeight: 700 }}>{item?.name}</p>
    //                         <p style={{ fontSize: "12px", marginBottom: '20px' }}>
    //                             {item?.address}
    //                         </p>
    //                         <div className="border-bottom" />

    //                         <Radio
    //                             style={{ marginTop: "10px", marginLeft: 'auto' }}
    //                             size="large"
    //                             value={item.id}
    //                         />
    //                     </Card>
    //                 )) : !loading ? (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Venues" />) : null}
    //             </Radio.Group>
    //         </div>
    //     </Spin>
    //     {error ? (<Text style={{ marginTop: 24, display: 'block' }} type="danger">{error}</Text>) : null}
    // </Card>

    <Card style={{ borderColor: error && "#ff4d4f" }}>
      <Title level={4}>Select Venue</Title>
      <Spin spinning={loading}>
        <div ref={scrollerRef} className="cards-wrapper"
          style={{ display: 'flex', gap: 24, alignItems: 'center', cursor: "pointer" }}>
          {venues?.length ? (
            venues.map((item, i) => (
              <div
                key={i}
                onClick={() => {
                  setVenue(item);
                  setErrors((prev) => ({ ...prev, venue: "" }));
                }}
              >
                <Card
                  className={item.id === venue?.id ? "seletced" : ""}
                  // style={{ minWidth: 310, maxWidth: 310 }}
                  style={{ width: "310px", height: "270px" }}
                >
                  <div>
                    <img
                      src={
                        item?.images?.length
                          ? item?.images[0]?.image
                          : templateImage
                      }
                      class="img-fluid venue-image"
                      alt=""
                    />
                  </div>
                  <p style={{ fontWeight: 700 }}>{item?.name}</p>
                  <p style={{ fontSize: "12px", marginBottom: "20px" }}>
                    {item?.address}
                  </p>
                  <div className="border-bottom" />
                  {/* <Radio
                      style={{ marginTop: "10px", marginLeft: "auto" }}
                      size="large"
                      value={item.id}
                      
                    /> */}
                </Card>
              </div>
            ))
          ) : !loading ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Venues"
            />
          ) : null}
        </div>
      </Spin>
      {error ? (
        <Text style={{ marginTop: 24, display: "block" }} type="danger">
          {error}
        </Text>
      ) : null}
    </Card>
  )
}

export default Venues
