import React from "react";
import InputMask from "react-input-mask";

export default function PhoneMask(props) {
  return (
    <InputMask
      mask="(999) 999-9999"
      maskChar=""
      onChange={props.onChange}
      placeholder="(123) 456-7890"
      style={{
        width: "100%",
      }}
      className="ant-input"
      value={props.value}
    />
  );
}
