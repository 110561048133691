import React, { useState } from 'react';
import dayjs from 'dayjs';
import usePopup from '../../../../../common/MessageModal/usePopup';

// UI's
import { Button, Modal } from 'antd';
import { Form, Input, DatePicker, TimePicker } from 'antd';
import { addNewAppointment } from '../../../../../services/events';
import styles from '../../../events.module.scss';

const AddNewAppointment = ({ open, handelClose, eventId, refetch }) => {
    const popup = usePopup();
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [loading, setLoading] = useState(false);

    const renderdButtons = () => {
        return [
            <Button size={'large'} key="cancel" onClick={handelClose}>
                Cancel
            </Button>,
            <Button loading={loading} onClick={() => form.submit()} size={'large'} type="primary">
                Create Appointment
            </Button>
        ]
    }

    const disabledPastDate = (current) => {
        return (
            current < dayjs().startOf('day')
        );
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            const res = await addNewAppointment({
                ...values,
                event: eventId,
                "appt_start_date": dayjs(values?.appt_start_date).format('YYYY-MM-DD'),
                "appt_start_time": dayjs(values?.appt_start_time).format('hh:mm:ss'),
                "appt_end_time": dayjs(values?.appt_end_time).format('hh:mm:ss'),
            });
            popup.success('Success!', res?.data?.message);
            refetch();
            form.resetFields();
            handelClose();
        } catch (error) {
            popup.error('Oops!', error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal
            className={styles.createModal}
            centered
            title="Create New Appointment"
            open={open}
            onCancel={handelClose}
            footer={renderdButtons()}
        >
            <Form
                className={styles.eventForm}
                form={form}
                name="validateOnly"
                layout="vertical"
                autoComplete="off"
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="title"
                    label="Title*"
                    rules={[
                        {
                            required: true,
                            message: 'title is required!'
                        }
                    ]}
                >
                    <Input size="large" placeholder="Enter appointment title" />
                </Form.Item>
                <Form.Item
                    name="appt_start_date"
                    label="Date*"
                    defaultValue
                    rules={[
                        {
                            required: true,
                            message: 'Date is required!'
                        },
                    ]}
                >
                    <DatePicker
                        disabledDate={disabledPastDate}
                        popupClassName={styles.customDatePicker}
                        size='large'
                    />
                </Form.Item>
                <Form.Item
                    name="appt_start_time"
                    label="Start time*"
                    defaultValue
                    rules={[
                        {
                            required: true,
                            message: 'Start time is required!'
                        },
                    ]}
                >
                    <TimePicker
                        size="large"
                        showNow={false}
                        style={{
                            width: '100%',
                        }}
                        format="hh:mm A"
                    />
                </Form.Item>
                <Form.Item
                    name="appt_end_time"
                    label="End time*"
                    defaultValue
                    rules={[
                        {
                            validator: (rule, value, callback) => {
                                const startTime = form.getFieldValue('appt_start_time');
                                if (dayjs(value).isAfter(dayjs(startTime).add(30, 'minute'))) {
                                    callback();
                                } else if (!value) {
                                    callback('Event end time is required!');
                                } else {
                                    callback('End time must be at least 30 minutes ahead!');
                                }
                            }
                        }
                    ]}
                >
                    <TimePicker
                        size="large"
                        showNow={false}
                        style={{
                            width: '100%',
                        }}
                        format="hh:mm A"
                    />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description*"
                    rules={[
                        {
                            required: true,
                            message: 'Description is required!'
                        },
                    ]}
                >
                    <TextArea
                        autoSize={{ minRows: 5 }}
                        placeholder="Appointment description..."
                        size="large"
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddNewAppointment
