export const payment_dates = [
    {
        value: 1,
        label: "1 Day Before",
    },
    {
        value: 2,
        label: "2 Days Before",
    },
    {
        value: 3,
        label: "3 Days Before",
    },
    {
        value: 4,
        label: "4 Days Before",
    },
    {
        value: 5,
        label: "5 Days Before",
    },
    {
        value: 10,
        label: "10 Days Before",
    },
    {
        value: 15,
        label: "15 Days Before",
    },
    {
        value: 20,
        label: "20 Days Before",
    },
    {
        value: 25,
        label: "25 Days Before",
    },
];