import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button, Card, Row, Col, Spin, Typography, Avatar, Divider } from 'antd';
import { EnvironmentFilled, CaretRightFilled, DeleteFilled } from '@ant-design/icons';

import Loader from '../../../common/Loader';
import { getRoom, deleteRoom } from '../../../services/rooms';

import RoomForm from '../comps/forms/RoomForm';
import Packages from './Packages';
import Services from './Services';
import usePopup from '../../../common/MessageModal/usePopup';

import { ReactComponent as EditIcon } from '../../../assets/icons/fill-pencil.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as DollarIcon } from '../../../assets/icons/dollar-dark.svg';
import { ReactComponent as UsersIcon } from '../../../assets/icons/users.svg';
import bgImage from '../../../assets/background.jpg';

import Layout from '../Layout';

import '../styles/venue-details.scss';

const RoomDetails = () => {
    const popup = usePopup();
    const navigate = useNavigate();
    const { id, venue_id } = useParams();
    const { Title, Text } = Typography;
    const [room, setRoom] = useState(null);
    const [edit, setEdit] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activeFilter, setActiveFilter] = useState('packages');

    const fetchRoom = async () => {
        setLoading(true);
        try {
            const res = await getRoom(id);
            setRoom(res?.data);
        } catch (error) {
            const errorCode = error?.response?.status || 500;
            if (errorCode === 500 || errorCode === 503) {
                window.location.href = '/server-error';
                console.log("Redirecting to server error page...");
            } else {
                popup.error("Oops...", error?.response?.data?.message);
                if (venue_id) {
                    navigate(`/company/venues/${venue_id}`);
                } else {
                    navigate('/company/venues');
                }
            }
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchRoom();
    }, [id]);

    const details = [
        {
            title: 'Price',
            value: room?.base_price,
            icon: <DollarIcon />
        },
        {
            title: 'Max Guests',
            value: room?.max_guests,
            icon: <UsersIcon />
        },
        {
            title: 'Address',
            value: room?.location,
            icon: <EnvironmentFilled />
        },
    ];

    const deleteTheRoom = async () => {
        try {
            const res = await deleteRoom(room?.id);
            popup.success("Success!", res?.data?.message);
            if (venue_id) {
                navigate(`/company/venues/${venue_id}`);
            } else {
                navigate('/company/venues');
            }
        } catch (error) {
            popup.error("Oops...", error?.response?.data?.message);
        }
    }

    return (
        <Layout
            title={'Room Details'}
            addButton={<div style={{ display: 'flex', gap: 12 }}>
                {room?.id ? (<>
                    <Button
                        size='large'
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        icon={<DeleteIcon />}
                        onClick={() => popup.confirm(room?.name, 'Are you sure you want to delete this room?', deleteTheRoom)}
                    />
                    <Button
                        type='primary'
                        size='large'
                        icon={<EditIcon />}
                        onClick={() => setEdit(true)}
                    >
                        Edit
                    </Button>
                </>) : null}
            </div>
            }
            items={[
                { title: 'Company Settings' },
                { title: <Link to={'/company/venues'}>Venues</Link> },
                { title: <Link to={`/company/venues/${venue_id}`}>Venue Details</Link> },
                { title: <Link to={`/company/venues/${venue_id}`}>Room Details</Link> },
            ]}
        >
            {!room && loading ? (
                <Loader minHeight={400} />
            ) : (
                <Spin spinning={loading}>
                    <Card className="sub-section-card">
                        <Row gutter={[36, 36]}>
                            <Col span={24} xs={24} xl={10}>
                                {room?.images?.length ? (
                                    <Row gutter={[12, 12]}>
                                        {room?.images.map((img, i) => (
                                            <Col key={i} span={24} xs={!i ? 24 : 12}>
                                                <div className="venue-image">
                                                    <img src={img?.image} alt="venue" />
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                ) : (
                                    <Row gutter={[12, 12]}>
                                        <Col span={24} xs={24}>
                                            <div className="venue-image">
                                                <img src={bgImage} alt="venue" />
                                            </div>
                                        </Col>
                                        <Col span={24} md={12}>
                                            <div className="venue-image">
                                                <img src={bgImage} alt="venue" />
                                            </div>
                                        </Col>
                                        <Col span={24} md={12}>
                                            <div className="venue-image">
                                                <img src={bgImage} alt="venue" />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <Col span={24} xs={24} xl={14}>
                                <Title level={3}>{room?.name}</Title>
                                <Row style={{ marginTop: 42 }} gutter={[16, 24]}>
                                    {details?.map((item, i) => (
                                        <Col key={i} span={24} lg={12}>
                                            <div className='wrapper'>
                                                <Avatar icon={item?.icon} size={40} style={{ backgroundColor: '#F0F1F3' }} />
                                                <div>
                                                    <Title level={5} type='secondary'>{item?.title}</Title>
                                                    <Title level={5}>{item?.value || '-'}</Title>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                                <Divider />
                                <Row gutter={[16, 24]}>
                                    {room?.amenities?.length ? (
                                        <Col span={24} xs={12} xl={8}>
                                            <div className="list-wrapper">
                                                <Title level={5}>Offered Amenities</Title>
                                                {room?.amenities?.map((item, i) => (
                                                    <Text key={i} type="secondary">
                                                        <CaretRightFilled /> {item}
                                                    </Text>
                                                ))}
                                            </div>
                                        </Col>
                                    ) : null}
                                    {room?.ceremony_types?.length ? (
                                        <Col span={24} xs={12} xl={8}>
                                            <div className="list-wrapper">
                                                <Title level={5}>Event Types</Title>
                                                {room?.ceremony_types?.map((item, i) => (
                                                    <Text key={i} type="secondary">
                                                        <CaretRightFilled /> {item}
                                                    </Text>
                                                ))}
                                            </div>
                                        </Col>
                                    ) : null}
                                    {room?.spaces?.length ? (
                                        <Col span={24} xs={12} xl={8}>
                                            <div className="list-wrapper">
                                                <Title level={5}>Spaces Types</Title>
                                                {room?.spaces?.map((item, i) => (
                                                    <Text key={i} type="secondary">
                                                        <CaretRightFilled /> {item}
                                                    </Text>
                                                ))}
                                            </div>
                                        </Col>
                                    ) : null}
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    <div className={'filter-switch'}>
                        <Button
                            className={`button ${activeFilter === 'packages' && 'active'}`}
                            type="text"
                            onClick={() => setActiveFilter('packages')}
                        >
                            Packages
                        </Button>
                        <Button
                            className={`button ${activeFilter === 'services' && 'active'}`}
                            type="text"
                            onClick={() => setActiveFilter('services')}
                        >
                            Services
                        </Button>
                    </div>
                    {activeFilter === 'packages' ? (
                        <Packages room_id={room?.id} venue_id={venue_id} room={room} />
                    ) : null}
                    {activeFilter === 'services' ? (
                        <Services room_id={room?.id} venue_id={venue_id} />
                    ) : null}
                </Spin>
            )}

            <RoomForm
                fetchRooms={fetchRoom}
                open={edit}
                handelClose={() => setEdit(false)}
                room={{ ...room, type: 'edit' }}
            />
        </Layout>
    )
}

export default RoomDetails
