import React, { useState, useEffect } from 'react';
import { Upload, message, Button } from "antd";
import usePopup from '../../common/MessageModal/usePopup';

import { ReactComponent as ImageIcon } from "../../../src/assets/icons/image.svg";
import { ReactComponent as EditIcon } from "../../../src/assets/icons/fill-pencil.svg";
import { ReactComponent as DeleteIcon } from "../../../src/assets/icons/trash.svg";

import styles from './imageUploader.module.scss';

const ImageUploader = ({ input, output, smallSize }) => {
    const popup = usePopup();
    const [files, setFiles] = useState(null);

    useEffect(() => {
        setFiles(input);
    }, [input]);

    const truncateString = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.substring(0, maxLength) + "...";
        }
        return str;
    }

    const beforeUpload = (file) => {
        const isLtMaxSize = (file.size / 1024 / 1024) < 2;
        if (!isLtMaxSize) {
            message.error(`Can't upload "${truncateString(file.name, 30)}". File must be smaller than 2MB!`);
        }

        return isLtMaxSize;
    };

    const dummyRequest = async ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const props = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        accept: ".jpg, .jpeg, .png, .gif",
        fileList: files,
        showUploadList: false,
        beforeUpload,
        customRequest: dummyRequest,
        onChange(info) {
            if (info.file.status === 'uploading') {
                setFiles(info.fileList);
                output(info.fileList);
            } else if (info.file.status === 'done') {
                setFiles(info.fileList);
                output(info.fileList);
                message.success(`${truncateString(info.file.name, 30)} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${truncateString(info.file.name, 30)} file upload failed.`);
            } else if (info.file.status === 'removed') {
                output(info.fileList);
                setFiles(info.fileList);
                message.error(`${truncateString(info.file.name, 30)} file removed.`);
            }
        },
    };
    return (
        <div className={styles.imageUploader}>
            {files?.length ? files.map((file, i) => (
                <div key={i} className={styles["uploaded-image-wrapper"]}>
                    <div className={styles.actions}>
                        <Upload
                            {...props}
                            showUploadList={false}
                        >
                            <Button
                                type="primary"
                                size="large"
                                shape="circle"
                                icon={<EditIcon />}
                            />
                        </Upload>
                        <Button
                            type="primary"
                            size="large"
                            icon={<DeleteIcon />}
                            shape="circle"
                            danger
                            onClick={() => {
                                popup.confirm("Delete Image", "Are you sure? This action is permanent and cannot be undone.", () => {
                                    setFiles(null);
                                    output(null);
                                }, true);
                            }}
                        />
                    </div>
                    <img src={file?.originFileObj ? URL.createObjectURL(file.originFileObj) : file} alt={file.name} />
                </div>
            )) : (
                <Upload {...props} style={{ width: '100%' }}>
                    <div className={`${styles.addImage} ${styles.oneImage} ${smallSize && styles.small}`}>
                        <ImageIcon />
                        <p>Click to add a image</p>
                        <span>Add Image</span>
                    </div>
                </Upload>
            )}
        </div>
    )
}

export default ImageUploader
