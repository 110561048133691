import React from "react";
import { Row, Col, Form, Input, Button } from "antd";
import LoginHero from "../ForgetPasswrod/Assets/Login.png";
import logo from "../ForgetPasswrod/Assets/logo.png";
import "./forgotPassword.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Resend from "../RegisterComplete/ResendPassword";

const ForgetPasswrd = () => {
  const [loading, setLoading] = React.useState(false);
  const [showResend, setShowResend] = React.useState(false);
  const [email, setEmail] = React.useState();

  const navigate = useNavigate();

  const requestPasswordReset = async (values) => {
    setEmail(values?.email);
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_API_URL}/api/auth/password_reset/request/`,
        { username: values.email },
      )
      .then((response) => {
        toast.success("Request sent to request password");
        setShowResend(true);
      })
      .catch((error) => {
        let errorCode = error?.response?.status || 500;
        if (errorCode >= 500 && errorCode <= 504) {
          navigate("/server-error");
        } else {
          let errorMessage = error?.response?.data?.message;
          errorMessage = errorMessage?.errors
            ? errorMessage?.errors[0]
            : errorMessage?.username
              ? errorMessage?.username[0]
              : "Something went wrong";
          toast.error(errorMessage);
        }
      });
    setLoading(false);
  };

  return (
    <>
      {showResend ? (
        <Resend email={email} />
      ) : (
        <Row>
          <div className="forgot-container">
            <Col span={24} lg={11} className="forgot-image-column">
              <div className="forgot-image">
                <img src={LoginHero} alt="Login Image" />
              </div>
            </Col>
            <Col span={24} lg={13} className="forgot-column">
              <div className="forgot-form-container">
                <div>
                  <div className="forgot-logo">
                    <img src={logo} alt="" />
                  </div>
                  <p className="forgot-heading">Forgot Password</p>
                  <p className="forget-para">
                    Enter the email address you used when joined, and we’ll send
                    reset instructions to reset your password.
                  </p>
                </div>
                <br />
                <Form
                  name="forgot-form"
                  initialValues={{ remember: true }}
                  onFinish={requestPasswordReset}
                >
                  <label className="forget-password-lable" htmlFor="useName">
                    Email*
                  </label>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your email",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Your email"
                      className="forgot-form-item"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="forgot-button"
                      loading={loading}
                    >
                      <span className="button-text">
                        {loading ? null : "Send Reset Instructions"}
                      </span>
                    </Button>
                  </Form.Item>
                </Form>
                <p className="back-to-login-text">
                  Back to log in page?{" "}
                  <Link
                    to="/login"
                    style={{ color: "#0499CB", fontWeight: "bold" }}
                  >
                    Back now
                  </Link>
                </p>
              </div>
            </Col>
          </div>
        </Row>
      )}
    </>
  );
};

export default ForgetPasswrd;
