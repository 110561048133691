import React, { useRef, useState } from 'react';
import { Card, Typography, Button, Carousel, Dropdown, Divider, Switch } from 'antd';
import { MoreOutlined, DeleteFilled } from '@ant-design/icons';
import usePopup from '../../../common/MessageModal/usePopup';

import { ReactComponent as NextArrow } from '../../../assets/icons/arrow_circle_left.svg';
import { ReactComponent as PriceIcon } from '../../../assets/icons/dollar.svg';
import bgImage from '../../../assets/background.jpg';

import { deletePackage, updatePackage } from '../../../services/packages';

import '../styles/packages.scss';
import PackageForm from './forms/PackageForm';

const PackageCard = ({ data, fetchPackages, room_id, venue_id, status_switch }) => {
    const popup = usePopup();
    const { Title, Text } = Typography;
    const sliderRef = useRef(null);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const deleteThisPackage = async () => {
        try {
            const res = await deletePackage(data?.id);
            popup.success("Success!", res?.data?.message);
            fetchPackages();
        } catch (error) {
            popup.error("Oops...", error?.response?.data?.message);
        }
    }

    const items = [
        {
            label: <a onClick={() => setEdit(true)}><Text>Edit</Text></a>,
            key: 'edit',
        },
        {
            label: <a onClick={() => popup.confirm("Delete " + data?.name, "Are you sure? This action is permanent and cannot be undone.", deleteThisPackage)}><Text type='danger'>Delete</Text></a>,
            key: 'delete',
        },
    ];

    const formatTime = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        let formattedTime = '';

        if (hours > 0) {
            formattedTime += `${hours} hour${hours > 1 ? 's' : ''}`;
        }

        if (remainingMinutes > 0) {
            formattedTime += ` ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
        }
        return formattedTime;
    }

    const updatePackageStatus = async (payload) => {
        setLoading(true);
        try {
            await updatePackage(data?.id, payload);
            popup.success("Success!", 'Package status changed successfully.');
            fetchPackages();
        } catch (error) {
            const errorCode = error?.response?.status || 500; 
            if (errorCode === 500 || errorCode === 503) {
              window.location.href = '/server-error';
              console.log("Redirecting to server error page...");
            } 
            if (typeof error?.response?.data?.message === 'object') {
                popup.error("Oops...", error?.response?.data?.message[0]?.name[0]);
            } else if (typeof error?.response?.data?.message === 'array') {
                const errors = error?.response?.data?.message[0];
                Object.entries(errors).map(([field, message]) => {
                    popup.error("Oops...", message);
                });
            } else {
                popup.error("Oops...", error?.response?.data?.message);
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <Card className='package-card'>
            <div className="slider">
                <Carousel ref={sliderRef}>
                    {data?.images?.length ? data?.images?.map((item, i) => (
                        <img key={i} className='card-img' src={item?.image} alt="cover" />
                    )) : (
                        <img className='card-img' src={bgImage} alt="cover" />
                    )}
                </Carousel>
                {data?.images?.length > 1 ? (
                    <div className="controls">
                        <div className="btn back" onClick={() => sliderRef?.current?.prev()}>
                            <NextArrow />
                        </div>
                        <div className="btn" onClick={() => sliderRef?.current?.next()}>
                            <NextArrow />
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="header">
                <div>
                    <Title level={5}>{data?.name}</Title>
                    <Text type="secondary">
                        {data?.description}
                    </Text>
                </div>
                <Dropdown menu={{ items }} placement="bottomRight" trigger={['click']}>
                    <Button type="text" shape="circle" icon={<MoreOutlined />} />
                </Dropdown>
            </div>
            <div className="content">
                <div className="item">
                    <label>Venue</label>
                    <div className="value types">
                        {data?.location || '-'}
                    </div>
                </div>
                <div className="item">
                    <label>Package Duration</label>
                    <div className="value">{formatTime(data?.duration_minutes || 0)}</div>
                </div>
                <div className="item">
                    <label>Number of guests</label>
                    <div className="value">{data?.num_of_guests || '0'}</div>
                </div>
            </div>
            <Divider style={{ borderBlockStartStyle: 'dashed', marginBlock: 16 }} />
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <div className="price">
                    <PriceIcon />
                    <Text>{data?.total_price || '0'}</Text>
                </div>
                {status_switch ? (
                    <Switch
                        style={{ marginLeft: 'auto' }}
                        checked={data?.status === 'activated'}
                        loading={loading}
                        onChange={(checked) => {
                            if (checked) {
                                updatePackageStatus({ status: 'activated' });
                            } else {
                                updatePackageStatus({ status: 'deactivated' });
                            }
                        }}
                    />
                ) : null}
            </div>

            <PackageForm
                fetchPackages={fetchPackages}
                open={edit}
                room_id={room_id}
                venue_id={venue_id}
                handelClose={() => setEdit(false)}
                update_package={{ ...data, type: 'edit' }}
            />
        </Card>
    )
}

export default PackageCard
