import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import {
    Card,
    Spin,
    Empty
} from "antd";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { getChartData } from "../../services/dashboard";

import "../AdminDB.css";

const Chart = ({ filter, rangVal }) => {
    const location = useSelector((state) => state.venues.selectedVenue);
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState(null);

    const fetchChartData = async (params) => {
        setLoading(true);
        try {
            const res = await getChartData(params);
            // const data = updateDataForChart(res.data.data);
            if (filter === 'customDates') {
                setChartData(res.data.data.data);
            } else {
                setChartData(res.data.data);
            }
        } catch (err) {
            console.error("Chart: ", err?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (location?.id && filter !== 'customDates' && filter !== 'daily') {
            fetchChartData({ location: location?.id, type: filter });
        } else if (filter === 'customDates' && rangVal.length && location?.id) {
            fetchChartData({ location: location?.id, start_date: rangVal[0] || '', end_date: rangVal[1] || '' });
        } else {
            setChartData(null);
        }
    }, [filter, location, rangVal]);

    const giveAName = (key) => {
        if (filter === 'customDates') {
            return key + ' Year'
        } else {
            switch (chartData?.length) {
                case 12:
                    return key + ' Year'
                case 31:
                    return key + ' Month'
                case 7:
                    return key + ' Week'
                default:
                    return ''
            }
        }
    }

    return (
        <Card className="Dashboard-cards">
            <h5 style={{ fontSize: "20px" }}>Revenue Statistics</h5>
            <Spin spinning={loading}>
                <div style={{ marginTop: "1rem" }}>
                    {chartData?.length ? (
                        <ResponsiveContainer width={'100%'} height={300}>
                            <LineChart
                                data={chartData}
                                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                            >
                                <XAxis dataKey="type" />
                                <YAxis tickFormatter={(val) => ('$' + val)} />
                                <Tooltip separator=": $" />
                                <Legend />
                                <Line type="monotone" dataKey="current" stroke="#01339A" name={giveAName('This')} style={{ strokeWidth: "2.5px" }} />
                                <Line type="monotone" dataKey="last" stroke="#0499CB" name={giveAName('Last')} style={{ strokeWidth: "2.5px" }} />
                            </LineChart>
                        </ResponsiveContainer>
                    ) : (
                        <Empty />
                    )}
                </div>
            </Spin>
        </Card>
    )
}

export default Chart