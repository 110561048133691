import { Modal, Spin } from "antd";
import "./ConfirmationModal.scss";
// import { ReactComponent as EditIcon } from "../../../../assets/CustomModal/editIcon.svg";
import { ReactComponent as CrossIcon } from "../../../../assets/Modals/CrossIcon.svg";

const ConfirmationModal = ({
  open,
  setOpen,
  content,
  onConfirm,
  isLoading,
}) => {
  return (
    <>
      <Modal
        title={null}
        centered
        open={open}
        onOk={() => setOpen(false)}
        footer={null}
        className="ConfirmationModal"
        closable={false}
        onCancel={() => setOpen(false)}
      >
        <div className="header">
          <div className="header-left"></div>
          <div className="header-center">Are you sure?</div>
          <div className="header-right">
            <CrossIcon
              onClick={() => {
                setOpen(false);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <Spin spinning={isLoading} delay={500}>
          <div className="content">
            <p
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            ></p>
          </div>
        </Spin>
        <div className="footer">
          <div className="footer-left"></div>
          <div className="footer-center"></div>
          <div className="footer-right">
            <div className="close-btn">
              <button
                type="button"
                className="btn"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
              <button type="button" className="btn-confirm" onClick={onConfirm}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ConfirmationModal;
