import React, { useState, useEffect } from "react";
import { Typography, Card, Divider, Checkbox } from "antd";
import TermsModal from "./TermsModal";
import moment from "moment";

const TransactionDetail = ({
  dateDuration,
  paymentTerms,
  paymentInfo,
  details,
  sebmitButton,
  selectedPackage,
  services,
}) => {
  const { Title } = Typography;
  const [totalOfServies, setTotalOfServies] = useState(0);
  const [termModal, setTermModal] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isTermsNotOpenedModal, setIsTermsNotOpenedModal] = useState(false);
  const [isTermsOpened, setIsTermsOpened] = useState(false);

  useEffect(() => {
    const total = services.reduce(
      (accumulator, item) => accumulator + (item?.total_price || 0),
      0
    );
    setTotalOfServies(total);
  }, [services]);

  return (
    <>
      <Card className="booking-card">
        <Title type="secondary" level={4}>
          Transaction Detail
        </Title>
        <Divider />
        <div className="detail-list">
          <label>Product List</label>
          <div className="list-wrapper">
            {selectedPackage ? (
              <div className="list">
                {selectedPackage?.name} <b>${selectedPackage?.total_price}</b>
              </div>
            ) : null}
          </div>
        </div>
        <Divider />
        <div className="detail-list">
          <label>Total</label>
          <div className="list-wrapper">
            <div className="list" style={{ justifyContent: "flex-end" }}>
              <b>${totalOfServies + (selectedPackage?.total_price || 0)}</b>
            </div>
          </div>
        </div>
        <Divider />
        {paymentInfo?.is_partial_allowed ? (
          <>
            <div className="detail-list">
              <label>Installment Payments</label>
              <div className="list-wrapper">
                {/* {paymentTerms?.map((item, index) => (
                  <div key={index} className="list">
                    Due on {moment(dateDuration?.event_date, "YYYY-MM-DD").subtract(item?.payment_day, "days").format("MMM DD, YYYY")} <b>${parseFloat(((totalOfServies + (selectedPackage?.total_price || 0)) / paymentTerms?.length).toFixed(2))}</b>
                  </div>
                ))} */}

                {paymentTerms?.map((item, index) => {
                  const installmentAmount = parseFloat(
                    (
                      (totalOfServies + (selectedPackage?.total_price || 0)) /
                      paymentTerms?.length
                    ).toFixed(2)
                  );
                  const adjustedAmount =
                    index === paymentTerms.length - 1
                      ? (
                          totalOfServies +
                          (selectedPackage?.total_price || 0) -
                          installmentAmount * (paymentTerms.length - 1)
                        ).toFixed(2)
                      : installmentAmount.toFixed(2);

                  return (
                    <div key={index} className="list">
                      Due on{" "}
                      {index === 0
                        ? moment().format("MMM DD, YYYY")
                        : moment(dateDuration?.event_date, "YYYY-MM-DD")
                            .subtract(item?.payment_day, "days")
                            .format("MMM DD, YYYY")}{" "}
                      <b>${adjustedAmount}</b>
                    </div>
                  );
                })}
              </div>
            </div>
            <Divider />
          </>
        ) : (
          ""
        )}

        <div className="term-check">
          <Checkbox
            checked={isTermsAccepted}
            onChange={(val) => {
              if (isTermsOpened) {
                setIsTermsAccepted(val.target.checked);
                setIsTermsNotOpenedModal(false);
              } else {
                setIsTermsNotOpenedModal(true);
              }
            }}
          />

          <div>
            I Agree to the{" "}
            <a
              onClick={() => {
                setTermModal(true);
                setIsTermsOpened(true);
              }}
            >
              Term & Conditions and Privacy Policy
            </a>
          </div>
        </div>

        {isTermsNotOpenedModal && (
          <p
            style={{
              color: "red",
            }}
          >
            * Please review the Terms and conditions
            *
          </p>
        )}
        {sebmitButton(isTermsAccepted)}
      </Card>
      <TermsModal
        open={termModal}
        setChecked={setIsTermsAccepted}
        handelClose={() => setTermModal(false)}
        content={details?.terms}
      />
    </>
  );
};

export default TransactionDetail;
