import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'antd';

const MessageModal = ({ open, title, content, onClose, error, timeOut }) => {

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                onClose(false);
            }, timeOut ? timeOut : 3000);
        }
    }, [open])


    return (
        <div>
            <Modal title={title} open={open} onOk={() => onClose(false)} onCancel={() => onClose(false)}>
                <h4>{content}</h4>
            </Modal>
        </div>
    )
}

export default MessageModal