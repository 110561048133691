import React, { useState, useEffect } from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import usePopup from '../../../../../common/MessageModal/usePopup';
import myApi from '../../../../Integrations/PaymentIntegration/UserData';

// UI's
import { Typography, Space, Button, Card, Spin, Select } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import AddPayments from './AddPayments';
import { getPayments, refundPayment, updatePayment } from '../../../../../services/events';
import noPayment from '../../../../../assets/no-payments.svg';
import empty from '../../../../../assets/empty.svg';
import PaymentMode from './PaymentMode';
import './billings.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Billings = ({ totalPrice, eventId, event }) => {
    const navigate = useNavigate();
    const popup = usePopup();
    const { Title, Text } = Typography;
    const [addPayments, setAddPayments] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [payments, setPayments] = useState([]);
    const [paymentMode, setPaymentMode] = useState(false);
    const [eventPayment, setEventPayment] = useState(null);
    const [payButtonLoading, setPayButtonLoading] = useState(false);
    const [accountID, setAccountId] = useState(null);
    const [dateOfPayment, setDateOfPayment] = useState('');

    const getStripeAccountId = async () => {
        setLoading(true);
        try {
            const res = await myApi.get(`${process.env.REACT_APP_BASE_API_URL}/api/company/integration/stripe/`)
            setAccountId(res.data.data.account_id);
        } catch (error) {
            setAccountId(null);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getStripeAccountId()
    }, [])

    const fetchAllPayments = async () => {
        setLoading(true);
        try {
            const res = await getPayments(eventId);
            setPayments(res?.data?.results);
        } catch (err) {
            popup.error('Opss!', err?.response?.data?.message, err);
        } finally {
            setLoading(false);
        }
    }

    const paymentRefund = async (id, name) => {
        setPayButtonLoading(id);
        try {
            const res = await refundPayment(id);
            popup.success('Success!', `Your payment "${name}" has been refunded successfully!`);
            fetchAllPayments();
        } catch (err) {
            popup.error('Opss!', err?.response?.data?.message);
        } finally {
            setPayButtonLoading(false);
        }

    };

    useEffect(() => {
        if (eventId && accountID && !loading) {
            fetchAllPayments();
        }
    }, [eventId, accountID]);

    // Paymnets dates
    const dateOfPaymentFunc = () => {
        setDateOfPayment(null);
        const currentDate = moment().startOf("day");
        const dueDate = moment
            .duration(
                moment(event?.event_date, "YYYY-MM-DD").diff(currentDate)
            )
            .asDays();

        if (dueDate >= 0) {
            let dates = [{ value: event?.event_date, label: "Event day" }];
            const loopCount = Math.ceil(dueDate / 5);
            for (let i = 1; i < dueDate && i < 5; i++) {
                dates.push({
                    value: moment(event?.event_date, "YYYY-MM-DD")
                        .subtract(i, "days")
                        .format("YYYY-MM-DD"),
                    label: i === 1 ? i + " day before" : i + " days before",
                });
            }
            for (let i = 1; i < loopCount + 1; i++) {
                dates.push({
                    value:
                        i * 5 >= dueDate
                            ? moment().format("YYYY-MM-DD")
                            : moment(event?.event_date, "YYYY-MM-DD")
                                .subtract(i * 5, "days")
                                .format("YYYY-MM-DD"),
                    label: i * 5 >= dueDate ? "Due today" : i * 5 + " days before",
                });
            }
            setDateOfPayment(dates);
        }
    };

    useEffect(() => {
        if (payments.length) {
            dateOfPaymentFunc();
        }
    }, [payments, event]);

    // update payment
    const updatePaymentonServer = async (id, date) => {
        let formData = new FormData();
        formData.append("scheduled_date", date);
        formData.append("paid_by_email", event?.contacts[0]?.email);
        setUpdateLoading(true);
        try {
            const res = await updatePayment(id, formData);
            fetchAllPayments();
        } catch (error) {
            popup.error('Opss!', error?.response?.data?.message);
        } finally {
            setUpdateLoading(false);
        }
    }

    const renderDates = (index) => {
        if (index === 1 && payments.length > 2) {
            const today = moment().format('YYYY-MM-DD');
            const sameDay = Boolean(event?.event_date === today);
            const dates = dateOfPayment?.length ? dateOfPayment?.filter(item => sameDay ? sameDay : item.label !== 'Event day') : [];
            return dates
        } else if (index === 2 && payments[1]?.scheduled_date) {
            const dates = dateOfPayment?.length ? dateOfPayment?.filter(item => moment(item.value, 'YYYY-MM-DD').isAfter(payments[1]?.scheduled_date)) : [];
            return dates
        } else {
            return dateOfPayment
        }
    }

    if (loading) {
        return (
            <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
                <Spin size={'large'} />
            </Card>
        )
    }

    return (
        <div>
            <Card
            >
                {(!payments?.length || !accountID) ? (
                    <div className="no-payments">
                        <img src={!accountID ? noPayment : empty} alt="no payments" />
                        <h3>{!accountID ? "No linked Bank account is found" : "Oops! Its Empty Here..."}</h3>
                        <p>{!accountID ? 'To receive funds from your event, add a bank account.' : 'Please click on below button to create payments for your event.'}</p>
                        {!accountID ? (
                            <Button type="primary" onClick={() => navigate('/Integration/LinkStripe')}>Create Stripe Account</Button>
                        ) : (
                            <Button icon={<PlusCircleFilled />} type="primary" onClick={() => setAddPayments(true)}>ADD Payment method</Button>
                        )}
                    </div>
                ) : (
                    <>
                        <Space className='navigationBar'>
                            <Title level={4}>Current Payment</Title>
                            {/* <Button className='payment-button' size={'medium'}>
                                <EditOutlined /> Modify Payment Method
                            </Button> */}
                        </Space>
                        {payments?.map((item, i) => (
                            <Card
                                key={i}
                                className='initial-payment-card'
                                title={item?.title}
                                extra={<Space>
                                    <Text style={{ fontWeight: 600, marginLeft: '16px' }}>Day of payment:</Text>
                                    <Select
                                        style={{ minWidth: 120 }}
                                        placeholder="Select date"
                                        value={
                                            i === 0 ? { label: 'Booking day', value: 0 } : item.scheduled_date &&
                                                moment(item.scheduled_date).format("YYYY-MM-DD")
                                        }
                                        options={renderDates(i)}
                                        loading={updateLoading}
                                        onChange={(value) => updatePaymentonServer(item.id, value)}
                                        disabled={item.status === "partial_payment" || item.status === "paid" || i === 0}
                                    />
                                    {item?.status === "partial_payment" || item?.status === "paid" ?
                                        (
                                            <Button
                                                type="primary"
                                                className='initial-payment-button refund'
                                                size={'medium'}
                                                loading={payButtonLoading === item.id}
                                                onClick={() => paymentRefund(item?.id, item?.title)}
                                            // disabled
                                            >
                                                Refund
                                            </Button>
                                        ) : (
                                            <Button
                                                type="primary"
                                                className='initial-payment-button'
                                                size={'medium'}
                                                loading={payButtonLoading === item.id}
                                                disabled={!item.scheduled_date}
                                                onClick={() => {
                                                    setPaymentMode(true)
                                                    setEventPayment(item)
                                                }}
                                            >
                                                Pay
                                            </Button>
                                        )}
                                </Space>}
                            >
                                <div className='payment-info'>
                                    <div>
                                        <h1>${item?.payment_expected}</h1>
                                        {/* <p>Includes up to 10 users, 20 GB individual data and access to all features</p> */}
                                    </div>
                                    <div className={`payment-status ${item?.status === 'partial_payment' ? 'paid' : ''}`}>
                                        <span>{item?.status === "partial_payment" ? 'Paid' : item?.status === "refund" ? 'Refunded' : item?.status === "sched_installment" ? 'Scheduled' : item?.status?.replaceAll('_', ' ')}</span>
                                    </div>
                                </div>
                            </Card>
                        ))}
                    </>
                )}
            </Card>
            <AddPayments
                open={addPayments}
                totalPrice={totalPrice}
                handelClose={setAddPayments}
                fetchAllPayments={fetchAllPayments}
                eventId={eventId}
            />
            <Elements stripe={stripePromise}>
                <PaymentMode
                    open={paymentMode}
                    handelClose={() => setPaymentMode(false)}
                    payment={eventPayment}
                    setPayButtonLoading={setPayButtonLoading}
                    payButtonLoading={payButtonLoading}
                    fetchAllPayments={fetchAllPayments}
                    event={event}
                />
            </Elements>
        </div >
    )
}

export default Billings
