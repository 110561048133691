import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
// UI's
import {
    Card,
    Input,
    Select,
    Typography,
} from "antd";

import { getContractTemplates } from "../../../services/events";

const Contracts = ({ error, setErrors, terms, setTerms }) => {
    const { Title, Text } = Typography;
    const { TextArea } = Input;
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchContractTemplates = async () => {
        setLoading(true);
        try {
            const res = await getContractTemplates();
            setTemplates(res?.data?.data);
        }
        catch (error) {
            const errorCode = error?.response?.status || 500; 
            if (errorCode === 500 || errorCode === 503) {
              window.location.href = '/server-error';
              console.log("Redirecting to server error page...");
            } else {
                toast.error(error.response.data.message);   
            }
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchContractTemplates();
    }, []);

    useEffect(() => {
        setErrors(prev => ({ ...prev, terms: '' }));
    }, [terms]);

    return (
        <Card style={{ marginTop: "20px" }}>
            <Title level={4}>Select Terms & Conditions</Title>

            <p style={{ color: "#777980" }}>Select Template</p>
            <Select
                placeholder="Select"
                size='large'
                loading={loading}
                style={{ width: '100%', backgroundColor: "#f9f9fc" }}
                options={templates?.length ? templates.map((item, i) => ({ label: item.title, value: item.title + i, data: item })) : null}
                onDropdownVisibleChange={(open) => {
                    if (open) {
                        fetchContractTemplates();
                    }
                }}
                onChange={(e, obj) => {
                    setTerms({
                        title: obj?.data?.title,
                        terms_html: obj?.data?.terms_html
                    });
                }}
            />

            <p style={{ color: "#777980", marginTop: "10px" }}>T & C</p>
            <Input
                size="large"
                style={{ backgroundColor: "#f9f9fc" }}
                placeholder="Initial Deposit"
                value={terms?.title}
                onChange={(e) => setTerms(prev => ({ ...prev, title: e.target.value }))}
                status={error?.title && 'error'}
            />
            {error?.title ? (<Text style={{ display: 'block' }} type="danger">{error?.title}</Text>) : null}

            <p style={{ color: "#777980", marginTop: "10px" }}>T & C details</p>
            <TextArea
                style={{ backgroundColor: "#f9f9fc" }}
                placeholder="Template Description"
                autoSize={{
                    minRows: 5,
                }}
                value={terms?.terms_html}
                onChange={(e) => setTerms(prev => ({ ...prev, terms_html: e.target.value }))}
                status={error?.terms_html && 'error'}
            />
            {error?.terms_html ? (<Text style={{ display: 'block' }} type="danger">{error?.terms_html}</Text>) : null}
        </Card>
    )
}

export default Contracts
