import { DASHBOARD_DATA, COMPANY, CHART, SEARCH } from './endpoints';
import client from '../axios';

// Dashboard
export const getDashboardData = async (params) => {
    const queryParams = params ? Object.entries(params).map(([key, value]) => (`${key}=${value}`)).join('&') : '';
    return await client.get(DASHBOARD_DATA + (queryParams ? `?${queryParams}` : ''));
}

// Chart
export const getChartData = async (params) => {
    const queryParams = params ? Object.entries(params).map(([key, value]) => (`${key}=${value}`)).join('&') : '';
    return await client.get(CHART + (queryParams ? `?${queryParams}` : ''));
}

// Company info
export const getCompanyDetails = async () => {
    return await client.get(COMPANY);
}
export const updateCompanyDetails = async (id, body) => {
    return await client.put(COMPANY + id + '/', body);
}

export const globalSearch = async (location_id, search) => {
    return await client.get(SEARCH + `?location=${location_id}` + `&search=${search}`);
}
