import { Modal } from "antd";
import React, { useState } from "react";
import "./EditCompanyDetails.scss";

import { ReactComponent as CrossIcon } from "../../../../assets/Modals/CrossIcon.svg";
import { ReactComponent as BackArrow } from "../../../../assets/Modals/backArrow.svg";
import { Input, Button } from "antd";
import PhoneMask from "../../../../common/PhoneMask";
import { Upload } from "antd";
import ZipCodeMask from "../../../../common/ZipCodeMask";
import { Spin } from "antd";

const EditCompanyDetails = ({
  open,
  setOpen,
  companyName,
  setCompanyName,
  companyEmail,
  file,
  setFile,
  isSpining,
  setCompanyEmail,
  companyPhone,
  setCompanyPhone,
  updateCompanyDetails,
  companyAddress,
  setCompanyAddress,
  companyCity,
  companyLogo,
  setCompanyCity,
  companyState,
  setCompanyState,
  companyZipCode,
  setCompanyZipCode,
  companyRequired,
}) => {
  const [isfirstScreen, setIsFirstScreen] = useState(true);
  const [isSecondScreen, setIsSecondScreen] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isZipCodeError, setIsZipCodeError] = useState(false);
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [isNameError, setIsNameError] = useState(false);
  const [tempPhone, setTempPhone] = useState(null);
  const [uploadImageURL, setUploadImageURL] = useState();
  const isEmailValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const dummyRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    customRequest: dummyRequest,
    onRemove: (file) => {
      // setFile(null);
      // setUploadImageURL("");
    },

    // beforeUpload(file, fileList) {
    //   const isJpgOrPng =
    //     file.type === "image/jpeg" || file.type === "image/png";
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isJpgOrPng) {
    //     setMsgModalData({
    //       title: "Image Upload",
    //       data: "You can only upload <b>JPG/PNG</b> file!",
    //       error: true,
    //     });
    //   }
    //   if (!isLt2M) {
    //     setMsgModalData({
    //       title: "Image Upload",
    //       data: "Image must smaller than <b>2MB</b>!",
    //       error: true,
    //     });
    //   }
    //   return isJpgOrPng && isLt2M;
    // },
    onChange(info) {
      const { status } = info.file;

      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
        setFile(info.file.originFileObj);
        setUploadImageURL(URL.createObjectURL(info.file.originFileObj));
      } else if (status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },

    onDrop(e) {
      console.log("Dropped files", e);
    },
  };

  return (
    <>
      <Modal
        title={null}
        centered
        open={open}
        onOk={() => {
          if (!companyRequired) {
            setOpen(false);
          }
        }}
        footer={null}
        className="edit-company-details"
        closable={false}
        onCancel={() => {
          if (!companyRequired) {
            setOpen(false);
            setIsFirstScreen(true);
            setIsSecondScreen(false);
          }
        }}
      >
        <div className="header">
          <div className="header-left">
            <BackArrow
              onClick={() => {
                if (isfirstScreen && !companyRequired) {
                  setOpen(false);
                } else {
                  setIsFirstScreen(true);
                  setIsSecondScreen(false);
                }
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          <div className="header-center">Edit Company</div>
          <div className="header-right">
            {!companyRequired ? (
              <CrossIcon
                onClick={() => {
                  setOpen(false);
                  setIsFirstScreen(true);
                  setIsSecondScreen(false);
                  setIsEmailError(false);
                  setIsZipCodeError(false);
                  setIsPhoneError(false);
                  setIsNameError(false);
                  setUploadImageURL(null);
                }}
                style={{
                  cursor: "pointer",
                }}
              />
            ) : null}
          </div>
        </div>

        <Spin spinning={isSpining} delay={500}>
          {isfirstScreen && (
            <div className="content-edit-company">
              <div className="content-edit-company-input-wrapper">
                <Input
                  placeholder="Email*"
                  size="large"
                  value={companyEmail}
                  onChange={(e) => {
                    setCompanyEmail(e.target.value);
                  }}
                />
                {isEmailError && <p>Email required.</p>}
              </div>
              <div className="content-edit-company-input-wrapper">
                <PhoneMask
                  value={companyPhone}
                  placeholder="Phone*"
                  onChange={(e) => {
                    setCompanyPhone(e.target.value);
                  }}
                >
                  {() => {
                    <Input
                      placeholder="Phone*"
                      size="large"
                      style={{
                        width: "93%",
                      }}
                      value={companyPhone}
                      onChange={(e) => {
                        setCompanyPhone(e.target.value);
                      }}
                    />;
                  }}
                </PhoneMask>
                {isPhoneError && (
                  <p style={{ color: "red" }}>
                    <span>Phone No.</span> is not valid.
                  </p>
                )}
              </div>
              {/* <div className="content-edit-company-input-wrapper">
                <Input
                  placeholder="Address"
                  size="large"
                  value={companyAddress}
                  onChange={(e) => {
                    setCompanyAddress(e.target.value);
                  }}
                />
              </div> */}
              {companyLogo && !uploadImageURL ? (
                <Upload
                  {...props}
                  showUploadList={false}
                  accept=".jpg, .jpeg, .png"
                >
                  <div className="company-logo">
                    <img src={companyLogo} alt="company-logo" />
                  </div>
                </Upload>
              ) : uploadImageURL ? (
                <Upload
                  {...props}
                  showUploadList={false}
                  accept=".jpg, .jpeg, .png"
                >
                  <div className="company-logo">
                    <img src={uploadImageURL} alt="company-logo" />
                  </div>
                </Upload>
              ) : (
                <div className="company-edit-upload">
                  <Upload
                    {...props}
                    showUploadList={false}
                    accept=".jpg, .jpeg, .png"
                  >
                    <button>Browse files</button>
                  </Upload>
                </div>
              )}
            </div>
          )}

          {isSecondScreen && (
            <div className="content-edit-company-2">
              <div className="content-edit-company-input-wrapper">
                <Input
                  placeholder="Enter Company Name*"
                  value={companyName}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                  size="large"
                />
                {isNameError && (
                  <p style={{ color: "red" }}>Company name can't be empty.</p>
                )}
              </div>
              <div className="content-edit-company-input-wrapper">
                <Input
                  placeholder="Address"
                  size="large"
                  value={companyAddress}
                  onChange={(e) => {
                    setCompanyAddress(e.target.value);
                  }}
                />
              </div>
              <div className="content-edit-company-input-wrapper">
                <Input
                  placeholder="Enter State*"
                  size="large"
                  value={companyState}
                  onChange={(e) => {
                    setCompanyState(e.target.value);
                  }}
                />
              </div>
              <div className="content-edit-company-input-wrapper">
                <Input
                  placeholder="Enter City"
                  size="large"
                  value={companyCity}
                  onChange={(e) => {
                    setCompanyCity(e.target.value);
                  }}
                />
              </div>
              {/* <div className="content-edit-company-input-wrapper">
              <Select
                defaultValue="lucy"
                style={{
                  width: "100%",
                }}
                size="large"
                //   onChange={handleChange}
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "Yiminghe",
                    label: "yiminghe",
                  },
                ]}
              />
            </div>
            <div className="content-edit-company-input-wrapper">
              <Select
                defaultValue="lucy"
                style={{
                  width: "100%",
                }}
                size="large"
                //   onChange={handleChange}
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "Yiminghe",
                    label: "yiminghe",
                  },
                ]}
              />
            </div> */}
              <div className="content-edit-company-input-wrapper">
                <ZipCodeMask
                  value={companyZipCode}
                  placeholder="Zip Code*"
                  onChange={(e) => {
                    setCompanyZipCode(e.target.value);
                  }}
                >
                  {
                    () =>
                      <Input
                        placeholder="Zip Code*"
                        size="large"
                        value={companyZipCode}
                        onChange={(e) => {
                          setCompanyZipCode(e.target.value);
                          setTempPhone(e.target.value);
                        }}
                      />
                  }
                </ZipCodeMask>
                {isZipCodeError && (
                  <p style={{ color: "red" }}>
                    <span>Zip Code</span> must be 5 digits.
                  </p>
                )}
              </div>
            </div>
          )}
        </Spin>

        <div className="footer">
          <div className="footer-left"></div>
          <div className="footer-center"></div>
          <div className="footer-right">
            <div className="back-btn">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  if (isSecondScreen) {
                    setIsFirstScreen(true);
                    setIsSecondScreen(false);
                  } else {
                    if (!companyRequired) {
                      setOpen(false);
                      setUploadImageURL(null);
                    }
                  }
                }}
              >
                Back
              </button>
            </div>
            <div className="next-btn">
              {isSecondScreen ? (
                <Button
                  type="button"
                  size="large"
                  className="btn"
                  loading={isSpining}
                  onClick={() => {
                    if (companyZipCode?.length < 5) {
                      setIsZipCodeError(true);
                    } else {
                      if (companyName?.length === 0) {
                        setIsNameError(true);
                      } else {
                        setIsZipCodeError(false);
                        updateCompanyDetails();
                        setUploadImageURL(null);
                        setIsFirstScreen(true);
                        setIsNameError(false);
                        setIsSecondScreen(false);
                      }
                    }
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button
                  type="button"
                  className="btn"
                  loading={isSpining}
                  onClick={() => {
                    if (isEmailValid(companyEmail)) {
                      if (companyPhone.length < 14) {
                        setIsPhoneError(true);
                      } else {
                        setIsPhoneError(false);
                        setIsFirstScreen(false);
                        setIsSecondScreen(true);
                        setIsEmailError(false);
                      }
                    } else {
                      setIsEmailError(true);
                    }
                  }}
                >
                  Next
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default EditCompanyDetails;
