import React, { useState, useEffect } from "react";
import { Form, Input, Button, Card, Spin } from "antd";
import toast from "react-hot-toast";
import InputMask from "react-input-mask";
import axiosInstance from "../../../common/axiosInstance";
import "./ProfileSetting.css";
import CityAutoComplete from "../../../common/CityAutoComplete";
import StateAutoComplete, { validateStateInput } from "../../../common/StateAutoComplete";

const phoneRegExp = /^\(\d{3}\) \d{3}-\d{4}$/;
const zipcodeRegex = /^\d{5}$/;
// Assuming the input format is (XXX) XXX-XXXX
const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return ''
  }
  const digits = phoneNumber?.replace(/\D/g, ''); // Remove non-numeric characters
  return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
};
const ProfileSetting = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});

  const user_info = localStorage.getItem('planspace_user_obj');

  const isValidZipcode = (zipcode) => {
    return zipcodeRegex.test(zipcode);
  };

  const getData = () => {
    setLoading(true);
    axiosInstance.get(`${process.env.REACT_APP_BASE_API_URL}/api/auth/user/info/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        const userData = response.data.data;
        setUserData(userData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Error fetching user data: " + error.message);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (userData && form) {
      form.setFieldsValue({
        name: userData?.full_name || '',
        email: userData?.username || "",
        phone: formatPhoneNumber(userData?.mobile) || "",
        address: userData?.address?.line1 || "",
        city: userData?.address?.zip_code?.city || "",
        state: userData?.address?.zip_code?.state || "",
        zipcode: userData?.address?.zip_code?.zip_code?.toString() || ""
      });
    }
  }, [userData]);

  const onFinish = async (values) => {
    setLoading(true);
    // remove douple spaces from name
    let name = values?.name?.replace(/\s{2,}/g, " ").trim();
    name = name?.split(" ");
    let firstName;
    let middleName;
    let lastName;
    if (name.length === 3) {
      firstName = name[0];
      middleName = name[1];
      lastName = name[2];
    } else if (name.length === 2) {
      firstName = name[0];
      middleName = "";
      lastName = name[1];
    } else if (name.length === 1) {
      firstName = name[0];
    }
    const data = {
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      username: values?.email,
      mobile: values?.phone,
      address: {
        line1: values?.address,
        zip_code: {
          city: values?.city,
          state: values?.state,
          zip_code: values?.zipcode,
        },
      },
    };

    if (values?.zipcode && !isValidZipcode(values?.zipcode)) {
      setLoading(false);
      toast.error("Invalid ZIP code format (5 digits)!");
      return;
    }

    try {
      const access_token = localStorage.getItem("access_token");
      if (!access_token) {
        console.error("JWT token not in local storage!");
        return;
      }
      const config = {
        headers: {
          Authorization: `JWT ${access_token}`,
        },
      };

      const response = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_API_URL}/api/auth/user/${userData?.id}/`,
        data,
        config
      );
      localStorage.setItem("planspace_user_obj", JSON.stringify(response?.data));
      setUserData(response?.data?.data);
      toast.success("Profile updated successfully", {
        autoClose: 3000,
      });
      setLoading(false);
      getData();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const errorMessage = "Account on this email already exist!";
        toast.error(errorMessage, {
          autoClose: 3000,
        });
      } else {
        toast.error("Error updating profile: " + error.message, {
          autoClose: 3000,
        });
      }
      setLoading(false);
    }
  };

  return (
    <div className="Profile-Container">
      <Spin spinning={loading}>
        <Card title="Profile-Settings" style={{ width: 800 }}>
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item
              label="Name*"
              name="name"
              rules={[
                { required: true, message: "Please enter a name!" },
              ]}
            >
              <Input placeholder="John Alexander Smith" maxLength={50} className="input-field" />
            </Form.Item>
            <Form.Item
              label="Email*"
              name="email"
              rules={[
                { required: true, message: "Please enter an email!" },
                { type: "email", message: "Invalid email format!" },
              ]}
            >
              <Input disabled={user_info?.is_google_login} className="input-field" />
            </Form.Item>
            <Form.Item
              label="Phone*"
              name="phone"
              rules={[
                { required: true, message: "Please enter a phone number!" },
                {
                  pattern: phoneRegExp,
                  message: "Please enter a valid Phone Number!",
                },
              ]}
            >
              <InputMask mask="(999) 999-9999" maskChar="" placeholder="(123) 345-6789">
                {(inputProps) => <Input {...inputProps} className="input-field" />}
              </InputMask>
            </Form.Item>
            <Form.Item
              label="Address"
              name="address"
            >
              <Input className="input-field" />
            </Form.Item>
            <Form.Item
              name={"city"}
              label="City"
            >
              <CityAutoComplete size="medium" />
            </Form.Item>

            <Form.Item
              name={"state"}
              label="State"
              style={{ width: '100%' }}
              rules={[
                {
                  validator: validateStateInput,
                },
              ]}
            >
              <StateAutoComplete size="medium" />
            </Form.Item>

            <Form.Item
              name={"zipcode"}
              label="Zip Code"
              style={{ width: '100%' }}
              rules={[
                {
                  pattern: /^(?!0{5})[0-9]{5}$/,
                  message: 'Please enter valid zip code!'
                },
              ]}
            >
              <InputMask
                mask="99999"
                maskChar=""
              >
                {() => <Input type="text" placeholder="Type here..." />}
              </InputMask>
            </Form.Item>
            <Form.Item style={{ display: "flex" }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
              <Button type="primary" style={{ margin: "0 15px" }}>
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Spin>
    </div >
  );
};

export default ProfileSetting;
