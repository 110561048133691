import { SERVICES } from './endpoints';
import client from '../axios';

// Services
export const getServices = async (params) => {
    const queryParams = params ? Object.entries(params)?.map(([key, value]) => (`${key}=${value}`)).join('&') : '';
    return await client.get(SERVICES + (queryParams ? `?${queryParams}` : ''));
}
// create Service
export const createService = async (body) => {
    return await client.post(SERVICES, body);
}

// update Service
export const updateService = async (id, body) => {
    return await client.put(SERVICES + id + '/', body);
}

// delete Service
export const deleteService = async (id) => {
    return await client.delete(SERVICES + id + '/');
}