import React, { useState, useEffect } from 'react';
import { Row, Col, Progress } from 'antd';
import { Link } from 'react-router-dom';
import logo2 from '../Assets/logo2.png';
import './SetupComplete.css';


const SetupComplete = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 1 : 100));
    }, 50);

    return () => clearInterval(interval);
  }, []);

  return (
    <Row className="setupcomplete-container">
      <Col span={1}>
        <div className="setupcomplete-logo-container">
          <img src={logo2} alt="Logo" className="setupcomplete-logo" />
        </div>
      </Col>
      <Col span={23} className='setupcomplete-form-column'>
        <div className="setupcomplete-form-container">
          <div>
            <p className="setupcomplete-setup-title">Congratulations! We are ready for the final step</p>
          </div>
          <p>We are collecting your inputs to feed </p>
          <div className="setupcomplete-setup-content">
            <div className="setupcomplete-card-container">
              <Progress style={{ fontFamily: 'Roboto Thin', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }} type="circle" percent={progress} size={130} />
            </div>
          </div>
          <div className="setupcomplete-footer">
            <Link>
              <button className='proceed-btn'>Proceed to Next Step</button>
            </Link>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SetupComplete;
