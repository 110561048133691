import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  Select,
  Divider,
  Empty,
  Row,
  Col,
  Avatar,
} from "antd";
import moment from "moment";
import { ReactComponent as Badge } from "../../../assets/icons/badge.svg";
import { serviceIcons } from "./utils";
import bgImage from "../../../assets/background.jpg";
import "../styles.scss";

const Packages = ({ setFilteredServices, packages, value, handelChange }) => {
  const { Title, Text } = Typography;
  const [selected, setSelected] = useState(null);

  const updatedPckages = packages?.length
    ? packages.map((item) => ({ label: item.name, value: item.id }))
    : [];

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const hoursText = hours > 0 ? `${hours} hour${hours !== 1 ? "s" : ""}` : "";
    const minutesText =
      remainingMinutes > 0
        ? `${remainingMinutes} minute${remainingMinutes !== 1 ? "s" : ""}`
        : "";

    if (hoursText && minutesText) {
      return `${hoursText} and ${minutesText}`;
    } else {
      return hoursText || minutesText || "-";
    }
  };

  useEffect(() => {
    if (value) {
      const found = packages?.find((item) => item.id === value);
      setSelected(found);
      setFilteredServices(found)
    }
  }, [value]);

  return (
    <Card className="booking-card">
      <Title level={4}>Select Package</Title>
      <Select
        size="large"
        style={{ width: "100%" }}
        placeholder="select"
        onChange={(value) => handelChange(value)}
        options={updatedPckages}
      />
      <Divider />
      {selected ? (
        <>
          <Title level={4}>Selected Package</Title>
          <div className="package-wrapper">
            <Row gutter={16}>
              <Col span={10}>
                <img
                  className="thumbnail"
                  src={
                    selected?.images?.length
                      ? selected?.images[0]?.image
                      : bgImage
                  }
                  alt="package"
                />
              </Col>
              <Col span={14}>
                <Title level={4}>{selected?.name}</Title>
                <Text>{selected?.description}</Text>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 46 }}>
              {/* <Col span={10}>
                            <div className='package-details'>
                                <Avatar icon={<Badge />} size={46} style={{ backgroundColor: '#F0F1F3' }} />
                                <div>
                                    <Title level={5} type='secondary' style={{ marginBlock: 0 }}>Day Availability</Title>
                                    <Title level={5} style={{ marginBlock: 0 }}>{selected?.days?.length ? selected?.days?.join(', ') : '-'}</Title>
                                </div>
                            </div>
                        </Col> */}
              <Col span={10}>
                <div className="package-details">
                  <Avatar
                    icon={<Badge />}
                    size={46}
                    style={{ backgroundColor: "#F0F1F3" }}
                  />
                  <div>
                    <Title
                      level={5}
                      type="secondary"
                      style={{ marginBlock: 0 }}
                    >
                      Package Length
                    </Title>
                    {/* <Title level={5} style={{ marginBlock: 0 }}>{moment.duration(selected?.duration_minutes, 'hours').asMinutes() || '-'}</Title> */}
                    {/* <Title level={5} style={{ marginBlock: 0 }}>{selected?.duration_minutes}</Title> */}
                    <Title level={5} style={{ marginBlock: 0 }}>
                      {formatDuration(selected?.duration_minutes)}
                    </Title>
                  </div>
                </div>
              </Col>
            </Row>
            <Divider />
            {selected?.addons?.length ? (
              <>
                <Title level={4}>Services</Title>
                <div className="services-wrapper">
                  {selected?.addons?.map((item, i) => (
                    <div key={i} className="package-details">
                      <Avatar
                        icon={serviceIcons[i]?.icon}
                        size={46}
                        style={{ backgroundColor: serviceIcons[i]?.color }}
                      />
                      <Title level={5} style={{ marginBlock: 0 }}>
                        {item?.name}
                      </Title>
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </div>
          <div className="total-price">
            <div className="inner">
              <Title level={5} style={{ marginBlock: 0, color: "#0499CB" }}>
                Total Package Cost
              </Title>
              <Title level={5} style={{ marginBlock: 0 }}>
                ${selected?.total_price}
              </Title>
            </div>
          </div>
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No Package Selected"
        />
      )}
    </Card>
  );
};

export default Packages;
