import React from 'react';
import { Row, Col, Form, Input, Button, Modal } from 'antd';
import LoginHero from '../Login/Assets/Login.png';
import googlelogo from '../Login/Assets/Google.png';
import logo from '../Login/Assets/logo.png';
import './Login.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useGoogleLogin } from "@react-oauth/google";
import { toast } from 'react-hot-toast';
import axiosInstance from '../../common/axiosInstance';
const LoginScreen = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const sendData = async (email, password) => {
    // Show loading spinner while making the API request
    setLoading(true);

    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_BASE_API_URL}/api/auth/login/`, {
        username: email,
        password: password,
      });

      // Show info modal without any buttons
      const successModal = Modal.success({
        title: 'Login Successful',
        content: response?.data?.message,
        okButtonProps: { style: { display: 'none' } },
      });

      // Save access token and user object to local storage
      localStorage.setItem('access_token', response.data.data.access);
      localStorage.setItem('planspace_user_obj', JSON.stringify(response.data.data));

      // Navigate to the home page
      navigate('/');

      // Hide loading spinner
      setLoading(false);

      // Close the success modal after 2 seconds
      setTimeout(() => {
        successModal.destroy();
      }, 2000);
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode >= 500 && errorCode <= 503) {
        console.error("error 500");
      } else {
        let errorMessage = error?.response?.data?.message;
        let formattedMessage = '';

        if (typeof errorMessage === 'object') {
          for (let key in errorMessage) {
            let a = key.replace('_', ' ').toLocaleUpperCase() + ': ' + errorMessage[key];
            formattedMessage += a + ', \n';
          }
        } else if (typeof errorMessage === 'string') {
          formattedMessage = errorMessage;
        }

        // Show error modal without any buttons
        const errorModal = Modal.error({
          title: 'Login Failed',
          content: formattedMessage,
          okButtonProps: { style: { display: 'none' } },
        });

        // Hide loading spinner
        setLoading(false);

        // Close the error modal after 2 seconds
        setTimeout(() => {
          errorModal.destroy();
        }, 2000);
      }

      // Show error modal without any buttons
      const errorModal = Modal.error({
        title: 'Login Failed',
        content: JSON.stringify(error?.response?.data?.message),
        okButtonProps: { style: { display: 'none' } },
      });

      // Hide loading spinner
      setLoading(false);

      // Close the error modal after 2 seconds
      setTimeout(() => {
        errorModal.destroy();
      }, 2000);
    }
  };


  const onFinish = (values) => {
    sendData(values.email, values.password);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      await axios
        .post(`${process.env.REACT_APP_BASE_API_URL}/api/auth/login/google/`, {
          access_token: codeResponse.access_token,
          source: "login"
        })
        .then((res) => {
          localStorage.setItem("access_token", res.data.data.access);
          localStorage.setItem("planspace_user_obj", JSON.stringify(res.data.data));
          navigate('/');
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message.errors ? err?.response?.data?.message.errors[0] : "Something Went Wrong")
        });
    },
    onError: (error) => console.log("Login Failed:", error),
  });
  return (
    <Row>
      <div className="login-container">
        <Col span={24} lg={11} className='login-image-column' >
          <div className="login-image">
            <img src={LoginHero} alt="Login Image" />
          </div>
        </Col>
        <Col span={24} lg={13} className='login-col'>
          <div className="login-form-container">
            <div>
              <div className="login-logo">
                <img src={logo} alt="" />
              </div>
              <p className='login-heading'>Welcome Back!</p>
              <p style={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", letterSpacing: "0.08px", marginTop: "6px", color: "#667085" }}>Login with your google account or <br /> Enter your valid account below.</p>
            </div>
            <Button type="primary" htmlType="submit" className="google-button" onClick={() => googleLogin()}>
              <div className="button-content">
                <img src={googlelogo} alt="" className="google-logo" />
                <span style={{ fontSize: "14px", fontWeight: "600", lineHeight: "20px", letterSpacing: "0.07" }}>Login with Google</span>
              </div>
            </Button>
            <div className="divider" style={{ gap: "32", marginTop: "32px" }}>
              <div className="divider-line"></div>
              <div className="divider-text">Or</div>
              <div className="divider-line"></div>
            </div>
            <br />
            <Form name="login-form" initialValues={{ remember: true }} onFinish={onFinish}>
              <label htmlFor="userName" style={{ gap: "32", fontSize: "16px", color: "#777980" }}>Username / Email*</label>
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Please enter your username/email' }]}
              >
                <Input placeholder="Your username/email" className="login-form-item" />
              </Form.Item>
              <label htmlFor="password" style={{ display: 'flex', justifyContent: 'space-between', fontSize: "16px", color: "#777980" }}>
                <span>Password*</span>
                <span className="forgot-password">
                  <Link to="/forgetPassword" style={{ gap: "32", fontWeight: "700", fontSize: "14px", color: "#0499CB" }}>Forgot Password</Link>
                </span>
              </label>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please enter your password.' }]}
              >
                <Input.Password placeholder="Your password" className="login-form-item" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={`login-button ${loading ? 'loading' : ''}`}
                  loading={loading}
                >
                  <span className="button-text">{loading ? 'Loading...' : 'Log in'}</span>
                </Button>
              </Form.Item>
            </Form>
            <p style={{ textAlign: 'center', fontSize: "14px" }}>
              Don’t have an account?
              <span style={{ fontWeight: "700", fontSize: "14px", color: "#0499CB" }}>  <Link to="/register" style={{ color: "#0499CB" }}>Register now</Link></span>
            </p>
          </div>
        </Col>
      </div>
    </Row>
  );
};

export default LoginScreen;