import React, { useState } from 'react';
import dayjs from 'dayjs';
import usePopup from '../../../../../common/MessageModal/usePopup';

// UI's
import { Button, Modal } from 'antd';
import { Form, Input, DatePicker } from 'antd';
import { addNewTask } from '../../../../../services/events';
import styles from '../../../events.module.scss';

const AddNewTask = ({ open, handelClose, eventId, refetch }) => {
    const popup = usePopup();
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [loading, setLoading] = useState(false);

    const renderdButtons = () => {
        return [
            <Button size={'large'} key="cancel" onClick={handelClose}>
                Cancel
            </Button>,
            <Button loading={loading} onClick={() => form.submit()} size={'large'} type="primary">
                Create Task
            </Button>
        ]
    }

    const disabledPastDate = (current) => {
        return (
            current < dayjs().startOf('day')
        );
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            const res = await addNewTask({
                "event": eventId,
                "name": values?.title,
                "description": values?.description,
                "automated": false,
                "due_date": `${dayjs(values?.due_date).format('YYYY-MM-DD')} 23:59:00`,
                "status": "pending",
            });
            popup.success('Success!', res?.data?.message);
            refetch();
            form.resetFields();
            handelClose();
        } catch (error) {
            popup.error('Oops!', error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal
            className={styles.createModal}
            centered
            title="Create New Task"
            open={open}
            onCancel={handelClose}
            footer={renderdButtons()}
        >
            <Form
                className={styles.eventForm}
                form={form}
                name="validateOnly"
                layout="vertical"
                autoComplete="off"
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="title"
                    label="Title*"
                    rules={[
                        {
                            required: true,
                            message: 'title is required!'
                        }
                    ]}
                >
                    <Input size="large" placeholder="Type here..." />
                </Form.Item>
                <Form.Item
                    name="due_date"
                    label="Due Date*"
                    defaultValue
                    rules={[
                        {
                            required: true,
                            message: 'Due date is required!'
                        },
                    ]}
                >
                    <DatePicker
                        disabledDate={disabledPastDate}
                        popupClassName={styles.customDatePicker}
                        size='large'
                    />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description*"
                    rules={[
                        {
                            required: true,
                            message: 'Description is required!'
                        },
                    ]}
                >
                    <TextArea
                        autoSize={{ minRows: 5 }}
                        placeholder="Task description..."
                        size="large"
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddNewTask
