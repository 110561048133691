// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-color {
  background-color: #fafbfc;
}
.bg-color .right-border {
  border-right: 2px solid #eaedf4;
}

.ant-picker {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/MainPage.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AAAE;EACE,+BAAA;AAEJ;;AAEA;EACE,WAAA;AACF","sourcesContent":[".bg-color {\n  background-color: #fafbfc;\n  .right-border {\n    border-right: 2px solid #eaedf4;\n  }\n}\n\n.ant-picker {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
