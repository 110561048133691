import React, { useState, useEffect } from 'react';
import InputMask from "react-input-mask";
import ImageUploader from '../../../../components/ImageUploader';
import RoomForm from './RoomForm';
import usePopup from '../../../../common/MessageModal/usePopup';

// UI's
import { Button, Modal } from 'antd';
import { Form, Input } from 'antd';
import { createVenue, updateVenue } from '../../../../services/venues';
import StateAutoComplete, { validateStateInput } from '../../../../common/StateAutoComplete';
import CityAutoComplete from '../../../../common/CityAutoComplete';

const VenueForm = ({ open, handelClose, fetchVenues, venue }) => {
    const popup = usePopup();
    const [form] = Form.useForm();
    const [addRoom, setAddRoom] = useState(false);
    const [venuePayload, setVenuePayload] = useState(null);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);

    function formatPhoneNumber(phoneNumber) {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber;
    }

    useEffect(() => {
        if (venue) {
            const { zip_code, images, phone, ...rest } = venue;
            form.setFieldsValue({
                ...rest,
                phone: formatPhoneNumber(phone),
                city: zip_code?.city,
                state: zip_code?.state,
                zip_code: zip_code?.zip_code,
            });
            if (images?.length) {
                const data = images.map(item => ({ thumbUrl: item }))
                setImages(data);
            }
        }
    }, [venue, open]);

    const handleCancel = () => {
        handelClose();
        form.resetFields();
        setImages([]);
        setAddRoom(false);
        setVenuePayload(null);
    }

    const renderdButtons = () => {
        let buttons = [
            <Button size={'large'} key="cancel" onClick={handleCancel}>
                Cancel
            </Button>,
            <Button
                size={'large'}
                key="next"
                type="primary"
                loading={loading}
                onClick={() => {
                    setAddRoom(false);
                    form.submit();
                }}
            >
                {venue?.type === 'edit' ? 'Update' : 'Save'}
            </Button>
        ];
        if (venue?.type !== 'edit') {
            buttons.splice(1, 0,
                <Button
                    style={{ marginLeft: 'auto' }}
                    size={'large'}
                    key="room"
                    type="primary"
                    loading={loading}
                    onClick={() => {
                        setAddRoom(true);
                        form.submit();
                    }}
                >
                    Add Room
                </Button>
            );
        }
        return buttons
    }

    const handleSubmit = async (values) => {
        const formData = new FormData();
        Object.entries(values).map(([key, value]) => {
            formData.append(key, key === 'state' ? value?.toUpperCase() : value ? value : "");
        });
        if (images.length > 0) {
            images.forEach(image => formData.append('images', image.originFileObj));
        }

        if (addRoom) {
            setVenuePayload(formData);
        } else {
            setLoading(true);
            try {
                if (venue?.type === 'edit') {
                    await updateVenue(venue?.id, formData);
                    popup.success("Success!", "Venue updated successfully");
                } else {
                    await createVenue(formData);
                    popup.success("Success!", "Venue created successfully");
                    // const new_venue = res?.data?.data;
                    // const venue_obj = { id: new_venue?.id, label: new_venue?.name, value: new_venue?.id }
                    // dispatch(setVenue(venue_obj));
                    // localStorage.setItem('selectedVanue', JSON.stringify(venue_obj));
                }
                fetchVenues();
                handleCancel();
            } catch (error) {
                const errorCode = error?.response?.status || 500;
                if (errorCode === 500 || errorCode === 503) {
                    window.location.href = '/server-error';
                    console.log("Redirecting to server error page...");
                }
                if (typeof error?.response?.data?.message === 'object' || 'array') {
                    const errors = error?.response?.data?.message[0];
                    Object.entries(errors).map(([field, message]) => {
                        form.setFields([
                            {
                                name: field,
                                errors: message,
                            },
                        ]);
                    });
                } else {
                    popup.error("Oops...", error?.response?.data?.message);
                }
            } finally {
                setLoading(false);
            }
        }

    }

    return open ? (
        <Modal
            centered
            title={venue?.type === 'edit' ? "Update Venue" : "Add Venue"}
            open={open}
            onCancel={handleCancel}
            footer={renderdButtons()}
        >
            <Form
                style={{ marginTop: 24 }}
                form={form}
                name="validateOnly"
                layout="vertical"
                autoComplete="off"
                className='modal-form'
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="name"
                    label="Venue Name*"
                    rules={[
                        {
                            required: true,
                            message: 'Venue name is required!'
                        },
                    ]}
                >
                    <Input maxLength={30} size="large" placeholder="Enter venue name" />
                </Form.Item>
                <Form.Item
                    name={"address_line1"}
                    label="Address"
                >
                    <Input maxLength={100} size="large" placeholder="Enter venue address" />
                </Form.Item>
                <Form.Item
                    name={"city"}
                    label="City*"
                    rules={[
                        {
                            required: true,
                            message: 'City is required!'
                        },
                    ]}
                >
                    <CityAutoComplete />
                </Form.Item>
                <div style={{ display: 'flex', gap: 16 }}>
                    <Form.Item
                        name={"state"}
                        label="State*"
                        style={{ width: '100%' }}
                        rules={[
                            {
                                required: true,
                                message: 'State is required!'
                            },
                            {
                                validator: validateStateInput,
                            },
                        ]}
                    >

                        <StateAutoComplete />
                    </Form.Item>
                    <Form.Item
                        name={"zip_code"}
                        label="Zip Code*"
                        style={{ width: '100%', maxWidth: '40%' }}
                        rules={[
                            {
                                required: true,
                                message: 'Zip code is required!'
                            },
                            {
                                // pattern: /^\d{5}$/,
                                pattern: /^(?!0{5})[0-9]{5}$/,
                                message: 'Please enter valid zip code!'
                            },
                        ]}
                    >
                        <InputMask
                            mask="99999"
                            maskChar=""
                        >
                            {() => <Input size="large" type="text" placeholder="Enter zipcode" />}
                        </InputMask>
                    </Form.Item>
                </div>
                <Form.Item
                    name={'email'}
                    label="Email"
                    rules={[
                        {
                            required: true,
                            message: 'Email is required!',
                        },
                        {
                            type: 'email',
                            message: 'The email you entered doesn’t seem valid!',
                        },
                    ]}
                >
                    <Input size="large" type="email" placeholder="Enter venue email" />
                </Form.Item>
                <Form.Item
                    name={'phone'}
                    label="Phone*"
                    rules={[
                        {
                            required: true,
                            message: 'Phone number is required!'
                        },
                        {
                            pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
                            message: 'Please enter valid phone!'
                        }
                    ]}
                >
                    <InputMask
                        mask="(999) 999-9999"
                        maskChar=""
                    >
                        {() => <Input size="large" type="text" placeholder="Enter venue phone" />}
                    </InputMask>
                </Form.Item>
                <ImageUploader
                    previous_images={venue?.images}
                    revalidate={fetchVenues}
                    output={setImages}
                />
            </Form>
            <RoomForm
                open={venuePayload ? { ...venuePayload, type: 'venue' } : false}
                handelClose={() => {
                    setVenuePayload(null);
                    setAddRoom(false);
                }}
                closeAll={handleCancel}
                fetchRooms={fetchVenues}
                venue_payload={venuePayload}
            />
        </Modal>
    ) : null
}

export default VenueForm
