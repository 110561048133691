import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ImageUploader from "../../../../components/ImageUploader";
import usePopup from "../../../../common/MessageModal/usePopup";
import { getRooms } from "../../../../services/rooms";

// UI's
import { Button, Modal } from "antd";
import { Form, Input, Select } from "antd";

import { createService, updateService } from "../../../../services/services";

import "../../styles/services.scss";

const ServicesForm = ({
  open,
  handelClose,
  venue_id,
  room_id,
  fetchServices,
  update_service,
  autoSelectAddOn,
}) => {
  const popup = usePopup();
  const current_venue = useSelector((state) => state.venues.selectedVenue?.id);
  const [form] = Form.useForm();
  const [images, setImages] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [roomsLoading, setRoomsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchRooms = async () => {
    setRoomsLoading(true);
    try {
      const res = await getRooms(venue_id ? venue_id : current_venue);
      const data = res?.data?.results?.map((room) => ({
        label: room.name,
        value: room.id,
      }));
      setRooms(data);
    } finally {
      setRoomsLoading(false);
    }
  };

  useEffect(() => {
    if (open && (current_venue || venue_id)) {
      fetchRooms();
    }
  }, [open, current_venue, venue_id]);

  useEffect(() => {
    if (room_id) {
      form.setFieldValue("room", room_id);
    }
  }, [open, room_id]);

  useEffect(() => {
    if (update_service) {
      const { room, images, ...rest } = update_service;
      form.setFieldsValue({ ...rest, room: room?.id });
    }
  }, [update_service, open]);

  const handleCancel = () => {
    handelClose();
    form.resetFields();
    setImages([]);
  };

  const renderdButtons = () => {
    let buttons = [
      <Button size={"large"} key="cancel" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button
        style={{ marginLeft: "auto" }}
        size={"large"}
        key="next"
        type="primary"
        loading={loading}
        onClick={() => form.submit()}
      >
        {update_service?.type === "edit" ? "Update" : "Create"}
      </Button>,
    ];
    return buttons;
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    if ((venue_id || current_venue) && update_service !== "edit") {
      formData.append("location", venue_id ? venue_id : current_venue);
    }
    formData.append("is_active", true);
    Object.entries(values).map(([key, value]) => {
      formData.append(
        key,
        typeof value === "array" || typeof value === "object"
          ? JSON.stringify(value)
          : value === undefined
            ? ""
            : value
      );
    });

    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i].originFileObj);
      }
    }

    setLoading(true);
    try {
      if (update_service?.type === "edit") {
        const res = await updateService(update_service?.id, formData);
        popup.success("Success!", res?.data?.message);
      } else {
        const res = await createService(formData);
        // popup.success("Success!", res?.data?.message);
        popup.success("Success!", "Services Created Successfully");
        if (autoSelectAddOn !== undefined) {
          autoSelectAddOn(res?.data?.data?.id);
        }
      }
      fetchServices();
      handleCancel();
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = '/server-error';
        console.log("Redirecting to server error page...");
      }
      if (typeof error?.response?.data?.message === "object" || "array") {
        const errors = error?.response?.data?.message[0];
        Object.entries(errors).map(([field, message]) => {
          form.setFields([
            {
              name: field,
              errors: message,
            },
          ]);
        });
      } else {
        popup.error("Oops...", error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };
  return open ? (
    <Modal
      centered
      title={
        update_service?.type === "edit" ? "Update Service" : "Create Service"
      }
      open={open}
      onCancel={handleCancel}
      footer={renderdButtons()}
    >
      <Form
        style={{ marginTop: 24 }}
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        className="modal-form"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="name"
          label="Service Name*"
          rules={[
            {
              required: true,
              message: "Service name is required!",
            },
          ]}
        >
          <Input maxLength={50} size="large" placeholder="Enter service name" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea
            maxLength={150}
            autoSize={{ minRows: 3, maxRows: 8 }}
            size="large"
            placeholder="Max 150 characters are allowed!"
          />
        </Form.Item>

        <Form.Item
          name={"price"}
          label="Service Price*"
          rules={[
            {
              validator: (rule, value) => {
                const numericValue = parseFloat(value);
                if (
                  isNaN(numericValue) ||
                  numericValue <= 0 ||
                  numericValue > 1000000
                ) {
                  return Promise.reject(
                    "Please enter a valid amount between 1 and 1,000,000!"
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            size="large"
            type="number"
            prefix={<>$</>}
            onKeyDown={(e) => {
              if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>

        <Form.Item name="room" label="Assign Room">
          <Select
            size="large"
            style={{ width: "100%" }}
            placeholder="select"
            options={rooms}
            allowClear
            loading={roomsLoading}
            disabled={room_id}
            onDropdownVisibleChange={(open) => {
              if (open) {
                !rooms?.length && fetchRooms();
              }
            }}
          />
        </Form.Item>
      </Form>

      <ImageUploader
        previous_images={update_service?.images}
        revalidate={fetchServices}
        output={setImages}
      />
    </Modal>
  ) : null;
};

export default ServicesForm;
