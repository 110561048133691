import React, { useState, useEffect } from "react";
// UI's
import {
  Typography,
  Card,
  Button,
  Input,
  Row,
  Col,
  Switch,
  Select,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { payment_dates } from "./Assets/utils";

const Payments = ({ createdPayments }) => {
  const { Title } = Typography;
  const [installment, setInstallment] = useState(false);
  const [payments, setPayments] = useState(null);

  useEffect(() => {
    const defaultPyament = [
      {
        title: "Payment 1",
        type: "credit-card",
        payment_day: 0,
      },
    ];
    setPayments(defaultPyament);
  }, []);

  useEffect(() => {
    if (payments?.length) {
      const updated = payments?.map((item) => {
        const { title, type, ...rest } = item;
        return {
          ...rest,
          percentage: payments?.length
            ? (100 / payments?.length).toFixed(2)
            : 100,
          is_partials_allowed: installment,
        };
      });
      createdPayments(updated);
    }
  }, [payments]);

  //   const addNewPayment = () => {
  //     const newPayment = {
  //       title: `Payment ${payments.length + 1}`,
  //       type: "credit-card",
  //       payment_day: 0,
  //     };
  //     setPayments((prev) => [...prev, newPayment]);
  //   };

  const addNewPayment = () => {
    const lastPayment = payments[payments.length - 1];
    const newPayment = {
      title: `Payment ${payments.length + 1}`,
      type: lastPayment.type,
      payment_day: lastPayment.payment_day === 0 ? 1 : lastPayment.payment_day,
    };
    setPayments((prev) => [...prev, newPayment]);
  };

  const updatePaymentAtIndex = (index, key, value) => {
    const newData = [...payments];
    newData[index] = { ...newData[index], [key]: value };
    setPayments(newData);
  };

  const deletePaymentAtIndex = (index) => {
    const newData = [...payments];
    newData.splice(index, 1);
    setPayments(newData);
  };

  // const generatePaymentDateOptions = (index) => {
  //   if (index > 0) {
  //     const prevPaymentDay = payments[index - 1]?.payment_day;
  //     return payment_dates.filter((date) => date.value >= prevPaymentDay);
  //   }
  //   return payment_dates;
  // };

  const generatePaymentDateOptions = (index) => {
    if (index > 0) {
      const prevPaymentDay = payments[index - 1]?.payment_day;
      if (prevPaymentDay === 0) {
        return payment_dates;
      }
      const prevPaymentDateIndex = payment_dates.findIndex(
        (date) => date.value === prevPaymentDay
      );
      const filteredOptions = payment_dates.slice(
        Math.max(prevPaymentDateIndex - 3, 0),
        prevPaymentDateIndex + 1
      );

      return filteredOptions;
    }
    return payment_dates;
  };

  return (
    <Card style={{ marginTop: "20px" }}>
      <div className="payment-header">
        <Title level={4}>Select Payment terms</Title>
        <p>
          <Switch
            checked={installment}
            onChange={(val) => {
              if (!val) {
                setPayments([
                  {
                    title: "Payment 1",
                    type: "credit-card",
                    payment_date: 0,
                  },
                ]);
              }
              setInstallment(val);
            }}
          />{" "}
          Enable Installment Payments
        </p>
      </div>

      <Row style={{ marginTop: "10px" }} gutter={[16, 16]}>
        {payments?.map((pay, i) => (
          <>
            <Col span={7} key={i}>
              <p className="payment-type">Payment Schedule</p>
              <Input
                id="cardHolderName"
                size="large"
                style={{ backgroundColor: "#f9f9fc", width: "100%" }}
                value={pay?.title}
                onChange={(e) =>
                  updatePaymentAtIndex(i, "title", e.target.value)
                }
              />
            </Col>
            <Col span={7} key={i}>
              <p className="payment-type ">Payment Schedule</p>
              <Select
                id="creditCardNumber"
                size="large"
                style={{ width: "100%", backgroundColor: "#f9f9fc" }}
                value={pay.type}
                onChange={(val) => updatePaymentAtIndex(i, "type", val)}
                options={[
                  { value: "debit-card", label: "Debit Card" },
                  { value: "credit-card", label: "Credit Card" },
                ]}
              />
            </Col>

            <Col span={7} key={i}>
              <p className="payment-type ">Date of Payment</p>
              <Select
                size="large"
                style={{ width: "100%", backgroundColor: "#f9f9fc" }}
                value={pay.payment_day}
                options={
                  i === 0
                    ? [{ value: 0, label: "Booking Day" }]
                    : generatePaymentDateOptions(i)
                }
                onChange={(val) => {
                  if (i === 0) {
                    updatePaymentAtIndex(0, "payment_day", 0);
                  } else {
                    updatePaymentAtIndex(i, "payment_day", val);
                  }
                }}
                disabled={i === 0}
              />
            </Col>
            <Col span={3} key={i}>
              {i ? (
                <Button
                  className="delete-button"
                  onClick={() => deletePaymentAtIndex(i)}
                >
                  <DeleteOutlined style={{ color: "#EB3D4D" }} />
                </Button>
              ) : null}
            </Col>
          </>
        ))}
      </Row>

      {installment && payments.length < 3 ? (
        <Button className="button-terms" onClick={addNewPayment} size="large">
          <PlusOutlined /> Add Payment Terms
        </Button>
      ) : null}
    </Card>
  );
};

export default Payments;
