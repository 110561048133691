import React, { useEffect, useContext, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { Button, Modal, Typography } from 'antd';
import { ModalContext } from './MessageModalContext';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error-icon.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success-icon.svg';

import './styles.scss';

const MessageModal = () => {
    const { open, setOpen, content, setContent, setPopup } = useContext(ModalContext);
    const { showBoundary } = useErrorBoundary();
    const { Title, Text } = Typography
    const [loading, setLoading] = useState(false);
    const [timeOutId, setTimeOutId] = useState('');

    const closeModal = () => {
        setOpen(false);
        setContent(null);
        clearTimeout(timeOutId);
        setTimeOutId('');
    }

    const callAction = async () => {
        setLoading(true)
        await content?.action();
        setLoading(false);
        if (content?.close) {
            closeModal();
        }
    }

    useEffect(() => {
        // Auto close modal after 3 seconds (Except Confirm Modal)
        if (open) {
            const id = setTimeout(() => {
                if (typeof content?.action !== 'function') {
                    closeModal();
                }
            }, 3000);
            setTimeOutId(id);
        }
    }, [open, content])

    // popupsettings
    useEffect(() => {
        const modal = {
            confirm: (title, body, action, close) => {
                setOpen('confirm');
                setContent({
                    title,
                    body,
                    action,
                    close,
                    type: 'confirm',
                });
            },
            success: (title, body) => {
                setOpen('success');
                setContent({
                    title,
                    body,
                    type: 'success',
                });
            },
            error: (title, body, network_error) => {
                if (network_error && network_error?.code === 'ERR_NETWORK') {
                    showBoundary(network_error);
                } else {
                    setOpen('error');
                    setContent({
                        title,
                        body,
                        type: 'error',
                    });
                }
            },
        }
        setPopup(modal);
    }, []);

    return (
        <Modal
            open={open}
            footer={null}
            onCancel={closeModal}
            closable={false}
            centered
            className="ps-message-modal"
            zIndex={99999999999999}
        >
            {content?.type === 'success' ? (
                <SuccessIcon />
            ) : (
                <ErrorIcon />
            )}
            <Title level={5}>{content?.title || 'Untitled'}</Title>
            {content?.body ? (
                <Text type='secondary'>{JSON.stringify(content?.body).replaceAll('"', '')}</Text>
            ) : null}
            {typeof content?.action === 'function' ? (
                <div className="footer">
                    <Button size='large' block onClick={closeModal}>Cancel</Button>
                    <Button loading={loading} type="primary" block size='large' danger onClick={callAction}>Delete</Button>
                </div>
            ) : null}
        </Modal>
    )
}

export default MessageModal
