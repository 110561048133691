import React from "react";
import { Row, Col, Modal, Input, Typography } from "antd";
import "./paymentIntegration.scss";
import toast from "react-hot-toast";
import ReactInputMask from "react-input-mask";
import myApi from "../../../network/axios";
import StateAutoComplete from "../../../common/StateAutoComplete";

const UpdateCompanyInformation = ({
  open,
  setEditModal,
  companyData,
  setCompanyData,
  accountID,
  callback,
}) => {
  const { Title, Text } = Typography
  const [loading, setLoading] = React.useState(false);
  const handleFieldChange = (e, field, subfield) => {
    e.preventDefault();
    // If it's a nested field (e.g., address.city), update it accordingly
    if (subfield) {
      setCompanyData({
        ...companyData,
        [field]: {
          ...companyData[field],
          [subfield]: e.target.value,
        },
      });
    } else {
      // If it's a top-level field, update it directly
      setCompanyData({
        ...companyData,
        [field]: e.target.value,
      });
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      let payload = {
        account_id: accountID,
        id: companyData?.id,
        type: "company",
        company: {
          name: companyData?.name,
          phone: companyData?.phone,
          email: companyData?.email,
          address: {
            line1: companyData?.address?.line1,
            line2: companyData?.address?.line2 || "",
            city: companyData?.address?.city,
            state: companyData?.address?.state,
            postal_code: companyData?.address?.postal_code,
          },
        },
      };
      await myApi
        .post(
          `${process.env.REACT_APP_BASE_API_URL}/api/company/integration/stripe/update/`,
          payload
        )
        .then((res) => {
          toast.success(res?.data?.message);
          setLoading(false);
          setEditModal(false);
          callback();
        }).catch((err) => {
          toast.error(err?.response?.data?.message);
          setLoading(false);
        })
    } catch (err) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onOk={() => handleUpdate()}
        onCancel={() => setEditModal(false)}
        okText="Update"
        cancelText="Cancel"
        confirmLoading={loading}
      >
        <Title level={3} style={{ marginBottom: 0 }}>Company Information</Title>
        <Text color="secondary">Update Your Company Information</Text>

        <Row gutter={[16, 16]} style={{ marginBottom: "24px", marginTop: '16px' }}>
          <Col span={24}>
            <p>Name</p>
            <Input
              type="text"
              placeholder="Complete Company Name"
              size="large"
              value={companyData?.name}
              onChange={(e) => handleFieldChange(e, "name")}
            />
          </Col>
          <Col span={24}>
            <p>Phone Number</p>
            <ReactInputMask
              mask="(999) 999-9999"
              maskChar=""
              value={companyData?.phone}
              onChange={(e) => handleFieldChange(e, "phone")}
            >
              {() => <Input placeholder="Phone Number" size="large" />}
            </ReactInputMask>
          </Col>
          <Col span={24}>
            <p>Street Address</p>
            <Input
              placeholder="Complete address of company"
              size="large"
              value={companyData?.address?.line1}
              onChange={(e) => handleFieldChange(e, "address", "line1")}
            />
          </Col>
          <Col span={9}>
            <p>City</p>
            <Input
              placeholder="City Name"
              size="large"
              value={companyData?.address?.city}
              onChange={(e) => handleFieldChange(e, "address", "city")}
            />
          </Col>
          <Col span={8}>
            <p>State</p>
            <StateAutoComplete
              value={companyData?.address?.state}
              onChange={(val) => {
                setCompanyData({
                  ...companyData,
                  address: {
                    ...companyData.address,
                    state: val,
                  },
                });
              }}
            />
          </Col>
          <Col span={7}>
            <p>Postal Code</p>
            <Input
              placeholder="Postal Code"
              size="large"
              value={companyData?.address?.postal_code}
              onChange={(e) => handleFieldChange(e, "address", "postal_code")}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default UpdateCompanyInformation;
