import { configureStore } from "@reduxjs/toolkit";
import LocationReducer from "./Location/LocationSlice";
import venuesReducer from './VenuesSlice';
import companySlice from "./CompanySlice";

export default configureStore({
  reducer: {
    location: LocationReducer,
    venues: venuesReducer,
    company: companySlice,
  },
});
