import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setVenues as updateVenues, setVenue } from '../../store/VenuesSlice';
import { Button, Row, Col, Result, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import usePopup from '../../common/MessageModal/usePopup';

import { getVenues } from '../../services/venues';

import Loader from '../../common/Loader';
import Layout from './Layout';
import VenueCard from './comps/VenueCard';
import VenueForm from './comps/forms/VenueForm';

const Venues = () => {
    const popup = usePopup();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [venues, setVenues] = useState([]);
    const [venueModal, setVenueModal] = useState(false);

    const fetchVenues = async () => {
        setLoading(true);
        try {
            const res = await getVenues();
            setVenues(res?.data?.results);
            dispatch(updateVenues(res?.data?.results));
        } catch (error) {
            const errorCode = error?.response?.status || 500; 
            if (errorCode === 500 || errorCode === 503) {
              window.location.href = '/server-error';
              console.log("Redirecting to server error page...");
            }
            else{
                popup.error('Oops...', error?.response?.data?.message, error);
            }

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchVenues();
    }, []);

    useEffect(() => {
        const oldVenue = JSON.parse(localStorage.getItem('selectedVanue'));
        if (venues.length) {
            const haveVenue = venues?.find((item) => item.id === oldVenue?.id);
            if (!haveVenue) {
                localStorage.removeItem('selectedVanue');
                dispatch(setVenue(null));
            }
        }
    }, [venues]);

    return (<>
        <Layout
            title={'Venues'}
            addButton={
                <Button type='primary' size='large' icon={<PlusOutlined />} onClick={() => setVenueModal(true)}>
                    Add Venue
                </Button>
            }
            items={[{ title: 'Company Settings' }, { title: 'Venues' }]}
        >
            {loading && !venues?.length ? (
                <Loader minHeight={400} />
            ) : (
                <Spin spinning={loading}>
                    <Row gutter={[24, 24]}>
                        {venues?.length ? venues.map((item, i) => (
                            <Col key={i} span={24} md={12} xl={8} xxl={6}>
                                <VenueCard item={item} fetchVenues={fetchVenues} />
                            </Col>
                        )) : (
                            <Col span={24}>
                                <Result
                                    status="404"
                                    title="Oops! No venues are found!"
                                    subTitle="Sorry, you don't have any venues yet."
                                />
                            </Col>
                        )}
                    </Row>
                </Spin>
            )}
        </Layout>
        <VenueForm
            open={venueModal}
            handelClose={() => setVenueModal(false)}
            fetchVenues={fetchVenues}
        />
    </>)
}

export default Venues;