import React, { useState, useEffect } from "react";
import { Layout, Space } from "antd";

import MainHeader from "./Header";
import Sidebar from "./Sidebar";


const MainLayout = ({ children }) => {
  const { Header, Sider, Content } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1280) {
        setMobile(true);
        setCollapsed(true);
      } else {
        setCollapsed(false);
        setMobile(false);
      }
    }

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);


  const contentStyle = {
    paddingInline: "24px",
    minHeight: "calc(100vh - 96px)",
    overflow: "auto",
    width: "100%",
    backgroundColor: "#F9FDFF",
    overflowX: 'hidden'
  };

  const headerStyle = {
    color: "#858D9D",
    height: 88,
    width: "100%",
    backgroundColor: "rgb(249, 253, 255)",
    padding: "0px 0px 24px 0px",
    // display: "flex",
    // alignItems: "center",
    marginTop: "0px"
  };

  const siderStyle = {
    boxShadow: "4px 0px 30px 0px rgba(131, 98, 234, 0.05)",
    backgroundColor: "#fff",
    borderRight: "1px solid #F0F1F3",
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={[0, 48]}>
      <Layout>
        <Sider
          collapsible={!mobile}
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          width={260}
          style={siderStyle}
        >
          <Sidebar collapsed={collapsed} />
        </Sider>
        <Layout>
          <Header style={headerStyle}>
            <MainHeader />
          </Header>
          <Content style={contentStyle}>

            {children}
          </Content>
        </Layout>
      </Layout>
    </Space>
  );
};

export default MainLayout;
