import { PACKAGES } from './endpoints';
import client from '../axios';

// Packages
export const getPackages = async (params) => {
    const queryParams = params ? Object.entries(params)?.map(([key, value]) => (`${key}=${value}`)).join('&') : '';
    return await client.get(PACKAGES + (queryParams ? `?${queryParams}` : ''));
}
// create Package
export const createPackage = async (body) => {
    return await client.post(PACKAGES, body);
}

// update Package
export const updatePackage = async (id, body) => {
    return await client.put(PACKAGES + id + '/', body);
}

// delete Package
export const deletePackage = async (id) => {
    return await client.delete(PACKAGES + id + '/');
}